<template>
    <dl id="admin_page">

        <article class="myo_search_area">
            <form name='form_search' style="clear:both;"  v-on:submit.prevent="submitForm">
                <section class="myo_condition_box">
                    <dl class="myo_search_list">
                        <dt>{{ i18n.search.find }}</dt><!-- 검색 -->
                        <dd class="">
                            <section class="flex_box">
                                <div class="myo_search_box">
                                    <input type="text" class="myo_search_input" name="search_text" v-model="search_text">
                                </div>
                            </section>
                        </dd>
                    </dl>
                </section><!-- // myo_condition_box -->

                <div class="myo_condition_search2">
                    <button type="reset" onclick="location.replace('/MyNotice')" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                    <button type="submit" class="search_btn btn-eft-none">{{ i18n.myoffice.Search }}</button>
                </div>
            </form>
        </article>

        <div class="position_relate_box">
            <abbr title="@lang('myoffice.myofficeAlert.CanTablescroll2')" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
        </div>

        <div class="list-table-wrap">
            <table class="list-table notice_list_box">
                <thead>
                <tr>
                    <th data-priority="4">{{ i18n.inquriy.inquriy.title }}</th>
                    <th data-priority="6">{{ i18n.inquriy.inquriy.questionDate }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(Value,index) in List" class="list_hover" v-bind:style="Value.notice_yn == 'Y' ? 'background:#f7f7f7' : ''">
                    <td style="overflow: hidden;">
                        <a :href="Value.href" >
                            <span  style='cursor:pointer; ' >{{ Value.title }}</span>
                            <span><i v-show="Value.attach_cnt" class="far fa-file-alt attach" aria-hidden="true"></i></span>
                        </a>
                        <span v-show="Value.notice_yn == 'Y'" class='impo-badge'>Notice</span>
                        <span v-show="Value.new_yn == 'Y'" class="new-badge">New</span>
                    </td>
                    <td align="center">{{  Value.reg_date  }}</td>
                </tr>
                <tr class="h-2" v-if="total_count == 0">
                    <td class="text-left md:text-center pl-5 py-5" colspan="13">No Data</td>
                </tr>

                </tbody>
            </table>
        </div>
        <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
            <v-pagination
                v-model="page"
                :pages=total_page
                :range-size="1"
                active-color="#DCEDFF">
            </v-pagination>
        </div>
    </dl>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import Loading from "vue3-loading-overlay";
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyNotice",
    components : {
        VPagination,
        Loading
    },
    props:['mid_mypage'],
    data: () => ({
        i18n: window.i18n,
        List : [],
        total_count : 0,
        page : 1,
        total_page : 1,
        mid : window.location.pathname,
        search_text : "",
    }),
    watch : {
        page : function(){
            this.submitForm();
        }
    },
    created : function (){
        this.loadPage();

    },
    methods : {
        submitForm : function (){
          this.loadPage();
          if(this.total_count == 0){
            VueSimpleAlert.alert("There are no search results","","",{});
            this.sid_name = "";
          }
        },
      loadPage : function () {
          axios.post(window.Laravel.back_url+'/api/Notice',{
            page : this.page,
            page_size : 20,
            mid : this.mid_mypage ? this.mid_mypage : this.mid,
            search_text : this.search_text
          },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.List = response.data.list.data;
            this.total_count = response.data.total_count;
            this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
            this.loading = false;

          });
      }
    }

}
</script>

<style scoped>

</style>
