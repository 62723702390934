import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate';

export default createStore({

  state: { // data
      cartCount: 0
  }, /** computed */
  getters: {
    getCartCount: (state) => {
      return state.cartCount
    },
  },
  mutations: {
    setCartCount: (state, cartCount) => {
      state.cartCount = cartCount
    }
  }, actions: {}, modules: {}, plugins: [createPersistedState()]
})
