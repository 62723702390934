<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>
  <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
    <address_search @set_address="set_address" @close_address_search="close_address_search" />
  </modalView>

  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
        <MypageTop :laravel="laravel"></MypageTop>
        <div class="w-full pt-4 lg:flex lg:justify-start">
          <div class="hidden lg:block xl:block">
            <SideMenu></SideMenu>
          </div>
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start flex-col">
                  <div class="flex justify-between">
                    <h1 class="text-lg dark:text-white lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800 mb-2">{{ i18n.shop.mypage.YourAddresses }}</h1>
                  </div>
                  <div class="w-full border-t-2 border-black divide-y bg-white lg:p-2" id="infomodify">
                    <div class="py-2 border-b-2 border-black">
                      <div class="flex justify-between text-lg mb-4">
                        <h2 class="text-sm md:text-base font-semibold">
                          Pastikan untuk memasukkan informasi yang akurat. Anggota bertanggung jawab atas kesalahan pengiriman karena informasi yang diberikan salah.
                          <!-- You must enter the correct information. The member is responsible for the wrong delivery due to the wrong information. -->
                        </h2>
                        <div class="flex justify-end">
                          <MinusSmIcon class="h-6 w-6" aria-hidden="true" />
                          <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                        </div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-md font-semibold mb-1">Nama Penerima</div>
                        <div class="w-full lg:w-5/6"><input type='text' v-model='this.shipping_info["d_name"]' placeholder="Name"  maxlength="45" class="w-full xl:w-80 lg:w-80"></div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-md font-semibold mb-1">Ponsel</div>
                        <div class="w-full lg:w-5/6"><input type='text' @keyup="regNumber('d_mobile')" v-model='this.shipping_info["d_mobile"]' placeholder="Mobile" class="w-full xl:w-80 lg:w-80" maxlength="12"></div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-md font-semibold mb-1">Kode Pos</div>
                        <div class="w-full lg:w-5/6">
                          <input name="post" id="post" class="type_number h-10 w-full lg:w-48" @click="search_address" v-model='this.shipping_info["d_post"]' maxlength='5' type="text" readonly>
                          <input type="button" @click="search_address"  class="h-10 w-full lg:w-32 leading-6 text-white bg-gray-900 cursor-pointer mt-2 lg:mt-0 xl:mt-0" value="search address"/>
                        </div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-md font-semibold mb-1">Alamat pengiriman</div>
                        <div class="w-full lg:w-5/6">
                          <input type="text" class="add-input1 h-10 w-full xl:w-80 lg:w-80" @click="search_address" v-model='this.shipping_info["d_address1_str"]'  readonly>
                        </div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-md font-semibold mb-1">Tandai alamat sebagai</div>
                        <div class="w-full lg:w-5/6">
                          <input type="text" class="add-input1 h-10 w-full xl:w-80 lg:w-80" v-model='this.shipping_info["d_address2"]' placeholder="Please enter up to 40 characters." maxlength="40"> <span class="text-sm text-neutral-400">Apart, suite, unit, etc</span>
                        </div>
                      </div>
                      <div class="text-rose-500 my-2 font-semibold">
                        ※ Jangan memasukkan informasi alamat duplikat (misalnya kota/provinsi, kereta bawah tanah, nama jalan, kode pos) di kolom alamat. <!-- Please do not enter duplicate address information in the two fields below(Ex. State, City, Street, Post code). -->
                        <br> Bidang alamat (Apart, suite, unit, etc) hanya boleh berisi informasi rinci. <!-- Only detailed information should be entered in the two fields below(Building, House etc). -->
                      </div>
                      <div class="mt-4">
                        <input type="checkbox" name="default" id="default_chk" v-model="checked">
                        <label for="default_chk"> {{ i18n.shop.etc.alertdefaultshipping }} </label>
                      </div>

                    </div>
                    <div class="flex space-x-2">
                      <button type="button" v-show="this.no != '0'" @click='address_update()'  class="my-2 py-2 px-4 leading-6 text-white bg-gray-900 w-24 text-center w-1/2 lg:w-1/6">{{ i18n.myoffice.save }}</button>
                      <button type="button" v-show="this.no == '0'" @click='address_add()'  class="my-2 py-2 px-4 leading-6 text-white bg-gray-900 w-24 text-center w-1/2 lg:w-1/6">{{ i18n.myoffice.save }}</button>
                      <button type="button" @click="list_url" class="my-2 py-2 px-4 leading-6 text-white bg-gray-500 w-24 text-center w-1/2 lg:w-1/6">{{ i18n.myoffice.Cancle }}</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from '../MypageTop.vue';
import SideMenu from '../SideMenu.vue';
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import {createPopper} from "@popperjs/core";
import modalView from "../../modalView";

export default {
  name: "AddressModify",
  props : ['no'],
  components : {
    VueSimpleAlert,
    ShoppingCartIcon,
    MypageTop,
    SideMenu,
    Loading,
    modalView,
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    no_search_messsage: '',
    isLoggedIn: false,
    loading : false,
    fullPage : true,
    state_list : "",
    address_search_open:false,
    shipping_info: [],
    openlayer:Boolean,
  }),
  computed : {
    openlayer: function(){
      this.address_book = this.openlayer;
      //return this.set_open;
    }
  },
  beforeCreate() {

  },
  created() {
    if(this.no != '0'){
      this.get_edit_address_book(this.no);
    }

  },
  mounted() {
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
    }
  },
  methods:{
    regNumber(colum){
      var reg = /'/gi;
      var text = this.shipping_info[colum];
      text = text.replace(/[^0-9]/g,'');
      this.shipping_info[colum] = text;
    },
    address_update(){
      if(!this.shipping_info["d_name"]){
        alert.alert('Please enter reciever name');
        return false;
      }
      if(!this.shipping_info["d_mobile"]){
        alert.alert('Please enter mobile No.');
        return false;
      }
      if(!this.shipping_info["d_address1"]){
        alert.alert('Please enter Address1 information');
        return false;
      }
      if(!this.shipping_info["d_post"]){
        alert.alert('Please select shipping Postcode information');
        return false;
      }

      let format = new FormData();
      format.append("_token"    , this.csrf);
      format.append("seq"       , this.no);
      format.append("bz_cd"     , window.Laravel.bz_cd);
      format.append("name"      , this.shipping_info.d_name ?? '');
      format.append("mobile"    , this.shipping_info.d_mobile ?? '');
      format.append("address1"  , this.shipping_info.d_address1 ?? '');
      format.append("address"   , this.shipping_info.d_address2 ?? '');
      format.append("post"      , this.shipping_info.d_post ?? '');
      format.append("r_county"  , this.shipping_info.d_city_code ?? '');
      format.append("default"   , this.checked);
      format.append("client_ip" , window.Laravel.clientIp);

      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_update', format,
        {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.loading = false;
          if(response.data){
            VueSimpleAlert.alert("Completed","","",{});
          }
          this.list_url();
        });
    },
    // 주소록 추가하기
    address_add(){
      if(!this.shipping_info["d_name"]){
        alert.alert('Please enter reciever name');
        return false;
      }
      if(!this.shipping_info["d_mobile"]){
        alert.alert('Please enter mobile No.');
        return false;
      }
      if(!this.shipping_info["d_address1"]){
        alert.alert('Please enter address1 information');
        return false;
      }
      if(!this.shipping_info["d_post"]){
        alert.alert('Please select shipping Postcode information');
        return false;
      }

      let format = new FormData();
      format.append("_token"   , this.csrf);
      format.append("bz_cd"    , window.Laravel.bz_cd);
      format.append("name"     , this.shipping_info.d_name ?? '');
      format.append("mobile"   , this.shipping_info.d_mobile ?? '');
      format.append("address1" , this.shipping_info.d_address1 ?? '');
      format.append("address"  , this.shipping_info.d_address2 ?? '');
      format.append("post"     , this.shipping_info.d_post ?? '');
      format.append("r_county" , this.shipping_info.d_city_code ?? '');
      format.append("default"  , this.checked);
      format.append("client_ip", window.Laravel.clientIp);

      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_store', format,
        {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.loading = false;
          // console.log(response.data);
          if(response.data){
            VueSimpleAlert.alert("Completed","","",{});
          }
          this.list_url();
        });
    },
    get_edit_address_book(id)
    {
      axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_item',{
        seq:id
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.shipping_info["seq"]         = response.data.seq_oab;
          this.shipping_info["d_name"]      = response.data.r_name;
          this.shipping_info["d_name1"]     = '';
          this.shipping_info["d_name2"]     = '';
          this.shipping_info["d_post"]      = response.data.r_post;
          this.shipping_info["d_city_code"] = response.data.r_county;
          this.shipping_info["d_address1"]  = response.data.r_addr1;
          this.shipping_info["d_address1_str"] = response.data.r_addr1.replace(/\|@\|/g, ', ');
          this.shipping_info["d_address2"]  = response.data.r_addr2;
          this.shipping_info["d_telephone"] = response.data.r_tel;
          this.shipping_info["d_mobile"]    = response.data.r_mobile;
          this.checked                      = (response.data.default_yn === 'Y');
        })
    },
    close_address_book(){
      // this.openlayer = false;
      this.$emit("close_address_book");
    },
    search_address(){
      this.address_search_open = true;
    },
    set_address(value){
      this.address_search_open             = false;
      this.shipping_info["d_address1"]     = [value.provinsi, value.kota_kabupaten, value.kecamatan_distrik].join('|@|');
      this.shipping_info["d_address1_str"] = [value.provinsi, value.kota_kabupaten, value.kecamatan_distrik].join(', ');
      this.shipping_info["d_post"]         = value.postcode;
      this.shipping_info["d_city_code"]    = value.city_code;
    },
    close_address_search(){
      this.address_search_open = false;
    },
    list_url(){
      location.href="/Mypage/AddressList";
    },
  },

}

</script>

<style scoped>

</style>
