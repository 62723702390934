<template>
    <div id="mem_info">

        <h2 class="myo_table_tit">{{ i18n.myoffice.dashboard.myinformation }}</h2>
        <br>
        <div class="member_info_button">
            <span class="btn dark2">
                <input type="button" onclick="location.href='/MyforinerUpload'" :value="i18n.myoffice.foreignerformupload2">
            </span>
        </div>

        <ul id="myo_form" class="mgb_30">
            <li class="item table_form" >
                <span class="item_title">{{ i18n.myoffice.MemberId }}</span>
                <span class="item_content">{{ mem.userid }}</span>
            </li>
            <li class="item table_form">
                <span class="item_title info_title">{{ i18n.myoffice.memberName }}</span>
                <span class="item_content info_content">{{ mem.username }}</span>
            </li>
            <li class="item table_form">
                <span class="item_title">{{ i18n.myoffice.RegistrationDate }}</span>
                <span class="item_content">{{ mem.reg_date }}</span>
            </li>
            <li class="item table_form">
                <span class="item_title">{{ i18n.myoffice.Rank }}</span>
                <span class="item_content" style='position: relative;'>{{ mem.rank_name }}</span>
            </li>
            <li v-if="this.mem.chgAcademy_chk == 0" class="item item table_form">
                <span class="item_title">Academy</span>
                <span class="item_content">{{ mem.cnt_name }} <button type="button" class="download_btn3" onclick="location.href='/MyPersonInfo/modifyCenter'">{{ i18n.myoffice.Change}} {{ i18n.myoffice.dashboard.Academy}}</button></span>

            </li>
            <li class="item item table_form">
                <span class="item_title title_sponsor">{{ i18n.myoffice.PlacementSponsor }}</span>
                <span class="item_content info_content">{{ mem.p_names }}</span>
            </li>
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.EnrollmentSponsor }}</span>
                <span class="item_content info_content">{{ mem.r_names }}</span>
            </li>
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.dashboard.goUrl }}</span>
                <span class="item_content info_content">
                    <span><button type="button" class="download_btn3"  v-on:click="copy_register_url(mem.xid)">{{ i18n.myoffice.Copy }}</button></span>
                </span>
            </li>
<!--            <li class="item item table_form">-->
<!--                <span class="item_title">Membership Enrollment Form</span>-->
<!--                <span class="item_content info_content">-->
<!--                    <span v-if="rec_form.file_name"><button type="button" class="download_btn3"  v-on:click="popup_member_form()">DownLoad</button></span>-->
<!--                </span>-->
<!--            </li>-->

        </ul>

        <h2 class="myo_table_tit">{{ i18n.myoffice.PersonalInformation }}</h2>
        <ul id="myo_form">
            <li class="item item table_form" >
                <span class="item_title">{{ i18n.myoffice.gender }}</span>
                <span class="item_content">{{ mem.sexs == "남" ? "male" : "female" }}</span>
            </li>
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.birthday }}</span>
                <span class="item_content">{{ mem.birthday }}</span>
            </li>
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.tel }}</span>
                <span class="item_content">{{ mem.tel != "null" ? mem.tel : '' }}</span>
            </li>
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.handphone }}</span>
                <span class="item_content">{{ mem.mobile }}</span>
            </li>
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.email }}</span>
                <span class="item_content">{{ mem.e_mail }}</span>
            </li>
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.Address }}</span>
                <span class="item_content">&nbsp;{{ mem.addr2 }} {{ mem.addr1 }}</span>
            </li>
            <li class="item item table_form">
                <span class="item_title">Tampilan Kinerja</span>
                <span class="item_content">
		    		<p class="mgb_8">{{ i18n.myoffice.LookallowuplineList }} :
						<span class="point_text">{{ myconfig.flag_4week == 'N' ? i18n.myoffice.MyMember.block : '' }} </span>
						<span class="signiture">{{ myconfig.flag_4week == 'Y' ? i18n.myoffice.MyMember.allow : '' }}</span>
					</p>
		    		<p>{{ i18n.myoffice.LookmyofficeList }} :
						<span class="point_text">{{ myconfig.flag_profit == 'N' ? i18n.myoffice.MyMember.block  : '' }}</span>
						<span class="signiture">{{ myconfig.flag_profit == 'Y' ? i18n.myoffice.MyMember.allow : '' }}</span>
					</p>
				</span>
            </li>
        </ul>

        <div class="myo_condition_search2">
            <button type="button" class="reset_btn" onclick="location.href='/MyMemberWithdrawal'">{{ i18n.myoffice.withdrawal }}</button>
<!--            <button type="button" class="reset_btn" v-on:click="goWidhtral">{{ i18n.myoffice.withdrawal }}</button>-->
            <button type="button" class="search_btn" onclick="location.href='/MyPersonInfo/modify'">{{ i18n.myoffice.MemberInfoChange }}</button>
        </div>


        <h2 class="myo_table_tit">{{ i18n.myoffice.AccountInfomation }}</h2>
        <ul id="myo_form">
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.bankname }}</span>
                <span class="item_content">{{ mem.bank_name != "null" ? mem.bank_name : "" }}</span>
            </li>
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.bankaccountno }}</span>
                <span class="item_content">{{ mem.account != "null" ? mem.account : "" }}</span>
            </li>
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.AccountHolder }}</span>
                <span class="item_content">{{ mem.depositor }}</span>
            </li>
        </ul>


    </div>



</template>

<script>
import VueSimpleAlert from "vue3-simple-alert";
import vSelect from "vue-select";
import Loading from "vue3-loading-overlay";

export default {
    name: "MyPersonInfo",
    components : {
        VueSimpleAlert,
    },
    data: () => ({
        i18n: window.i18n,
        mem: "",
        myconfig : "",
        state : "",
        doc_void : "",
        doc_w9 : "",
        cp58: "",
        rec_form : ""
    }),
    beforeCreate() {
        axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.mem = response.data;

        });

        axios.post(window.Laravel.back_url + '/api/getMyConfig', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.myconfig = response.data;

        });
        axios.post(window.Laravel.back_url + '/api/getjoininfoDocu',{ bz_cd : window.Laravel.bz_cd }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            //console.log(response.data);
            this.rec_form = response.data.rec_form;

        });


    },methods:{
        goWidhtral(){
            VueSimpleAlert.alert("Please use the 1:1 inquiries to withdraw membership during the pre-registration period.","","",{allowOutsideClick : false}).then((result) => {
            });

        },
        copy_register_url(xid){
            this.detailshow = false;
            var link = 'https://signup.gcoop.com/RegToken?xid='+encodeURIComponent(xid);

          var md = $.Zebra_Dialog('<div class="url_tit"><i class="fas fa-link"></i>&nbsp;url copy</div><textarea id="reg_url">'+link+'</textarea><div class="url_notice"><div class="url_tit"><i class="fas fa-lightbulb-exclamation" aria-hidden="true"></i>&nbsp;notice</div>If the URL is used to join as a member, the sponsor is automatically designated for the left line based on the major performance.</div>', {
                width: 1000,
                title:  i18n.myoffice.myofficeAlert.RegisterURLTit,
                type :  false,
                modal: true,
                overlay_close: false,
                buttons:
                    [
                        {caption: 'Copy', callback: function() {
                                $('#reg_url').select();
                                document.execCommand('copy');
                            }}
                    ]
            });
        },
    popup_member_form() {
            window.open("https://cdn3.gcooperp.com/"+this.rec_form.path_name+this.rec_form.file_name+".pdf");
        },


    }
}
</script>

<style>
/* 해당 페이지 URL 팝업에만 css 수정 적용 */
.ZebraDialog{background: #fff; border-radius: 20px !important;}
.ZebraDialog .ZebraDialog_Title{background: transparent !important; border-bottom:1px solid #ddd !important; padding-right: 30px !important; line-height: 1.2;}
.ZebraDialog .ZebraDialog_ButtonsOuter{background: transparent !important; border: 0px solid !important;}
.ZebraDialog .ZebraDialog_Buttons{ position: absolute; top: 210px; left: -15px;}
.ZebraDialog .ZebraDialog_Body{padding: 20px 20px 5px 20px !important;}
#reg_url{border-radius: 10px; border: 1px solid #fff; background: #eefaff!important; width: calc(100% - 30px)!important; height: 75px !important; font-size: 13px !important;}
.url_notice{margin-top:70px; padding-top: 10px; border-top:1px dotted #ddd;}
.url_tit{color: #5c98f3; margin-bottom:5px;}
</style>
