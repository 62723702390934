<template>
    <loading :active="false"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <article class="myo_search_area">
        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <section class="myo_condition_box">
                <dl class="myo_search_list">
                    <dt>{{ i18n.myoffice.Commission.giveDate }}</dt>
                    <dd class="myo_search_list">
                        <section class="flex_box benefit_date_box">
                            <vSelect :options="years" label="value" :reduce="value => value.value"  :clearable="false" v-model="s_year"  ></vSelect>
                            <vSelect :options="months" label="value" :reduce="value => value.title" :clearable="false" v-model="s_month"  ></vSelect>
                            <vSelect :options="days" label="value" :reduce="value => value.code" :clearable="false" v-model="s_day"  ></vSelect>

                        </section>
                    </dd>
                </dl>
            </section>

            <div class="myo_condition_search2">
                <button type="reset" class="reset_btn" onclick="location.href='/us/MyBenefitList';">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>

        </form>
    </article>

    <section class="accordion_notice_box">
        <div class="accordion_notice_item">
            <a class="accordion_notice_tit" href="#n">
                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                {{ i18n.myoffice.myoSearchForm.notice }}
                <div class="bmenu x7">
                    <span class="btop">
                        <i class="fas fa-chevron-up" aria-hidden="true"></i>
                    </span>
                </div>
            </a>
            <div class="accordion_notice_cont">
                <div class="myo_search_notice">
                    <p>{{ i18n.myoffice.Commission.txt1 }}</p>
                    <p>{{ i18n.myoffice.myofficeAlert.CarryoverBonusAlert }}</p>
                </div>
            </div>
        </div>
    </section>

    <div id="printArea">
        <div class="cont_wrap">

            <div style="height:30px;"></div>

            <div style="text-align: center; margin:10px 0 15px 0;"><strong class="gray06" style="font-weight:bold; font-size:28px;">{{ i18n.myoffice.Commission.commissionStatement }}</strong></div>

            <div class="position_relate_box2">
                <span class="table_notice_left"><!-- {{Session::get("login.name", '--' }} --> {{ i18n.myoffice.myofficeAlert.YourBonusListAlert }}</span>
                <abbr style="display:none;" title="{{ i18n.myoffice.myofficeAlert.CanTablescroll2 }}" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
            </div><!-- 님 수고 많으셨습니다. 회원님의 수당내역은 다음과 같습니다. -->

            <div id="divPrint" style="width:100%;" >
                <div class="list-table-wrap wrap_c">
                    <table class="list-table" style="width: 100%;">
                        <colgroup>
                            <col width="112"/>
                            <col width="*"/>
                        </colgroup>

                        <tbody class="tbody_print_C" v-if="member_info" v-show="member_info">
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.Commission.paymentDate }}</strong>
                            </td>

                            <td class="gray01 alignL">
                                <span id="paydate">{{ member_info.paydate }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.MemberId }}</strong>
                            </td>
                            <td class="gray01 alignL">
                                <span id="userId2">{{ member_info.userid2 }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.memberName }}</strong>
                            </td>
                            <td class="gray01 alignL">
                                <span id="userName">{{ member_info.username }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.Commission.sumofPayment }}</strong>
                            </td>
                            <td class="gray01 alignL">
                                <span id="totalAmt">{{ formatPrice(member_info.totalamt) }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.refund.totalDeduction }}</strong><!-- 공제합계 -->
                            </td>
                            <td class="gray01 alignL">
                                <span id="taxtotalAmt">{{ formatPrice(member_info.taxtotalamt) }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.Commission.sumofReduction }}</strong>
                            </td>
                            <td class="gray01 alignL">
                                <span id="etcLoan">{{ formatPrice(member_info.etcloan) }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.refund.otherRefunds }}</strong><!-- 기타환수금 -->
                            </td>
                            <td class="gray01 alignL">
                                <span id="amtbprecv">{{ formatPrice(member_info.amtbprecv) }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.Commission.paymentAmount }}</strong>
                            </td>
                            <td class="gray01 alignL">
                                <span id="trueAmt">{{ formatPrice(member_info.trueamt) }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.refund.lastweekAmount }}</strong><!-- 전주이월금액 -->
                            </td>
                            <td class="gray01 alignL">
                                <span id="totalBe">{{ formatPrice(member_info.totalbe) }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.refund.lowLastWeekAmount }}</strong><!-- 익주이월금액 -->
                            </td>
                            <td class="gray01 alignL">
                                <span id="totalRe">{{ formatPrice(member_info.totalre) }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.Commission.bankName }}</strong>
                            </td>
                            <td class="gray01 alignL">
                                <span id="bankName">{{ member_info.bankname }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.Commission.accountNumber }}</strong>
                            </td>
                            <td class="gray01 alignL">
                                <span id="account">{{ member_info.account }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="myo_tit" style="text-align:center;">
                                <strong>{{ i18n.myoffice.Commission.bankHolder }}</strong>
                            </td>
                            <td class="gray01 alignL">
                                <span id="depositor">{{ member_info.depositor }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <ul class="osBeneList_box_td">
                    <li>
                        <div class="gray02 bg02" style="padding:7px 10px; margin-top:10px; margin-bottom: 5px; box-sizing: border-box; background: #e2e2e2;">
                            <strong>{{ i18n.myoffice.Commission.BonusTaxList }}</strong><!-- 수당/공제 내역 -->
                        </div>

                        <div class="list-table-wrap">
                            <table class="list-table no_margin" style="width: 100%;">
                                <caption>{{ i18n.myoffice.Commission.BonusTaxList }}</caption><!-- 수당/공제 내역 -->
                                <colgroup>
                                    <col width="160px"/>
                                    <col width="160px"/>
                                    <col width="*"/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th class="ltit" style="height:20px;">{{ i18n.myoffice.Commission.type }}</th>
                                    <th>{{ i18n.myoffice.Commission.article }}</th>
                                    <th>{{ i18n.myoffice.Commission.price }}</th>
                                </tr>
                                </thead>
                                <tbody class="tbody_print_A"><!-- 수당공제내역 -->
                                <tr v-for="Value in info_list">
                                    <td>{{ Value.gubun }}</td>
                                    <td>{{ Value.payname }}</td>
                                    <td>{{ formatPrice(Value.amt) }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>
                </ul>

            </div>
        </div>


    </div>
    <div v-show="is_print" class="myo_condition_search2">
        <button type="button" class="search_btn"  @click="benefitListReport">{{ i18n.myoffice.Commission.Printing }}</button>
    </div>

</template>

<script>
import vSelect from "vue-select";
import Popper from "vue3-popper";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import printJS from 'print-js'

export default {
    name: "MyBenefitList",
    components : {
        vSelect,
        Loading,
        printJS
    },
    data: () => ({
        i18n: window.i18n,
        userid2: "",
        paydate: "",
        member_info : [],
        info_list : [],
        is_print : false,
        months : [{title : "01", value : i18n.myoffice.jan},
            {title : "02", value : i18n.myoffice.feb},
            {title : "03", value : i18n.myoffice.mar},
            {title : "04", value : i18n.myoffice.apr},
            {title : "05", value : i18n.myoffice.may},
            {title : "06", value : i18n.myoffice.jun},
            {title : "07", value : i18n.myoffice.jul},
            {title : "08", value : i18n.myoffice.aug},
            {title : "09", value : i18n.myoffice.sep},
            {title : "10", value : i18n.myoffice.oct},
            {title : "11", value : i18n.myoffice.nov},
            {title : "12", value : i18n.myoffice.dec}],
        s_year : new Date().getFullYear(),
        s_month : new Date().getMonth()+1 < 10 ? "0"+(new Date().getMonth()+1) : new Date().getMonth()+1,
        s_day : "",
        years : [],
        days : [],
        loading : false,

    }),
    watch : {
        s_year : function(){
            this.getGiveDay();
        },
        s_month : function(){
            this.getGiveDay();
        }
    },
    created : function (){
        var year = "2015";
        for(year ; year <= new Date().getFullYear() ; year++){
            this.years.push(
                {value: year}
            );
        }
        this.getGiveDay();
        //this.submitForm();
    },
    methods : {
        getGiveDay(){
            axios.post(window.Laravel.back_url+'/api/getGiveDay',{
                month : this.s_year+""+this.s_month
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response =>{
                this.s_day = response.data.data[0].value;
                this.days = response.data.data;
                this.submitForm();
            });
        },
        submitForm : function (){
            this.loading = true;
            console.log(this.s_day);
            axios.post(window.Laravel.back_url+'/api/MyBenefitList',{
                s_year : this.s_year,
                s_month : this.s_month,
                s_day : this.s_day,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.member_info = response.data.member_info;
                if(this.member_info != ""){
                    this.is_print = true;
                }
                this.info_list = response.data.info_list;
                this.loading = false;

            });
        },
        formatPrice(value,digit) {
            // 코드 개선 add by hskim :: 2023-07-21 오후 2:46
            value = isNaN(value) ? 0 : value;
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        benefitListReport(){
            printJS({'printable' : 'printArea', 'type' : 'html','css':['/myoffice/css/MyBenefitList.css'],'scanStyles':false});
        }

    }
}
</script>

<style scoped>

</style>
