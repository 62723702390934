<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>

  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
        <MypageTop :laravel="laravel"></MypageTop>
        <div class="w-full lg:flex lg:justify-start">
          <div class="hidden lg:block xl:block">
            <SideMenu></SideMenu>
          </div>
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start flex-col">

                  <div class="flex justify-between">
                    <h1 class="text-lg dark:text-white lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800 mb-2">{{ i18n.menu.MemberCheck }}</h1>
                  </div>

                  <div class="w-full border-y-2 border-black divide-y bg-white">
                    <div class="py-2">
                      <div class="flex justify-between text-lg mb-4">
                        <h2 class="text-red-500 mb-2 before:content-['*'] text-sm">
                          {{ i18n.shop.mypage.memberfind_noti }}
                        </h2>
                      </div>

                      <div class="w-full flex flex-col lg:flex-row justify-start lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-md font-semibold mb-1">Name</div>
                        <div class="w-full lg:w-4/6"><input type='text' v-model="username" class="w-full lg:w-4/6"></div>
                      </div>
                      <div class="w-full flex flex-col lg:flex-row justify-start lg:py-2 lg:items-center mb-2">
                        <div class="w-full lg:w-1/6 text-md font-semibold mb-1">Birthday</div>
                        <div class="w-full lg:w-5/6 flex space-x-2">
                          <select name="strtDaySch" id="DaySch" class="w-23 md:w-28 text-sm md:text-base md:mr-0.5" v-model=DaySch>
                            <option value="">Day</option>
                            <option v-for="(item, index) in days" :key="item" :value="item">{{item}}</option>
                          </select>
                          <select name="strtMonthSch" id="MonthSch" class="w-24 md:w-28 text-sm md:text-base md:mr-0.5" v-model=MonthSch>
                            <option value="">Month</option>
                            <option value="01">Jan</option>
                            <option value="02">Feb</option>
                            <option value="03">Mar</option>
                            <option value="04">Apr</option>
                            <option value="05">May</option>
                            <option value="06">Jun</option>
                            <option value="07">Jul</option>
                            <option value="08">Aug</option>
                            <option value="09">Sep</option>
                            <option value="10">Oct</option>
                            <option value="11">Nov</option>
                            <option value="12">Dec</option>
                          </select>
                          <select name="strtDaySch" id="YearSch" class="w-24 md:w-28 text-sm md:text-base" v-model=YearSch>
                            <option value="">Year</option>
                            <option v-for="(item, index) in years" :key="item" :value="item">{{item}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex"><button class="my-2 py-2 px-4 leading-6 text-white bg-gray-900 w-full lg:w-1/5 xl:w-1/5 rounded" @click="findmember">Search</button></div>

                  <!--                  <ul class="w-full p-2 border-t-2 border-black">
                                      <div class="ml-2 mb-5">
                                        이름과 생년월일을 입력하시면 가입 여부의 조회가 가능합니다.
                                      </div>

                                      <li class="table w-full table-fixed border md:border-y md:border-x divide-slate-200 relative">
                                        <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-normal">{{ i18n.myoffice.memberName }}</span>
                                        <span class="block md:table-cell align-middle p-2.5 text-gray-700 bg-white"><input type='text' v-model="username" >  </span>
                                      </li>
                                      <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                                        <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-normal">{{ i18n.myoffice.birthday }}</span>
                                        <span class="block md:table-cell align-middle p-2.5 text-gray-700 bg-white">

                                          <select name="strtDaySch" id="DaySch" style="width:100px;" v-model=DaySch>
                                            <option value="">Day</option>
                                            <option v-for="(item, index) in days" :key="item" :value="item">{{item}}</option>
                                          </select>
                                          <select name="strtMonthSch" id="MonthSch" style="width:120px;" v-model=MonthSch>
                                            <option value="">Month</option>
                                            <option value="01">Jan</option>
                                            <option value="02">Feb</option>
                                            <option value="03">Mar</option>
                                            <option value="04">Apr</option>
                                            <option value="05">May</option>
                                            <option value="06">Jun</option>
                                            <option value="07">Jul</option>
                                            <option value="08">Aug</option>
                                            <option value="09">Sep</option>
                                            <option value="10">Oct</option>
                                            <option value="11">Nov</option>
                                            <option value="12">Dec</option>
                                          </select>
                                          <select name="strtDaySch" id="YearSch" style="width:120px;" v-model=YearSch>
                                            <option value="">Year</option>
                                            <option v-for="(item, index) in years" :key="item" :value="item">{{item}}</option>
                                          </select>
                                        </span>
                                      </li>

                                      <div class="bg-gray-300 mt-5 mb-5 w-full h-0.5"></div>

                                      <span class="content-center mt-10">
                                        <button type="button" v-on:click="findmember" class="search_btn">Search</button>
                                      </span>
                                    </ul>-->
                  <div id="result" style="display: none">
                    <div class="w-full border border-slate-200 text-center p-5 mt-5 bg-white" id="text_fild">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

  import numeral from 'numeral';
  import 'numeral/locales';
  import VueSimpleAlert from "vue3-simple-alert";
  import {  ShoppingCartIcon} from '@heroicons/vue/outline'
  import MypageTop from '../MypageTop.vue';
  import SideMenu from '../SideMenu.vue';
  import Loading from 'vue3-loading-overlay';



  export default {
    name: "memberfind",
    components : {
      VueSimpleAlert,
      ShoppingCartIcon,
      MypageTop,
      SideMenu,
      Loading,
    },
    data: () => ({
      i18n: window.i18n,
      laravel: window.Laravel,
      no_search_messsage: '',
      isLoggedIn: false,

      loading : false,
      mem: "",
      username:"",
      userkind:"",
      user_date:"",
      days:['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
      years:[],
      YearSch:"",
      DaySch:"",
      MonthSch:"",
    }),
    beforeCreate() {

    },
    created() {
      var year = new Date().getFullYear()-100;
      for (year; year <= new Date().getFullYear(); year++) {
        this.years.push(
          year
        );
      }
    },
    mounted() {
      if (window.Laravel.isLoggedin) {
        this.isLoggedIn = true;
        this.user = window.Laravel.user;
      }
    },
    methods:{
      findmember()
      {
        if(this.username == ""){
          VueSimpleAlert.alert("Please enter name","","",{});
        }

        if(this.birthday == ""){
          VueSimpleAlert.alert("Please enter birthday","","",{});
        }
        axios.post(window.Laravel.back_url + '/api/getmemberFind', {"username" : this.username,"birthday" : this.YearSch+this.MonthSch+this.DaySch}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
          //찾기
          var text ="";

          if(response.data.status!="no")
          {
            if(response.data.rank_cd == '00')
            {
              text = this.username+" is registered as a consumer member.<br>Enrollment Date ["+response.data.reg_date+"]<br><br>Consumer members can re-enroll as soon as they withdraw.";
            }
            else
            {
              text = this.username+" is registered as a member.<br>Enrollment Date ["+response.data.reg_date+"]<br><br>Members can re-enroll only after 6 months upon withdrawal.";
            }
          }
          else
          {
            text = "Membership enrollment history does not exist.";
          }
          $("#result").show();
          $("#text_fild").html(text);
        });
      }
    },




  }
</script>

<style scoped>

</style>
