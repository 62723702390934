<template>
    <div id="sidebar-menu" class=" ">
        <div class="navi">
            <div class="tit">MY OFFICE</div>
            <nav>
                <transition name="fade">
                    <ul id="back_office_left_menu"  class="">
                        <li v-for="Value in navigations" v-on:click="NavChange(Value.no)" :class="(Value.no === 99) ? 'bg-[#585959]' : '' " >
                            <a :href="Value.url">
                              <i :class="Value.icon" ></i><span><i v-if="(Value.no === 99)" class="fa fa-user-plus my-0 mr-3 ml-[-3] text-[#f6a431]"></i>{{ Value.title }}</span><i v-if="(Value.no !== 99 && Value.no !== 6 && Value.no !== 0)" class="fal fa-angle-down" aria-hidden="true"></i>
                            </a>
                            <ul v-for="sub in Value.child" v-if="Value.child">
                                <li v-show="navId == Value.no"  v-if="sub.gourl" style="display: none;">
                                    <a :href="sub.url" @click="goAutoLogin(sub.urlLink, 'tools/main.php')">{{ sub.title }}</a>
                                </li>
                                <li v-show="navId == Value.no"  v-else-if="sub.url == '/MyAcademy' && sub.center_top_chk == 1" style="display: none;">
                                    <a :href="sub.url">{{ sub.title }}</a>
                                </li>
                                <li v-show="navId == Value.no"   v-else-if="sub.url != '/MyAcademy' && sub.center_top_chk != 1" style="display: none;">
                                  <a :href="sub.url">{{ sub.title }}</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div class="pg_info">
                                <span>
                                    <a style="color:white; margin-bottom:10px; " target="_blank" href="https://cdn3.gcooperp.com/shop/shoppingGuide/GCOOP_IDN_Polices.pdf">Kode Etik dan Kebijakan Bisnis Penjualan Langsung</a><!-- 쇼핑몰 이용가이드 -->
                                    <a style="color:white; margin-bottom:10px; " target="_blank" href="https://cdn3.gcooperp.com/shop/shoppingGuide/GCOOP_IDN_Compensation_plan.pdf">Marketing plan</a>
                                </span>
                            </div>
                        </li>
                    </ul>
                </transition>

            </nav>
        </div>
<!--        <div id="gcmall-wrap" v-show="user_kind == '1'">-->
<!--            <ul>-->
<!--                <li><a @click="goAutoLogin('multi_register');" style="cursor: pointer;"><img src="/images/multi_usa3.jpg" alt="멀티코드 가입하기" /></a></li>-->
<!--            </ul>-->
<!--        </div>-->

    </div>
</template>
<script>
export default {
    name: "MyofficeNav",
    data: () => ({
        product_categorys: [],
        isLoggedIn: false,
        i18n: window.i18n,
        isDashBoard : window.isDashBoard,
        user_kind : window.Laravel.user_kind,
        center_top_chk : window.Laravel.center_top_chk,
        navigations : [
            {"url" :'#',"no" : 6,title : i18n.menu.MyRegister,icon:"fal fa-user-plus",gourl:'Y',urlLink : "register"},
            {"url" :'/MyPersonInfo',"no" : 0,title : i18n.menu.MyPersonInfo,icon:"fal fa-user"},

            // {"no" : 1,title : i18n.menu.MyOfficeMenu,icon:"fal fa-home-lg-alt",
            //     child:[
            //         {"url" : "/MyofficeMain","no" : 1,title : i18n.menu.MyofficeMain},
            //         {"url" : "/MyDashBoard","no" : 1,title : i18n.menu.MyDashBoard},
            //         // {"url" : "/MyWeeklyPromotion","no" : 1,title : i18n.menu.MyWeeklyPromotion},
            //         {"url" : "/MyAcademy","no" : 1,title : i18n.menu.MyAcademy, "center_top_chk": window.Laravel.center_top_chk}
            //     ]
            // },
            {"no" : 2,title : i18n.menu.MyPromotion,icon:"fal fa-coins",
              child:[
                {"url" : "/MyRankPromotion","no" : 2,title : i18n.menu.MyRankPromotion},
              ]},
            {"no" : 3,title : i18n.menu.MyPersonInfos,icon:"fal fa-user-cog",
                child:[
                    {"url" : "/MyMemberFavorites","no" : 2,title : i18n.menu.MyMemberFavorites},
                    {"url" : "/MyMember","no" : 2,title : i18n.menu.MyMember},
                    {"url" : "/MyMemberLegBox","no" : 2,title : i18n.menu.MyMemberLegBox},
                    {"url" : "/MyMemberLegLine","no" : 2,title : i18n.menu.MyMemberLegLine},
                    {"url" : "/MyMemberLegBoxCompression","no" : 2,title : i18n.menu.MyMemberLegBoxCompression},
                    {"url" : "/MyMemberRank","no" : 2,title : i18n.menu.MyMemberRank},
                    {"url" : "/MyMemberUpChange","no" : 2,title : i18n.menu.MyMemberUpChange},
                    // {"url" : "/MemberReport","no" : 2,title : i18n.menu.MemberReport},
                    {"url" : "/underMemberInfo","no" : 2,title : i18n.menu.underMemberInfo},
                ]},
            {"no" : 4,title : i18n.menu.MyOrders,icon:"fal fa-tasks",
                child:[
                    {"url" : "/MyOrderList","no" : 3,title : i18n.menu.MyOrderList},
                    {"url" : "/MyOrderDown","no" : 3,title : i18n.menu.MyOrderDown},
                    {"url" : "/MyOrderDirDown","no" : 3,title : i18n.menu.MyOrderDirDown},
                    {"url" : "/MyOrderLegs","no" : 3,title : i18n.menu.MyOrderLegs},
                    {"url" : "/MyOrderCancel","no" : 3,title : i18n.menu.MyOrderCancel},
                ]},
            {"no" : 5,title : i18n.menu.MyBenefit,icon:"fal fa-money-check",
                child:[
                    {"url" : "/MyBenefitSearch","no" : 4,title : i18n.menu.MyBenefitSearch},
                    {"url" : "/MyBenefitTotalSearch","no" : 4,title : i18n.menu.MyBenefitTotalSearch},
                    {"url" : "/MyBenefitDetailHistory","no" : 4,title : i18n.menu.MyBenefitDetailHistory},
                    {"url" : "/MyBenefitList","no" : 4,title : i18n.menu.MyBenefitList},
                    {"url" : "/MyBenefitTranslHistory","no" : 4,title : i18n.menu.MyBenefitTranslHistory},
                    //{"url" : "/MyBenefitTaxBreakHistory","no" : 4,title : i18n.menu.MyBenefitTaxBreakHistory},
                    // {"url" : "/Mycp58","no" : 4,title : i18n.menu.CpInformation},
                    {"url" : "/MyBenefitCalendar","no" : 4,title : i18n.menu.MyBenefitCalendar},
                    {"url" : "/MyBenefitAnalysis","no" : 4,title : i18n.menu.MyBenefitAnalysis},
                    {"url" : "/MyBenefitAllowanceReturn","no" : 4,title : i18n.menu.MyBenefitAllowanceReturn}
                ]},
            {"no" : 7,title : i18n.menu.MyCommunity,icon:"fal fa-comments",
                child:[
                    {"url" : "/MyNotice","no" : 5,title : i18n.menu.MyNotice},
                    {"url" : "/MyData","no" : 5,title : i18n.menu.MyData},
                    {"url" : "#","no" : 5,title : i18n.menu.MyEducation, gourl:'Y',urlLink : "brand"},
                    {"url" : "/MyInQuriy","no" : 5,title : i18n.menu.MyInQuriy},
                    {"url" : "/MyFAQ","no" : 5,title : i18n.menu.MyFAQ}
                ]},
            {"url" : "/","no" : 0,title : i18n.menu.Shopping,icon:"fal fa-shopping-cart"},
            // {"url" : "/BusinessGuide","no" : 0,title : i18n.menu.BusinessGuide,icon : "fa fa-book"}


        ],
        navId : 0,
    }),
    created() {
      if ( this.isDashBoard === true) {
        this.navigations.splice(1, 0,
          {
            "no": 1, title: i18n.menu.MyOfficeMenu, icon: "fal fa-home-lg-alt",
            child: [
              {"url": "/MyofficeMain", "no": 1, title: i18n.menu.MyofficeMain},
              {"url": "/MyDashBoard", "no": 1, title: i18n.menu.MyDashBoard},
              // {"url" : "/MyWeeklyPromotion","no" : 1,title : i18n.menu.MyWeeklyPromotion},
              {
                "url": "/MyAcademy",
                "no": 1,
                title: i18n.menu.MyAcademy,
                "center_top_chk": window.Laravel.center_top_chk
              }
            ]
          },
        )
      }
    },
  beforeCreate() {

        // 카테고리 메뉴
        // axios.post(window.Laravel.back_url+'/api/getMyOfficeMenu', {
        //     bz_cd: window.Laravel.bz_cd
        // }).then(response => {
        //     ///this.navigations = response.data;
        // }).catch(function (error) {console.log(error);});
    },
    mounted() {
        if (window.Laravel.isLoggedin) {
            this.isLoggedIn = true;
            this.user = window.Laravel.user;
        }
        /** S : 멀티코드 회원가입 메뉴 : 비기너 이상 */
        if(this.user_kind >= 1) {
          this.navigations.push({"url" : "#", "no" : 99, title : i18n.menu.multicode, icon:""});
        }
        /** E : 멀티코드 회원가입 메뉴 : 비기너 이상 */
    },
    methods: {
        NavChange(id){
            if(id == 6){
              this.goAutoLogin('register','');
            }
            if(id == 99){
              this.goAutoLogin('multi_register','');
            }
            if(this.navId == id){
                id = 0;
            }
            this.navId = id;
        },
        goAutoLogin(mode, url) {
            if(mode == 'register'){
                window.open("https://signup.gcoop.com/AutoLogin?xid=" + window.Laravel.xid_info + "&token=" + window.Laravel.xid_info);
            }else if(mode == 'multi_register'){
                window.open("https://registermulti.gcoop.com/AutoLogin?xid=" + window.Laravel.xid_info + "&token=" + window.Laravel.xid_info);
            }else{
                let config = {
                  headers: {
                    Authorization: 'Bearer ' + window.Laravel.access_token
                  }
                };

                axios.post(window.Laravel.back_url +'/api/goAutoLogin',{
                  mode: mode,
                  url: url,
                  bz_cd: window.Laravel.bz_cd,
                },config).then(response => {
                  if(response.data.href_url != ""){
                    window.open(response.data.href_url);
                  }
                }).catch(e => {
                });
            }
        }
    },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
#back_office_left_menu > li {
  cursor: pointer;
}
</style>
