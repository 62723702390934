<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>
    <form name="uni_form" method="post" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
        <div class="ctn-box">
            <div class="board-inner">
                <div class="myoffice_page">

                    <section class="accordion_notice_box">
                        <div class="accordion_notice_item">
                            <a class="accordion_notice_tit" href="#n">
                                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                                {{ i18n.myoffice.myoSearchForm.notice }}
                                <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>
                            </a>
                            <div class="accordion_notice_cont">
                                <div class="myo_search_notice">
                                    <p>{{ i18n.myoffice.myofficeAlert.uploadFileLimitedAlert }}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <ul class="fileUpWRap clr upload-box ">
                        <li class="upload-list">
                            <h5>E-KTP</h5>
                            <dl>
                                <dt>
                                    <div class="fix_rectangle_box">
                                        <div class="fix_box_inner">
                                            <img id="img1" width="100%" height="100%" style="vertical-align:middle;" :src="juminCheck.fileurl" alt="">
                                            <div v-if="juminCheck.check_yn == 'N' && juminCheck.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                                        </div>
                                    </div>
                                </dt>
                                <dd>
                                    <div class="upload_btn_box">
                                        <input v-show="juminCheck.check_yn == 'N'" type="file" name="EKTPFile" id="EKTPFile" @change="readURL">
                                        <div v-if="juminCheck.check_yn == 'N' && juminCheck.memo == '' && juminCheck.fileurl != ''" class="res-depending"><span class="upload-state1">Proceeding</span></div>
                                        <div v-else-if="juminCheck.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                                        <div v-else-if="juminCheck.check_yn == 'N' && juminCheck.memo != ''" class="res-memo">{{ juminCheck.memo }}</div>
                                    </div>
                                </dd>
                                <div class="item_content_notice" style="color:#444">
                                  Mohon unggah foto E-KTP berwarna
                                </div>
                            </dl>
                        </li>
                        <li class="upload-list">
                            <h5>Foto berwarna buku rekening</h5>
                            <ul id="myo_form">
                                <li>
                                    <span class="item_title">{{ i18n.join.join.bank }}</span>
                                    <span class="item_content" style="text-align: left;">
                                        <div class="relative z-[10]">
                                            <vSelect :options="bank_key_list" label="bank_name" :reduce="value => value.bank_cd" v-model="bank" :clearable="false" :disabled="idvoid.check_yn == 'Y'" ></vSelect>
                                        </div>
                                    </span>
                                </li>
                                <li>
                                    <span class="item_title">{{ i18n.join.join.account }}</span>
                                    <span class="item_content" style="text-align: left;"><input type="text" v-model="account" class="type_number" :readonly="idvoid.check_yn == 'Y'" ></span>
                                </li>
                                <li>
                                    <span class="item_title">{{ i18n.myoffice.AccountHolder }}</span>
                                    <span class="item_content" style="text-align: left;">
                                        <input type="text" v-model="depositor" :readonly="idvoid.check_yn == 'Y'" >
                                    </span>
                                </li>
                            </ul> <!-- 테이블1 -->
                            <dl>
                                <dt>
                                    <div class="fix_rectangle_box">
                                        <div class="fix_box_inner">
                                            <img id="img1" width="100%" height="100%" style="vertical-align:middle;" :src="idvoid.fileurl" alt="">
                                            <div v-if="idvoid.check_yn == 'N' && idvoid.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                                        </div>
                                    </div>
                                </dt>
                                <dd>
                                    <div class="upload_btn_box">
                                        <input v-show="idvoid.check_yn == 'N'" type="file" name="IDVOIDFile" id="IDVOIDFile" @change="readURL">
                                        <div v-if="idvoid.check_yn == 'N' && idvoid.memo == '' && idvoid.fileurl != ''" class="res-depending"><span class="upload-state1">Proceeding</span></div>
                                        <div v-else-if="idvoid.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                                        <div v-else-if="idvoid.check_yn == 'N' && idvoid.memo != ''" class="res-memo">{{ idvoid.memo }}</div>
                                    </div>
                                    <div class="item_content_notice" style="color:#444">
                                      Mohon unggah foto buku tabungan berwarna
                                    </div>
                                </dd>
                            </dl>
                        </li>
                        <li class="upload-list">
                            <h5>NPWP</h5>
                            <dl>
                                <dt>
                                    <div class="fix_rectangle_box">
                                        <div class="fix_box_inner">
                                            <img id="img1" width="100%" height="100%" style="vertical-align:middle;" :src="npwp.fileurl" alt="">
                                            <div v-if="npwp.check_yn == 'N' && npwp.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                                        </div>
                                    </div>
                                </dt>
                                <ul id="myo_form">
                                    <li>
                                        <span class="item_title">NPWP No</span>
                                        <span class="item_content" style="text-align: left;">
                                        <input type="text" id="npwp1" name="npwp1" class="key_npwp" maxLength="2" style="width:40px; " v-model="npwp1">.
                                        <input type="text" id="npwp2" name="npwp2" class="key_npwp" maxlength="3" style="width:40px; " v-model="npwp2">.
                                        <input type="text" id="npwp3" name="npwp3" class="key_npwp" maxlength="3" style="width:40px; " v-model="npwp3">.
                                        <input type="text" id="npwp4" name="npwp4" class="key_npwp" maxlength="1" style="width:40px; " v-model="npwp4">-
                                        <input type="text" id="npwp5" name="npwp5" class="key_npwp" maxlength="3" style="width:40px; " v-model="npwp5">.
                                        <input type="text" id="npwp6" name="npwp6" class="key_npwp" maxlength="3" style="width:40px; " v-model="npwp6">
                                    </span>
                                    </li>
                                </ul> <!-- 테이블1 -->
                                <dd>
                                    <div class="upload_btn_box">

                                        <input v-show="npwp.check_yn == 'N'" type="file" name="NPWPFile" id="NPWPFile" @change="readURL">
                                        <div v-if="npwp.check_yn == 'N' && npwp.memo == '' && npwp.fileurl != ''" class="res-depending"><span class="upload-state1">Proceeding</span></div>
                                        <div v-else-if="npwp.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                                        <div v-else-if="npwp.check_yn == 'N' && npwp.memo != ''" class="res-memo">{{ npwp.memo }}</div>
                                    </div>
                                </dd>
                                <div class="item_content_notice" style="color:#444">
                                  Mohon unggah foto NPWP berwarna
                                </div>
                                <input type="checkbox" class="submit_yn" id="submit_yn_NPWP" name="submit_yn_NPWP" value="''" v-model="submit_yn_NPWP">
                                <label for="submit_yn_NPWP" name="submit_yn_NPWP" style="padding-left : 2px;">
                                  <span class="gray01">Saya tidak ingin menyerahkan data</span>
                                </label>
                            </dl>
                        </li>
                        <li class="upload-list">
                            <h5>Kartu Keluarga</h5>

                            <dl>
                                <dt>
                                    <div class="fix_rectangle_box">
                                        <div class="fix_box_inner">
                                            <img id="img1" width="100%" height="100%" style="vertical-align:middle;" :src="fafile.fileurl" alt="">
                                            <div v-if="fafile.check_yn == 'N' && fafile.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                                        </div>
                                    </div>
                                </dt>
                                <ul id="myo_form">
                                    <li>
                                        <span class="item_title">Jenis</span>
                                        <span class="item_content" style="text-align: left;">
                                        <select id="sa_state" name="sa_state" class="!w-32 text-sm" v-model="sa_state">
                                            <option value="" >--Select--</option>
                                            <option value="TK0" >TK0</option>
                                            <option value="TK1" >TK1</option>
                                            <option value="TK2" >TK2</option>
                                            <option value="TK3" >TK3</option>
                                            <option value="K0" >K0</option>
                                            <option value="K1" >K1</option>
                                            <option value="K2" >K2</option>
                                            <option value="K3" >K3</option>
                                        </select>
                                    </span>
                                    </li>
                                </ul> <!-- 테이블1 -->

                                <dd>
                                    <div class="upload_btn_box">

                                        <input v-show="fafile.check_yn == 'N'" type="file" name="FAFile" id="FAFile" @change="readURL">
                                        <div v-if="fafile.check_yn == 'N' && fafile.memo == '' && fafile.fileurl != ''" class="res-depending"><span class="upload-state1">Proceeding</span></div>
                                        <div v-else-if="fafile.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                                        <div v-else-if="fafile.check_yn == 'N' && fafile.memo != ''" class="res-memo">{{ fafile.memo }}</div>
                                    </div>
                                </dd>
                                <input type="checkbox" class="submit_yn" id="submit_yn_FA" name="submit_yn_FA" value="''" v-model="submit_yn_FA">
                                <label for="submit_yn_NPWP" name="submit_yn_NPWP" style="padding-left : 2px;">
                                  <span class="gray01">Saya tidak ingin menyerahkan data</span>
                                </label>
                            </dl>
                        </li>
                        <li class="upload-list">
                            <h5>Surat Pernyataan</h5>
                            <dl>
                                <dt>
                                    <div class="fix_rectangle_box">
                                        <div class="fix_box_inner">
                                            <img id="img1" width="100%" height="100%" style="vertical-align:middle;" :src="mrfile.fileurl" alt="">
                                            <div v-if="mrfile.check_yn == 'N' && mrfile.memo != ''" class="res-memo2"><span class="upload-state3">Pending</span></div>
                                        </div>
                                    </div>
                                </dt>

                                <dd>
                                    <div class="upload_btn_box">

                                        <input v-show="mrfile.check_yn == 'N'" type="file" name="MRFile" id="MRFile" @change="readURL">
                                        <div v-if="mrfile.check_yn == 'N' && mrfile.memo == '' && mrfile.fileurl != ''" class="res-depending"><span class="upload-state1">Proceeding</span></div>
                                        <div v-else-if="mrfile.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                                        <div v-else-if="mrfile.check_yn == 'N' && mrfile.memo != ''" class="res-memo">{{ mrfile.memo }}</div>
                                    </div>
                                </dd>
                                <input type="checkbox" class="submit_yn" id="submit_yn_MR" name="submit_yn_MR" value="''" v-model="submit_yn_MR">
                                <label for="submit_yn_MR" name="submit_yn_MR" style="padding-left : 2px;">
                                  <span class="gray01">Saya tidak ingin menyerahkan data</span>
                                </label>
                            </dl>
                        </li>
                    </ul>
                </div>

                <div class="myo_condition_search" style="margin-top:20px;">
                    <!--<button type="button" onclick="history.back()"  class="go_list_btn">{{ i18n.shop.board.Contents }}</button>-->
                    <button type="button" onclick="location.href='/MyofficeMain'"  class="go_list_btn">{{ i18n.shop.board.Contents }}</button>
                    <button type="submit" id="btn_submit" class="search_btn">{{ i18n.myoffice.save }}</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import vSelect from "vue-select";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyforinerUpload",
    components : {
        vSelect,
        Loading,
        VueSimpleAlert
    },
    data: () => ({
        i18n               : window.i18n,
        mem                : "",
        npwp               : {"check_yn" : "N","memo" : "","file_name" : ""},
        idvoid             : {"check_yn" : "N","memo" : "","file_name" : ""},
        fafile             : {"check_yn" : "N","memo" : "","file_name" : ""},
        mrfile             : {"check_yn" : "N","memo" : "","file_name" : ""},
        accountCheck       : {"check_yn" : "N","memo" : "","file_name" : ""},
        bankStdRow         : "",
        juminCheck         : {"check_yn" : "N","memo" : "","file_name" : ""},
        visaCheck          : {"check_yn" : "N","memo" : "","file_name" : ""},
        taxCheck           : {"check_yn" : "N","memo" : "","file_name" : ""},
        loading            : false,
        bank_key_list      : "",
        bank               :"",
        account            : "",
        depositor          : "",
        jumin_no           : "",
        forigner_jumin_no  : "",
        bankkatacode       : "",
        bankhiracode       : "",
        bankbranchkatacode : "",
        isForigner         : false,
        branch_list        : [],
        branch_cd          : '',
        branch_name        : '',
        npwp1 : '',
        npwp2 : '',
        npwp3 : '',
        npwp4 : '',
        npwp5 : '',
        npwp6 : '',
        sa_state : '',
        submit_yn_NPWP : false,
        submit_yn_FA : false,
        submit_yn_MR : false,
    }),
    watch : {
        bank() {
            //this.getBranchList(this.bank); /* Get Branch Code/Name List */
        },
    },
    beforeCreate() {
        axios.post(window.Laravel.back_url + '/api/getBankList',{ bz_cd : window.Laravel.bz_cd }).then(response => {
            this.bank_key_list = response.data;
        });

        axios.post(window.Laravel.back_url + '/api/getjoininfoDocu',{ bz_cd : window.Laravel.bz_cd }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.juminCheck = response.data.ektp;
            this.idvoid     = response.data.idvoid;
            this.npwp       = response.data.npwp;
            this.fafile     = response.data.fa;
            this.mrfile     = response.data.mr;

            /** 제출을 원하지 않은지 여부 체크박스 */
            this.submit_yn_NPWP = (this.npwp.check_yn == 'R')   ? true : false;
            this.submit_yn_FA   = (this.fafile.check_yn == 'R') ? true : false;
            this.submit_yn_MR   = (this.mrfile.check_yn == 'R') ? true : false;
        });

        axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.mem                = response.data;
            this.isForigner         = response.data.nt_cd == "999" ? true : false;
            this.jumin_no           = response.data.jumin_no;
            this.sa_state           = response.data.sa_state ?? "";

            if(response.data.custom_field_4) {
              this.npwp1 = response.data.npwp1;
              this.npwp2 = response.data.npwp2;
              this.npwp3 = response.data.npwp3;
              this.npwp4 = response.data.npwp4;
              this.npwp5 = response.data.npwp5;
              this.npwp6 = response.data.npwp6;
            }

            this.depositor          = response.data.depositor != "null" ? response.data.depositor : "";
            this.account            = response.data.account != "null" ? response.data.account : "";
            this.bank               = response.data.bank_cd != "null" ? response.data.bank_cd : "";
            // this.bankkatacode       = response.data.bankkatacode != 'null' ? response.data.bankkatacode : '';
            // this.bankhiracode       = response.data.bankhiracode != 'null' ?response.data.bankhiracode : '' ;
            // this.bankbranchkatacode = response.data.bankbranchkatacode != 'null' ?response.data.bankbranchkatacode : '' ;
        });
    },
    methods : {
        submitForm: function (event) {
            let formData = new FormData();
            formData.append('bz_cd', window.Laravel.bz_cd);

            var EKTPFile   = document.getElementById("EKTPFile");
            var IDVOIDFile = document.getElementById("IDVOIDFile");
            var NPWPFile   = document.getElementById("NPWPFile");
            var FAFile     = document.getElementById("FAFile");
            var MRFile     = document.getElementById("MRFile");

            /** EKTP */
            if(this.juminCheck.check_yn == "N") {
                if((EKTPFile.files[0] === undefined) && !this.juminCheck.fileurl) {
                  VueSimpleAlert.alert("Silakan unggah E-KTP.","","",{});
                  return;
                }
                if(EKTPFile.files[0] !== undefined) {
                   formData.append('EKTPFile', EKTPFile.files[0]);
                }
            }
            /** EKTP */
            /** IDVOID */
            if(this.idvoid.check_yn == "N") {
                if(!this.bank) {
                  VueSimpleAlert.alert(i18n.join.twalert.bankInfo,"","",{});
                  return;
                } else if(!this.account) {
                  VueSimpleAlert.alert(i18n.join.twalert.bankAcctNum,"","",{});
                  return;
                } else if(!this.depositor) {
                  VueSimpleAlert.alert(i18n.join.twalert.accNm,"","",{});
                  return;
                }
                if((IDVOIDFile.files[0] === undefined) && !this.idvoid.fileurl) {
                  VueSimpleAlert.alert("Silakan unggah Foto berwarna buku rekening.","","",{});
                  return;
                }
                if(IDVOIDFile.files[0] !== undefined) {
                  formData.append('IDVOIDFile', IDVOIDFile.files[0]);
                }
                formData.append('bank'     ,  this.bank);
                formData.append('depositor',  this.depositor);
                formData.append('account'  ,  this.account);
            }
            /** IDVOID */

            /** S : 데이터 넘기고 싶지 않다고 체크하지 않은 경우 + 업로드 파일이 있을 경우 + 승인 전에만 값을 넘김 */
            /** NPWP */
            if(!this.submit_yn_NPWP && this.npwp.check_yn == "N") { // 승인 전에 npwp 제출함
                if(!this.npwp1 || !this.npwp2 || !this.npwp3 || !this.npwp4 || !this.npwp5 || !this.npwp6) {
                  VueSimpleAlert.alert("arap masukkan nomor NPWP yang benar.","","",{}); // 정확한 NPWP 번호를 입력해 주세요.
                  return;
                }
                let custom_field_4_chk = this.npwp1 + this.npwp2 + this.npwp3 + this.npwp4 + this.npwp5 + this.npwp6;
                if(custom_field_4_chk.length != 15) {
                  VueSimpleAlert.alert("Nomor NPWP terdiri dari 15 digit.","","",{}); // NPWP는 15자리 입니다.
                  return;
                }
                let custom_field_4 = this.npwp1 + "." + this.npwp2 + "." + this.npwp3 + "." + this.npwp4 + "-" + this.npwp5 + "." + this.npwp6;
                formData.append('custom_field_4', custom_field_4);
                if(NPWPFile.files[0] !== undefined) { // 첨부파일이 있을 때만 저장
                  formData.append('NPWPFile',NPWPFile.files[0]);
                }
            } else if (this.submit_yn_NPWP) { // npwp 제출을 원하지 않음
              formData.append('submit_yn_NPWP', 'R');
            }
            /** NPWP */
            /** FA */
            if(!this.submit_yn_FA && this.fafile.check_yn == "N") { // 승인 전에 fa 제출함
                if(!this.sa_state) {
                  VueSimpleAlert.alert("Silakan pilih jenis keluarga.","","",{}); // 가족 유형을 선택해 주세요.
                  return;
                }
                formData.append('sa_state', this.sa_state);
                if(FAFile.files[0] !== undefined) { // 첨부파일이 있을 때만 저장
                  formData.append('FAFile',FAFile.files[0]);
                }
            } else if (this.submit_yn_FA) { // fa 제출을 원하지 않음
              formData.append('submit_yn_FA', 'R');
            }
            /** FA */
            /** MR */
            if(!this.submit_yn_MR && this.mrfile.check_yn == "N") { // 승인 전에 mr 제출함
              if(MRFile.files[0] !== undefined) { // 첨부파일이 있을 때만 저장
                formData.append('MRFile',MRFile.files[0]);
              }
            } else if (this.submit_yn_MR) { // mr 제출을 원하지 않음
              formData.append('submit_yn_MR', 'R');
            }
            /** MR */
            /** E : 데이터 넘기고 싶지 않다고 체크하지 않은 경우 + 업로드 파일이 있을 경우에만 값을 넘김 */

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + window.Laravel.access_token
                }
            }

            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/forienUpload',
                formData
                , config).then(response => {
                VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
                    if(result){
                        location.replace("/MyforinerUpload");
                    }
                });
                this.loading = false;
            });

        },
        readURL: function (event) {
            var file = event.target.files[0];
            var reader = new FileReader();

            reader.onload = (e) => {
                if(event.target.id == "EKTPFile") this.juminCheck.fileurl = e.target.result;
                if(event.target.id == "IDVOIDFile") this.idvoid.fileurl = e.target.result;
                if(event.target.id == "NPWPFile") this.npwp.fileurl = e.target.result;
                if(event.target.id == "FAFile") this.fafile.fileurl = e.target.result;
                if(event.target.id == "MRFile") this.mrfile.fileurl = e.target.result;
            }
            reader.readAsDataURL(file);
        },
        /* S : Get Branch Code/Name List */
        // getBranchList : function(bank) {
        //   this.branch_list    = [];
        //   axios.post(window.Laravel.back_url+'/api/getBranchList',{bz_cd : window.Laravel.bz_cd, bank_cd : bank}).then(response => {
        //     if(response.data === '-1' || response.data.length < 1) {
        //       this.branch_cd_name = "Please Select Branch Name!";
        //       return;
        //     }
        //     Object.keys(response.data).forEach(key => {
        //       this.branch_list.push({
        //         id          : key,
        //         label       : response.data[key].branch_name,
        //         branch_cd   : response.data[key].branch_cd,
        //         branch_name : response.data[key].branch_name,
        //         bank_cd     : response.data[key].bank_cd,
        //       });
        //     });
        //   });
        // }
        /* E : Get Branch Code/Name List */
    }
}
</script>

<style >
.jumin_check_y { pointer-events:none;background-color:#BDBDBD }
.upload-box{display: flex; width:100%; justify-content: space-evenly;  -webkit-justify-content: space-evenly; flex-wrap: wrap;}
.upload-box .upload-list{font-size:1em; padding:30px 15px; word-break: break-word; width:calc(95% / 2); margin:10px; border-radius: 10px; min-height: 150px; box-shadow: 0px 0px 2px 2px #e7e7e7; /*border:1px solid #ccc;*/ }
.upload-box .upload-list h5{font-size:1.3em; font-weight:500; margin-top:10px; margin-bottom:20px; text-align: center;}
.upload-box .upload-list table{border:1px solid #eee; vertical-align: middle; width:100%;}
.upload-box .upload-list table tr{border-bottom:1px solid #fff;}
.upload-box .upload-list table tr td{padding: 10px; border-bottom:1px solid #ddd; word-break: break-all;}
.upload-box .upload-list table tr td:nth-child(1){width:30%; text-align: center; border-right:1px solid #ddd; background:#f9f9f9; word-break: keep-all;}
.upload-box .upload-list table input{width:100%;}
.upload-box .upload-list input[type=text], .upload-box .upload-list input[type=password]{height: 35px; border: 1px solid #BBB; width: 100%; font-size: 15px; }
.upload-box .upload-list dl{margin-top:20px;}
.upload-box .upload-list dl dt .fix_box_inner:before{content: "Please upload the file"; display: inline-block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%; text-align: center; color: #999;}
.res-depending{text-align: center; margin:15px 0;}
.res-admit{text-align: center; margin:15px 0;}
.res-memo{text-align: center; margin:15px 0; color:#ff5722;} /*보류 박스*/
.res-memo2{position:absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255,0.7);} /*보류 박스*/
.upload-state1{border:3px double red; padding:10px 15px; display: inline-block; color:red;} /*대기*/
.upload-state2{border:3px double blue; padding:10px 15px; display: inline-block; color:blue;} /*승인*/
.upload-state3{text-align: center; color: #ff5722; position: absolute; top: 50%; left: 50%; transform:translate(-50%, -50%) rotate(348deg); border: 7px double #ff5722; padding: 5px 8px; font-size:35px;} /*보류*/
.upload_btn_box{margin-top:10px;}

@media all and (max-width:1023px){
    .upload-box{display: flex; width:100%;}
    .upload-box .upload-list{width:100%; margin:0px; margin-bottom:20px;}
    .upload-state3{font-size:20px; border: 3px double #ff5722;} /*보류*/
}
</style>
