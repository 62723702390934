<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
      <address_search @set_address="set_address" @close_address_search="close_address_search" />
    </modalView>

    <div>
        <div >
            <ul class="border divide-slate-200 mt-5 relative"> <!-- class="mt-5 relative border-gray-400 border-solid"  class="border divide-slate-200 mt-5 relative"-->
                <li class="table w-full table-fixed border-b divide-slate-200">
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">{{ i18n.myoffice.refund.profile}}</span>
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700">{{ mem.userid }} ( {{ mem.username }})</span>
                </li>
                <li class="table w-full table-fixed border-b divide-slate-200">
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normall">{{ i18n.myoffice.refund.orderNumber}}</span>
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700"><div class="i_contents">{{ ord_no }}</div>
                        <p class="order_info">{{ i18n.myoffice.Order.PurchaseDate}}<span id="acc_dates">{{ row.ord_date }}</span>, {{ i18n.myoffice.Order.PastDueDate}} <span id="diff_days">{{ row.diff_days }}</span></p></span><!-- 구매일 : 경과기일 :-->
                </li>
                <li class="table w-full table-fixed border-b divide-slate-200">
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">{{ i18n.myoffice.refund.applicationType}}</span>
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 item_content_label">
                        <label class='radio_input_box'><input type='radio' v-model="refund_type" value='refund_cancle' checked><span class='checkmark'></span>{{ i18n.myoffice.refund.cancel }}</label>
                        <label class='radio_input_box'><input type='radio' v-model="refund_type" value='change_address'><span class='checkmark'></span>{{ i18n.myoffice.refund.changeShippingAddress }}</label>
                    </span>
                </li>
                <li class="table w-full table-fixed border-b divide-slate-200 " v-show="refund_type == 'change_address'">
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal hidden md:block">
                        <span v-show="r_type == 'C'">{{ i18n.myoffice.returnAddress}}</span>
                        <span v-show="r_type != 'C'">{{ i18n.myoffice.Address}}</span>
                    </span>
                    <span class="table-cell align-middle p-2.5 text-gray-700">
                        <ul id="myo_form" class="mgb_30">
                            <li class="table w-full table-fixed border-b divide-slate-200">
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">Reciever</span>
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 item_content_label m_contents z-30 relative">
                                  <input name="mobile" id="r_name" ref="d_name" type="text" placeholder="Reciever" v-model='r_name'
                                         class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                </span>
                            </li>
                            <li class="table w-full table-fixed border-b divide-slate-200">
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">Mobile</span>
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 item_content_label m_contents z-30 relative">
                                      <input name="mobile" id="mobile" ref="d_mobile" type="text" placeholder="Mobile" v-model='r_mobile'
                                     class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                </span>
                            </li>
                            <!-- 주소지 영역-->
                            <li class="table w-full table-fixed border-b divide-slate-200">
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">Address</span>
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 item_content_label m_contents px-3 py-3">
                                    <div class="w-full py-1">
                                      <span><input @click="search_address"  name="d_post" id="d_post" class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 mb-px" v-model="r_post" type="text" placeholder="POST"></span>
                                      <span class="px-1"><input type="button" @click="search_address" value="search address" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-2 px-2 text-sm text-white uppercase"></span>
                                    </div>
                                    <div class="w-full py-1">
                                      <span class="py-1">
                                        <input @click="search_address" name="addr2_1" id="addr2_2" class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 mb-px !max-w-[400px]" v-model="r_address1_str" type="text" >
                                      </span>
                                      <span class="py-1">
                                        <input name="addr2_2" id="addr2_1" class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 mb-px !max-w-[400px]" v-model="r_address2" type="text" placeholder="House Number / Name of building" maxlength="30" >
                                      </span>
                                    </div>
                                </span>
                            </li>

<!--                            <li class="item table_form">
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">State</span>
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 item_content_label m_contents z-30 relative">
                                  <vSelect name="state" id="state" ref="d_state" :options="state_list" v-model='d_state' :clearable="false" @option:selected="onChangeState"
                                           class="block w-full text-gray-600 text-sm"></vSelect>
                                </span>
                            </li>-->
<!--                            <li class="item table_form myo-addr-box">
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">City</span>
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 item_content_label m_contents">
                                    <div class="addr_detail_box2 mgb_10 z-20 relative">
                                      <vSelect name="city" id="city" ref="d_city" :options="city_list" v-model='d_city' :clearable="false" @option:selected="onChangeCity"
                                               class="block w-full text-gray-600 text-sm"></vSelect>

                                    </div>
                                </span>
                            </li>-->
<!--                            <li class="item table_form myo-addr-box">
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">Street</span>
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 item_content_label m_contents z-10 relative">
                                  <vSelect label="street" name="street" id="street" ref="d_street" :options="street_list" v-model='d_street' :clearable="false" @option:selected="onChangeStreet"
                                           class="block w-full text-gray-600 text-sm"></vSelect>
                                </span>
                            </li>-->
<!--                            <li class="item table_form myo-addr-box">
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">ZIP Code</span>
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 item_content_label m_contents">
                                    <div class="addr_box_us mgb_10">
                                        <input name="post" id="post" class="type_number us-addr-post addr_post_input" v-model="d_post" maxlength='5' type="text" >
                                    </div>
                                </span>
                            </li>-->

                        </ul>
                    </span>
                </li>
            </ul>
            <div id="cancelOrderRefund" style="margin:20px 0; padding:0 15px;">
                <div class="myo_condition_search2">
<!--                    <button type="button" onclick="history.back()" class="go_list_btn">{{ i18n.myoffice.Golist }}</button>-->
                    <button type="submit" class="search_btn" v-on:click="submitForm" v-show="row.processing < 20">{{ i18n.myoffice.refund.apply }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import alert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import vSelect from "vue-select";
import modalView from "../../modalView";

export default {
    name: "MyViewOrderCancel",
    components : {
        alert,
        Loading,
        vSelect,
        modalView
    },
    props : {
        ord_no : String,
        r_type : String,
        searchType : String,
        schUserId : String,
    },
    data: () => ({
        i18n: window.i18n,
        row: "",
        mem : "",
        refund_type: "refund_cancle",
        loading : false,
        r_name : "",
        r_mobile : "",
        r_address1 : "",
        r_address1_str : "",
        r_address2: "",
        r_post : "",
        r_city_code : "",
        address_search_open:false,

    }),
    created(){
        axios.post(window.Laravel.back_url+'/api/getOrdMst',{
            ord_no : this.ord_no,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            if(response.data.c_num != ""){
                alert.alert("이미 신청한 기록이 있습니다.","","",{allowOutsideClick : false}).then(r => {
                    this.$emit('close-modal');
                    location.replace("/MyOrderCancel");
                });
            }
            this.row            = response.data.row;
            this.mem            = response.data.mem;
            this.r_post         = response.data.ord_deli.r_post ?? '-';
            this.r_address1     = response.data.ord_deli.r_addr1;
            this.r_address1_str = response.data.ord_deli.r_addr1.replace(/\|@\|/g, ', ');
            this.r_address2     = response.data.ord_deli.r_addr2;
            this.r_name         = response.data.ord_deli.r_name;
            this.r_mobile       = response.data.ord_deli.r_mobile;
            this.r_city_code    = response.data.ord_deli.r_county;
        });
        /*this.getPostState();*/
    },
    methods : {
        submitForm : function (){
            this.loading = true;
            var formData = new FormData();
            formData.append('refund_type', this.refund_type);
            formData.append('r_address1' , this.r_address1);
            formData.append('r_address2' ,  this.r_address2);
            formData.append('r_mobile'   , this.r_mobile);
            formData.append('r_name'     , this.r_name);
            formData.append('ord_no'     , this.ord_no);
            formData.append('r_post'     , this.r_post);
            formData.append('r_city_code', this.r_city_code);

            formData.append('processing' , 1);
            formData.append('cmemo'      ,'');

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + window.Laravel.access_token
                }
            }
            var url = "orderCancel";
            if(this.refund_type == "change_address"){
                url = "shippingChange";
            }

            axios.post(window.Laravel.back_url+'/api/'+url,formData,config).then(response => {
                alert.alert(response.data.msg,"","",{allowOutsideClick : false}).then(r => {
                    if(response.data.ret > 0) { // 배송지 정상 변경됐을 떄만
                      this.$emit('close-modal');
                      window.location.reload();
                    }
                });
                this.loading = false;

            });

        },
        search_address(){
          this.address_search_open = true;
        },
        set_address(value){
          this.address_search_open = false;
          this.r_address1          = [value.provinsi, value.kota_kabupaten, value.kecamatan_distrik].join('|@|');
          this.r_address1_str      = [value.provinsi, value.kota_kabupaten, value.kecamatan_distrik].join(', ');
          this.r_post              = value.postcode;
          this.r_city_code         = value.city_code;
        },
        close_address_search(){
          this.address_search_open = false;
        }

    }

}
</script>

<style>
</style>
