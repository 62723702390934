<template>
  <carousel v-if="popup_list.length !== 0" class="product_new" :settings='settings' :wrap-around="true">
    <slide class="slide2" v-for="popup in popup_list" :key="popup.no">
      <div class="carousel__item">
        <p>
          <a :href="popup.link">
            <img :src="popup.href" alt="badge" style="z-index: 1;"/>
          </a>
        </p>
      </div>
    </slide>
    <template #addons>
      <Pagination/>
      <Navigation/>
    </template>
  </carousel>
  <div class="popup-btn" v-if="popup_list.length !== 0">
    <button v-on:click="set_close_cookie" class="popup-close-btn1">{{ i18n.shop.popup.noOpen }}</button> <!--하루동안 안보기-->
    <button v-on:click="set_modal_flag(false)" class="popup-close-btn2 popup-close-btn">{{
        i18n.shop.popup.close
      }}
    </button> <!--닫기-->
  </div>
</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';
import {useMobileDetection} from "vue3-mobile-detection";

let numeral = require("numeral");
let itemsToShow = 1;
let mobileChk = 1;
let defaultCate = '';

export default {
  name: "popup",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  setup() {
    const {isMobile} = useMobileDetection();   // 모바일 여부 체크
    if (isMobile()) itemsToShow = 1;
    if (isMobile()) mobileChk = 2;
    return {itemsToShow, mobileChk};

  },
  data: () => ({
    i18n: window.i18n,
    popup_list: [],
    settings: {
      itemsToShow: itemsToShow,
      snapAlign: 'left',
    },
  }),
  watch: {},
  created: function () {
    axios.post(process.env.MIX_BACK_URL + '/api/getPopupController', {
      device: this.mobileChk,
      is_login: (window.Laravel.isLoggedin) ? "1" : "0",
      bz_cd: 'id'
    }).then(response => {
      // console.log(response.data.length);
      if (response.data.length != 0) {
        response.data.forEach((value, index) => {
          this.popup_list.push({
            no: value.no,
            name: value.orgname,
            href: 'https://cdn.gcoop.me/storage/popup/data/' + value.filename,
            current: false,
            link: value.link
          });
        });
      } else if (response.data.length == 0) {
        this.set_modal_flag(false);//1

      }
    });

  },

  methods: {
    set_modal_flag(open_modal) {
      this.$emit("set_modal_flag", open_modal);//2
    },
    set_close_cookie() {
      $cookies.set("popup_timer", "1", "1d");
      // this.open_modal = false;
      this.set_modal_flag(false);
    },
  },


}
</script>

<style>
.carousel__prev, .carousel__next {
  background-color: #0edad6;
}

@media ( max-width: 767px ) {
  .carousel__slide, carousel__slide--active, carousel__slide--visible, slide2 {
    /*width: 100% !important;*/
  }

  .carousel__track {
    /*transform: translateX(-350px) !important;*/
  }
}
</style>
