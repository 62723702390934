<template>
    <dl class="myo_search_list ">
        <dt v-html="i18n.myoffice.MyFavorites.ConsumerChangeDay"></dt>
        <dd>
            <select name='diff' id="diff" v-model="diff" class="select1 wide">
<!--                <option value="" :data-display="i18n.myoffice.Select">{{ i18n.myoffice.Select }}</option>-->
                <option v-for="Value in diff_array" :value="Value.id" >{{ Value.title }}</option>
            </select>
        </dd>
    </dl>

</template>

<script>
export default {
    name: "consumerChangeForm",
    data: () => ({
        i18n: window.i18n,
        diff : "0",
        diff_array : [
            {id:0 , title:window.i18n.myoffice.None},
            {id:30 , title:"Dalam 30 hari"},
            {id:40 , title:"Dalam 40 hari"},
            {id:50 , title:"Dalam 50 hari"},
            {id:60 , title:"Dalam 60 hari"}]
    }),
}
</script>

<style scoped>

</style>
