<template>
    <div class="w-full mb-auto lg:w-48 border-2 px-4 rounded divide-y hidden md:block">
        <div class="py-4" v-for="cate_1 in this.cate_list" :key="cate_1.id">
            <div>
              <div class="pt-1 pb-2 text-base dark:text-white font-semibold leading-4 text-left text-gray-800 cursor-pointer" v-if="cate_1.depth==1" @click="onChange(cate_1.id)">{{ cate_1.category_title }}</div>
              <ul class="px-0.5" v-for="(Value,index) in cate_1.cate_2">
                <li class="py-0.5 text-sm cursor-pointer"><a :href="'/MypageAcademy/' + Value.id">{{ Value.category_title }}</a></li>
              </ul>
            </div>
        </div>
    </div>
    <div class="lg:mt-4 lg:hidden">
      <select @change="onChangeMobile" class="w-full">
        <option v-for="(Value_m,index_m) in this.cate_list_mobile" :value="Value_m.id" :selected="this.no == Value_m.id">{{ Value_m.title }}</option>
      </select>

    </div>
</template>

<script>
import modalView from "../../modalView";
import VueSimpleAlert from "vue3-simple-alert";
export default {
    name: "SideMenu",
    props:['laravel', 'no'],
    cate_1 : [],
    cate_2_list : [],
    components : {
      modalView,
    },
    data: () => ({
      i18n: window.i18n,
      address_book_open:false,
      cate_list : [],
      cate_list_mobile : [],
    }),
    created : function (){
      this.getOnlineAcademyCateogry();
    },
    methods : {

      getOnlineAcademyCateogry(){
        let config = {
          headers: {
            Authorization: 'Bearer ' + window.Laravel.access_token
          }
        };

        axios.post(window.Laravel.back_url+'/api/getOnlineAcademyCateogry',{
          bz_cd: window.Laravel.bz_cd
        },config)
          .then(response => {
            this.cate_list = response.data.list;
            this.cate_list.forEach((value, index) => {
              value.cate_2.forEach((value2, index2) => {
                this.cate_list_mobile.push({
                  id: value2.id,
                  title : value.category_title + " > " + value2.category_title,
                });
              });

            });
          });
      },
      onChangeMobile(event) {
        location.replace('/MypageAcademy/' + event.target.value);
      },
      onChange(id) {
        location.replace('/MypageAcademy/' + id);
      }
    }
}
</script>

<style scoped>

</style>
