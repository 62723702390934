<template>
    <dl class="myo_search_list">
        <dt v-html="i18n.myoffice.dashboard.MaintenanceQualificationEndingDate"></dt>
        <dd class="myo_date_table">
            <div class="myo_date_box3">
                <datepicker id="end_date" name="end_date" v-model="end_date" :disabledWeekDays="[6, 0 , 1, 2, 4, 5]"   inputClassName="myo_date_input" locale="en" :format="format" autoApply :enableTimePicker="false" :clearable="true"  ></datepicker>
            </div>
        </dd>
    </dl>

</template>

<script>
    import datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: "endDateForm",
    components: {
        datepicker
    },
    data: () => ({
        i18n: window.i18n,
        locale :"en",
        end_date: "",
        format : "yyyy-MM-dd",

    }),
    watch : {
        end_date(end_date) {
            this.set_end_date(end_date);
        },
    },
    methods : {
        set_end_date(end_date) {
            this.$emit("set_end_date",end_date);
        },
    }
}
</script>

<style scoped>

</style>
