<template>

  <h3 class="max-w-8xl mx-auto py-2 lg:max-w-7xl text-xl mb-6">Find My ID</h3>
  <div class="w-full my-3 lg:flex lg:flex-wrap">
    <div class="w-full lg:w-1/6 flex-none mb-1 lg:mb-0"><span class="text-red-500">*</span> Name</div>
    <div class="w-full lg:w-3/4 glow"><input type="text" class="rounded-md border border-zinc-700" v-model="name" style="height: 34.5px; border: 1px solid rgba(60, 60, 60, 0.2);"></div>
  </div>
  <div class="w-full my-3 lg:flex lg:flex-wrap">
    <div class="flex-none w-full lg:w-1/6 mb-1 lg:mb-0"><span class="text-red-500">*</span> Birth Day</div>
    <div class="glow w-full lg:w-3/4 relative z-30" style="text-align:left;">
      <vSelect :options="day" label="value" :clearable="false" class="w-20 inline-block border-zinc-700"  v-model="strtDay"  style="min-width:60px; max-width:60px;"></vSelect>

      <vSelect :options="month" label="value" :clearable="false" :reduce="value => value.title"  class="w-20 inline-block border-zinc-700"  v-model="strtMonth"  style="min-width:80px; max-width:80px; margin-left:5px"></vSelect>

      <vSelect :options="year" label="value" :clearable="false" class="w-20  inline-block border-zinc-700"  v-model="strtYear"  style="min-width:95px; max-width:95px; margin-left:5px"></vSelect>
    </div>
  </div>

  <div class="w-full lg:flex lg:flex-wrap my-3">
    <div class="w-full lg:w-1/6 mb-1 lg:mb-0"><span class="text-red-500">*</span> e-KTP</div>
    <div class="glow w-full lg:w-3/4 relative align-top	">
      <div class="value inline-block align-top" >
        <input type="text" v-model="jumin1" style="ime-mode:disabled; height:34.5px; border: 1px solid rgba(60, 60, 60, 0.2);" maxlength="16" class="type_number rounded-md border border-grey-60 py-2"
                placeholder="16 digits" @input="jumin1 = jumin1.replace(/[^0-9]/g, '')">
      </div>
    </div>
  </div>

  <div class="w-full lg:flex lg:flex-wrap my-3">
    <div class="w-full lg:w-1/6 mb-1 lg:mb-0"><span class="text-red-500">*</span> Phone Number</div>
    <div class="glow w-full lg:w-3/4" style="text-align:left;">
      <input type="text" v-model="mobile" style="ime-mode:disabled; height:34.5px; border: 1px solid rgba(60, 60, 60, 0.2);" maxlength="13" class="type_number rounded-md border border-grey-60 py-2"
             @input="mobile = mobile.replace(/[^0-9]/g, '')">
    </div>
  </div>
  <div id="submit_area" class="mt-8"><span class="btn"><input type="button" id="send_btn" value='Search' v-on:click="send_data" class="block bg-orange-300 hover:bg-orange-400 text-center text-white font-bold font-heading py-3 px-8 rounded-md uppercase transition duration-200"></span></div>
</template>

<style>
.vs__dropdown_menu{background:#fff;}
</style>
<script>
import vSelect from "vue-select";
import alert from "vue3-simple-alert";

export default {
  name: "MyFindId",
  components: {
    vSelect
  },
  data: () => ({
    i18n: window.i18n,
    year : [],
    day : [],
    month : [{title: "01", value  : "Jan"},
      {title: "02", value  : "Feb"},
      {title: "03", value  : "Mar"},
      {title: "04", value  : "Apr"},
      {title: "05", value  : "May"},
      {title: "06", value  : "Jun"},
      {title: "07", value  : "Jul"},
      {title: "08", value  : "Aug"},
      {title: "09", value  : "Sep"},
      {title: "10", value  : "Oct"},
      {title: "11", value  : "Nov"},
      {title: "12", value  : "Dec"},],
    name : "",
    pin_type_list : [{title: "N", value  : "NRIC"}],
    pin_type : "N",
    jumin1 : "",
    jumin2 : "",
    jumin3 : "",
    forign_jumin : "",
    mobile : "",
    strtYear : "",
    strtDay : "",
    strtMonth : "",
  }),
  created : function (){
    for (var i = current_year; i >= lastyear; i--){
      this.year.push(i);
    }
    for (var i = 1; i <= 31; i++){
      var day = i;
      if(day < 10){
        day = "0"+day;
      }
      this.day.push(day);
    }

  },
  methods:{
    send_data(){
      if(this.name == "") {
        alert.alert("Please enter your name!","","",{allowOutsideClick : false}).then(r => {});
        return false;
      }

      if(this.strtYear == "" || this.strtMonth == "" || this.strtDay == "") {
        alert.alert("Please select your birthday!","","",{allowOutsideClick : false}).then(r => {});
        return false;
      }

      if(this.mobile == "") {
        alert.alert("Please enter your Phone number!","","",{allowOutsideClick : false}).then(r => {});
        return false;
      }

      axios.post(window.Laravel.back_url+'/api/getSearchId', {
        username : this.name,
        birthday : this.strtYear+this.strtMonth+this.strtDay,
        mobile   : this.mobile,
        juminNo  : this.jumin1,
        bz_cd    : window.Laravel.bz_cd
      }).then(response => {
        if(response.data != 0){
          alert.alert("Your Member ID is "+response.data,"","",{allowOutsideClick : false}).then(r => {
            location.replace("/sso/login");
          });
        }else{
          alert.alert("The information entered does not match our records","","",{allowOutsideClick : false}).then(r => {
          });
        }

      });

    },

  }

}
</script>

<style scoped>

</style>
