<template>
    <dl class="myo_search_list">
        <dt>{{ i18n.myoffice.MyMember.division }}</dt>
        <dd class="flex_box">
            <select id="reg_type" v-model="reg_type" class="select1 wide">
                <option value="P" >{{ i18n.myoffice.PlacementSponsor }}</option>
                <option value="R" >{{ i18n.myoffice.EnrollmentSponsor }}</option>
            </select>
        </dd>
    </dl>
</template>

<script>
export default {
    name: "divisionForm",
    data: () => ({
        i18n: window.i18n,
        s_date : "",
        reg_type : "P"
    }),

}
</script>

<style scoped>

</style>
