<template>
    <div id="content_myoffice" class="clr">
        <div id="content_dashboard">
            <div style="height:20px;"></div>
            <div id="week"></div>
            <div id="close_info">
                <div>
                    <div class="position_relate_box">
                        <abbr title="{{ i18n.myoffice.myofficeAlert.CanTablescroll2 }}" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
                    </div>
                    <div class="table_scroll_box">
                        <table class="list-table" style="width:100%;">
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>{{ i18n.myoffice.Order.type }}</th>
                                <th>{{ i18n.myoffice.Country2 }}</th>
                                <th>{{ i18n.myoffice.MemberId }}</th>
                                <th>{{ i18n.myoffice.memberName }}</th>
                                <th>{{ i18n.myoffice.Rank }}</th>
                                <th>A Line(New)</th>
                                <th>B Line(New)</th>
                                <th>{{ i18n.myoffice.Commission.paymentAmount }}</th>
                            </tr>
                            </thead>
                            <tbody id="weekly_promotion">
                                <tr v-for="( value ) in lists">
                                	<td align=center></td>
                                	<td align=center>{{ value.kind }}</td>
                                	<td align=center>{{ value.bz_cd }}</td>
                                	<td align=center>{{ value.userid }}</td>
                                	<td align=center>{{ value.username }}</td>
                                	<td align=center>{{ value.rank_name }}</td>
                                	<td align=right>{{ value.a_pv }}</td>
                                	<td align=right>{{ value.b_pv }}</td>
                                	<td align=right>{{ value.amt7 }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div><!-- //wrap -->
    </div>
</template>

<script>
export default {
    name: "MyWeeklyPromotion",
    data: () => ({
        i18n: window.i18n,
        lists : [],
    }),
}
</script>

<style scoped>

</style>