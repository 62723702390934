<template>
    <loading :active="false"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <div align="center">
        <table >
            <colgroup>
                <col width="50px" />
                <col width="80px" />
                <col width="50px" />
            </colgroup>
            <tbody>
            <tr>
                <td id="btncal_prev" v-on:click="prevMonth" class="arrow-year" align="center">
                    <i class="fas fa-angle-left"></i>
                </td>
                <td id="cal_curYear" align="center" class="bold20" style="font-size: 30px;">{{ s_year }} </td>
                <td id="btncal_next" v-on:click="nextMonth" class="arrow-year"  align="center">
                    <i class="fas fa-angle-right"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <div style="height: 20px;"></div>

    <div id="">
        <div id="piechart" style="height: 300px;" height="">
            <canvas id="myChart2" height="200"></canvas>
        </div>

        <div id="chart_div" style="height: 300px;" >
            <canvas id="myChart1" height="200"></canvas>
        </div>

        <div style="height:30px;"></div>
        <div class="position_relate_box">
            <span class="table_notice_left">{{ i18n.myoffice.myofficeAlert.bonusListViewAlert4 }}</span>
            <abbr title="{{ i18n.myoffice.myofficeAlert.CanTablescroll2 }}" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
        </div>

        <div id="search_result"  class="list-table-wrap anal_list" style="margin-top:5px;">
            <table class="list-table">
                <thead>
                <tr>
                    <th>No</th>
                    <th>{{ i18n.myoffice.Commission.paymentDate }}</th>
                    <th>{{ i18n.myoffice.Commission.Position }}</th>
                    <th>{{ i18n.myoffice.Commission.OrderAmount }}</th>
                    <th>{{ i18n.myoffice.Commission.OrderBV }}</th>
                    <th class="px-6 py-3 whitespace-nowrap" v-for="Value in name_list">{{ Value.pay_name }}</th>
                    <th>{{ i18n.myoffice.Commission.TotalAmount }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(Value,index) in List" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th class="px-6 py-3 whitespace-nowrap">{{ total_count - ((page-1)*20 + index) }}</th><!-- 무실적경과일 -->
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.paydate }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.ranknewname }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.ordamt }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.ordpv }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.amt1 }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.amt2 }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.amt4 }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.amt5 }}</th>
                    <th class="px-6 py-3 whitespace-nowrap" v-show="cnt_top_chk != 0">{{ Value.amt6 }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.amt7 }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.totalamt }}</th>
                </tr>
                </tbody>

            </table>
        </div>

        <div id="chart_div2" style="height: 300px; margin-top:50px; ">
            <canvas id="myChart" height="200" ></canvas>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
    name: "MyBenefitAnalysis",
    components : {
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        total_week : 30,
        days : 1,
        start_week : 0,
        total_day : 0,
        List : [],
        s_year : dayjs().format("YYYY"),
        name_list : [],
        cnt_top_chk : 0,
        total_count : 0,
        page : 1,
        Loading,
    }),
    watch : {
        s_year: function(){
            axios.post(window.Laravel.back_url+'/api/chart1',{
                s_year : this.s_year
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.drawChart(response.data);
            });
            axios.post(window.Laravel.back_url+'/api/getAnalysticChart1',{
                s_year : this.s_year
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.drawChart2(response.data);
            });
            axios.post(window.Laravel.back_url+'/api/getAnalysticChart2',{
                s_year : this.s_year
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.drawChart3(response.data);
            });
            this.submitForm();
        }
    },
    created : function () {
        axios.post(window.Laravel.back_url+'/api/getNameList',{
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.name_list = response.data;
        });

        axios.post(window.Laravel.back_url+'/api/chart1',{
            s_year : this.s_year
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.drawChart(response.data);
        });
        axios.post(window.Laravel.back_url+'/api/getAnalysticChart1',{
            s_year : this.s_year
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.drawChart2(response.data);
        });
        axios.post(window.Laravel.back_url+'/api/getAnalysticChart2',{
            s_year : this.s_year
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.drawChart3(response.data);
        });
        this.submitForm();
    },methods : {
        submitForm : function (){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/MyBenefitAnalysis',{
                s_year : this.s_year,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                //console.log(response.data.list);
                this.List = response.data.list;
                this.total_count = response.data.total_count;
                //console.log(this.total_count);
                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
                this.cnt_top_chk = response.data.cnt_top_chk;
            });
        },
        drawChart($list) {
            var chartwidth = $('#piechart').width();
            if($list)
            {

                var chartchl = $list.chartchl.split("|");
                var ctx = document.getElementById('myChart2').getContext('2d');

                var cartchd = $list.chartchd;
                var txtArray = new Array();
                cartchd = cartchd.split(",");
                for(var i = 0 ; i < cartchd.length; i++){
                    var subArray = new Array(cartchd[i]);
                    txtArray.push(cartchd[i]);

                }

                var myChart = new Chart(ctx, {
                    type: 'doughnut',
                    data: {
                        labels: chartchl,
                        datasets: [{
                            label: 'myoffice.Commission.BonusRate(%)',  /* 수당비율 */
                            data: txtArray,
                            backgroundColor: [
                                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                            ],
                            borderColor: [
                                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                            ],
                            borderWidth: 1
                        }]
                    },

                    options: {
                        title: {
                            display: true,
                            text: ''
                        },
                        maintainAspectRatio: false,
                    }
                });

            }
        },
        drawChart2($list) {
            var chartwidth = $('#piechart').width();
            if($list)
            {

                var chartchl = new Array(i18n.myoffice.jan,i18n.myoffice.feb,i18n.myoffice.mar,i18n.myoffice.apr,i18n.myoffice.may,i18n.myoffice.jun,
                    i18n.myoffice.jul,i18n.myoffice.aug,i18n.myoffice.sep,i18n.myoffice.oct,i18n.myoffice.nov,i18n.myoffice.dec);

                var ctx = document.getElementById('myChart1').getContext('2d');

                var cartchd = $list.chartchd;
                var txtArray = new Array();
                cartchd = cartchd.split(",");
                for(var i = 0 ; i < cartchd.length; i++){
                    var subArray = new Array(cartchd[i]);
                    txtArray.push(cartchd[i]);

                }
                var labels  = $list.chartchx.split("|");

                var myChart = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: i18n.myoffice.Commission.BonusRate+'(%)',  /* 수당비율 */
                            data: txtArray,
                            backgroundColor: [
                                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                            ],
                            borderColor: [
                                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                            ],
                            borderWidth: 1
                        }]
                    },
                    options: {
                        title: {
                            display: true,
                            text: i18n.myoffice.dashboard.yearmonthbonus /* 년 월별 수당금액 */
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    userCallback: function(value, index, values) {
                                        if(parseInt(value) >= 1000){
                                            value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                        }
                                        return value;
                                    }
                                }
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    var value = data.datasets[0].data[tooltipItem.index];
                                    if(parseInt(value) >= 1000){
                                        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    }
                                    return value;
                                }
                            } // end callbacks:
                        },
                    },
                });

            }
        },
        drawChart3($list) {
            var chartwidth = $('#piechart').width();
            if($list)
            {

                var chartchl = new Array(i18n.myoffice.jan,i18n.myoffice.feb,i18n.myoffice.mar,i18n.myoffice.apr,i18n.myoffice.may,i18n.myoffice.jun,
                    i18n.myoffice.jul,i18n.myoffice.aug,i18n.myoffice.sep,i18n.myoffice.oct,i18n.myoffice.nov,i18n.myoffice.dec);

                var ctx = document.getElementById('myChart').getContext('2d');

                var cartchd = $list.chartchd;
                var txtArray = new Array();
                cartchd = cartchd.split(",");
                for(var i = 0 ; i < cartchd.length; i++){
                    var subArray = new Array(cartchd[i]);
                    txtArray.push(cartchd[i]);

                }

                var myChart = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: Array(i18n.myoffice.jan,i18n.myoffice.feb,i18n.myoffice.mar,i18n.myoffice.apr,i18n.myoffice.may,i18n.myoffice.jun,
                            i18n.myoffice.jul,i18n.myoffice.aug,i18n.myoffice.sep,i18n.myoffice.oct,i18n.myoffice.nov,i18n.myoffice.dec),
                        datasets: [{
                            label: i18n.myoffice.Commission.BonusPrice, /* 수당금액 */
                            data: txtArray,
                            backgroundColor: [
                                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                            ],
                            borderColor: [
                                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                            ],
                            borderWidth: 1
                        }]
                    },
                    options: {
                        title: {
                            display: true,
                            text: i18n.myoffice.Commission.BonusRate /* 년 월별 수당금액 */
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    userCallback: function(value, index, values) {
                                        if(parseInt(value) >= 1000){
                                            value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                        }
                                        return value;
                                    }
                                }
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    //console.log(data);
                                    var value = data.datasets[0].data[tooltipItem.index];
                                    if(parseInt(value) >= 1000){
                                        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    }
                                    return value;
                                }
                            } // end callbacks:
                        },
                    },
                });

            }
        },
        prevMonth(){
            if(this.s_year-1 >= 2017){
                this.s_year--;
            }
        },
        nextMonth(){
            if(this.s_year+1 <= dayjs().format("YYYY")){
                this.s_year++;
            }
        }
    }
}
</script>

<style scoped>

</style>
