'use strict'


const pick = {
  // 현장 픽업 여부 확인
  isOnlyPickup: (products) => {
    let isOnlyPickup = false;
    products.forEach(function (data) {

      if (Number(data.product.seq_pdc) === Number(window.pickupCategoryIdx)) {
        isOnlyPickup = true;
      } else if (isOnlyPickup === true) {
        // error
      }
    })
    return isOnlyPickup;
  },
  getAddress : () => {
    return {
      'd_address1' : '71 UBI ROAD 1, OXLEY BIZHUB',
      'd_address2' : '#05-45 (S)',
      'd_post' : '408732'
    }
  }
}

export default pick
