<template>
    <dl class="myo_search_list">
        <dt>{{ i18n.myoffice.MyMember.step }}</dt>
        <dd>
            <span class="leg_input_box">
                <input type="text" name="level" id="level" v-model="level" class='type_number' style="max-width:50%; width:100px;">
            </span>
            <span class="mini_notice"> ('0' {{ i18n.myoffice.all }})</span>
        </dd>
    </dl>
</template>

<script>
export default {
    name: "stepForm",
    data: () => ({
        i18n: window.i18n,
        level: "3",
    }),
}
</script>

<style scoped>

</style>
