<template>

    <div class="member-search">
        <loading :active="false"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"></loading>

        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <input type="hidden" name="order" id="order">
            <input type="hidden" name="sort" id="sort">
            <section class="myo_condition_box">

                <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date"></dateForm>
            </section>
            <div class="myo_condition_search">
                <button type="reset" onclick="" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>

        </form>
    </div>
    <section class="accordion_notice_box">
        <div class="accordion_notice_item">
            <a class="accordion_notice_tit" href="#n">
                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                {{ i18n.myoffice.myoSearchForm.notice }}
                <div class="bmenu x7">
                    <span class="btop">
                        <i class="fas fa-chevron-up" aria-hidden="true"></i>
                    </span>
                </div>
            </a>
            <div class="accordion_notice_cont">
                <div class="myo_search_notice">
                    <p>Rincian pembayaran bonus berdasarkan tanggal pembayaran bonus.</p>
                </div>
            </div>
        </div>
    </section>

    <div class="summary position_relate_box">
        <strong>Total</strong> <em>{{ total }}</em>
        <abbr rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
    </div>
    <div id="search_result"  class="list-table-wrap">
        <table class="list-table">
            <caption>리스트</caption>
            <colgroup>
                <col width="100px"/>
                <col width="100px"/>
                <col width="100px"/>
                <col width="100px"/>
                <col width="140px"/>
                <col width="160px"/>
                <col width="*"/>
            </colgroup>
            <thead>
            <tr>
                <th class="ltit" style="height:35px;">{{ i18n.myoffice.Commission.paymentDate }}</th>
                <th>{{ i18n.myoffice.Commission.TotalAmount }}</th>
                <th>{{ i18n.myoffice.Commission.incomeTax }}</th><!-- 송금수수료 -->
                <th>{{ i18n.myoffice.refund.residentTax }}</th>
                <th>{{ i18n.myoffice.refund.transferFee }}</th><!-- 송금수수료 -->
                <th>{{ i18n.myoffice.Commission.amountPayable }}</th>
                <th>{{ i18n.myoffice.Commission.bankName }}</th>
                <th>{{ i18n.myoffice.Commission.accountNumber }}</th>
                <th>{{ i18n.myoffice.Commission.bankHolder }}</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(Value,index) in List" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="ltit text-center" style="height:27px;">{{ Value.give_date }}</td>
                <td class="ltit text-right"  style="height:27px;">{{ formatPrice(Value.total_amt) }}</td>
                <td class="ltit text-right"  style="height:27px;">{{ formatPrice(Value.tax1_amt) }}</td>
                <td class="ltit text-right"  style="height:27px;">{{ formatPrice(Value.tax2_amt) }}</td>
                <td class="ltit text-right"  style="height:27px;">{{ formatPrice(Value.bank_amt) }}</td>
                <td class="ltit text-right"  style="height:27px;">{{ formatPrice(Value.true_amt) }}</td>
                <td class="ltit text-center" style="height:27px;">{{ Value.bank_name }}</td>
                <td class="ltit text-center" style="height:27px;">{{ Value.account }}</td>
                <td class="ltit text-center" style="height:27px;">{{ Value.depositor }}</td>
            </tr>


            <tr>
                <td class="ltit text-center" style="height:27px;">Total Amount</td>
                <td class="ltit text-right"  style="height:27px;">{{formatPrice(total_amt)}}</td>
                <td class="ltit text-right"  style="height:27px;">{{formatPrice(tax1_amt)}}</td>
                <td class="ltit text-right"  style="height:27px;">{{formatPrice(tax2_amt)}}</td>
                <td class="ltit text-right"  style="height:27px;">{{formatPrice(bank_amt)}}</td>
                <td class="ltit text-right"  style="height:27px;">{{formatPrice(true_amt)}}</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>

            </tbody>
        </table>
    </div>
</template>

<script>
import dateForm from '../../searchForm/dateForm.vue';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
    name: "MyBenefitTranslHistory",
    components : {
        dateForm,
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        loading : false,
        s_date: '',
        e_date: '',
        fullPage : true,
        total  : '',
        List : [],
        total_amt : 0,
        tax1_amt : 0,
        tax2_amt : 0,
        bank_amt : 0,
        true_amt : 0,
    }),
    methods : {
        submitForm : function (event){
            event.preventDefault();
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/MyBenefitTranslHistory',{
                s_date : this.s_date,
                e_date : this.e_date,
                checkCondition : this.checkCondition,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.List = response.data.list.data;
                this.total_count = response.data.total_count;
                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
                this.total_amt = 0;
                this.tax1_amt = 0;
                this.tax2_amt = 0;
                this.bank_amt = 0;
                this.true_amt = 0;

                response.data.list.data.forEach((value, index) => {
                    this.total_amt += parseInt(value.total_amt);
                    this.tax1_amt += parseFloat(value.tax1_amt);
                    this.tax2_amt += parseInt(value.tax2_amt);
                    this.bank_amt += parseInt(value.bank_amt);
                    this.true_amt += parseInt(value.true_amt);
                });
            });

        },
        set_s_date(s_date){
            this.s_date = s_date;
        },
        set_e_date(e_date){
            this.e_date = e_date;
        },
        formatPrice(value,digit) {
            // 코드 개선 add by hskim :: 2023-07-21 오후 2:46
            value = isNaN(value) ? 0 : value;
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
    }
}
</script>

<style scoped>

</style>
