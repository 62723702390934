<template>
    <dl class="myo_search_list">
        <dt>{{ i18n.myoffice.myoSearchForm.selectDate }}</dt>
        <dd class="flex_box">
            <select id="date_type" v-model="date_type" class="select1 wide">
                <option value="register">{{ i18n.myoffice.RegistrationDate }}</option>
                <option value="update" selected >{{ i18n.myoffice.UpdateDate }}</option>
            </select>
        </dd>
    </dl>
</template>

<script>
export default {
    name: "selectDateForm",
    data: () => ({
        i18n: window.i18n,
        date_type: "update",
    }),
}
</script>

<style scoped>

</style>
