<template>
    <dl class="myo_search_list">
        <dt>{{ i18n.myoffice.GPStatus }}</dt>
        <dd>
            <select name='gpstatus' id="gpstatus" v-model="gpstatus" class="select1 wide">
                <option value="" :data-display=" i18n.myoffice.Select ">{{ i18n.myoffice.Select }}</option>
                <option value="1">{{ i18n.myoffice.GPcontinue }}</option>
                <option value="-1">{{ i18n.myoffice.GPunEnrollment }}</option>
            </select>
        </dd>
    </dl>
</template>

<script>
export default {
    name: "gpStatusForm",
    data: () => ({
        i18n: window.i18n,
        gpstatus: "",
    }),
}
</script>

<style scoped>

</style>
