<template>
  <dl class="myo_search_list">
    <dt>{{ i18n.myoffice.Country }}</dt>
    <dd>
      <vSelect :options="countries" label="label" :reduce="value => value.id" v-bind="bz_cd"></vSelect>
    </dd>
  </dl>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "countryForm",
    components : {
      vSelect
    },
    data: () => ({
      i18n: window.i18n,
      countries : [],
    }),
    props:{
      bz_cd :""
    },
    watch : {
        bz_cd(bz_cd){
            this.set_bz_cd(bz_cd);
        }
    },
    beforeCreate() {
      axios.post(window.Laravel.back_url+'/api/getCountryData',{
      }).then(response => {
          this.countries.push({
              id: '',
              label: i18n.myoffice.all,
          });

          Object.keys(response.data).forEach(key => {
          this.countries.push({
            id: key,
            label: response.data[key],
          });
        });
      });
    },
    methods : {
        set_bz_cd(bz_cd){
            this.$emit("set_bz_cd",bz_cd);
        }
    }
}
</script>

<style scoped>

</style>
