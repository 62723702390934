<template>
    <dl class="myo_search_list" >
        <dt v-show="title_hide == false && is_regtitle == false">{{ i18n.myoffice.myoSearchForm.selectDate }}</dt>
        <dt v-show="title_hide == false && is_regtitle == true">{{ i18n.myoffice.RegistrationDate }}</dt>

        <dd class="accordion_cont w-full block lg:grid lg:grid-cols-4 lg:gap-2">
            <div v-show="is_view == true" class="z-[12] lg:mb-0 mb-2">
                <vSelect :options="date_type_list" label="value" :reduce="value => value.title"  :clearable="false" v-model="date_types"  ></vSelect>
            </div>
            <div class="relative z-[11] lg:mb-0 mb-2">
                <vSelect :options="dateSelect" label="value" :reduce="value => value.title"  :clearable="false" v-model="selectTerm"  ></vSelect>
            </div>
            <div class="myo_date_table z-10 col-span-4">
                <div class="myo_date_box">
                    <datepicker id="s_date" name="s_date" v-model="startDate"  inputClassName="myo_date_input" locale="en" :format="format" autoApply :enableTimePicker="false" :clearable="false"  ></datepicker>
                </div>
                <span class="hyphen_icon">-</span>
                <div class="myo_date_box">
                    <datepicker id="e_date" name="e_date" v-model="endDate"  inputClassName="myo_date_input" locale="en" :format="format"  autoApply :enableTimePicker="false" :clearable="false"  ></datepicker>
                </div>
            </div>
        </dd>
    </dl>
</template>

<script>
import vSelect from "vue-select";
import datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: "dateForm",
    components: {
        datepicker,
        vSelect
    },
    data: () => ({
        i18n: window.i18n,
        locale :"ko",
        format : "dd/MM/yyyy",
        startDate : date_week,
        endDate : date_today,
        selectTerm : "",
        //date_type: "",
        dateSelect : [{title : "", value : i18n.myoffice.Select},
            {title : "today", value : i18n.search.today},
            {title : "week", value : i18n.search.week},
            {title : "current_month", value : i18n.search.thisMonth},
            {title : "before_1month", value : i18n.search.before1month},
            {title : "before_2month", value : i18n.search.before2month},
            {title : "before_3month", value : i18n.search.before3month},
            {title : "all_date", value : i18n.search.allday}],
        date_type_list : [],
        date_type_list1 : [{title : "", value : i18n.myoffice.Select},
            {title : "register", value : i18n.myoffice.RegistrationDate},
            {title : "update", value : i18n.myoffice.UpdateDate}],
        date_type_list2 : [{title : "", value : i18n.myoffice.Select},
            {title : "order", value : i18n.myoffice.Order.orderDate},
            {title : "accept", value : i18n.myoffice.Order.depositdate}],
        date_types : "",

    }),
    props:{
        s_date : "",
        e_date : "",
        is_view: false,
        start_plusday : "",
        title_hide : false,
        date_type: "",
        is_regtitle : true,
    },
    watch : {
        startDate(s_date){
          this.set_s_date(s_date);
        },
        endDate(e_date){
          this.set_e_date(e_date);
        },
        date_types(date_types){
            this.set_date_type(date_types);
        },
        selectTerm : function (){
            //console.log(this.selectTerm);
            if(this.selectTerm == "today"){
                this.today();
            }
            if(this.selectTerm == "week"){
                this.week();
            }
            if(this.selectTerm == "current_month"){
                this.current_month();
            }
            if(this.selectTerm == "before_1month"){
                this.before_1month();
            }
            if(this.selectTerm == "before_2month"){
                this.before_2month();
            }
            if(this.selectTerm == "before_3month"){
                this.before_3month();
            }
            if(this.selectTerm == "all_date"){
                this.all_date();
            }

        }

    },
    created : function () {
        this.endDate = this.start_plusday == 1 ? date_plus_7day : date_today;
        if(this.date_type == "register"){
            this.date_type_list = this.date_type_list1;
        }
        if(this.date_type == "order"){
            this.date_type_list = this.date_type_list2;
        }
        this.set_s_date(this.startDate);
        this.set_e_date(this.endDate);
        this.date_types = this.date_type;
    },
    methods : {
        dateChange(){
        },
        today(){
            this.startDate = date_today;
            this.endDate = date_today;
        },
        yesterday() {
            this.startDate = date_yesterday;
            this.endDate = date_yesterday;
        },
        this_month() {
            this.startDate = date_this_month_s;
            this.endDate = date_today;
        },
        prev_month() {
            this.startDate = date_prev_month_s;
            this.endDate = date_prev_month_e;
        },
        last_3month(){
            this.startDate = date_before_3month_s;
            this.endDate = date_today;
        },
        this_year() {
            this.startDate = date_this_year_s;
            this.endDate = date_today;
        },
        all_date() {
            this.startDate = date_all_first;
            this.endDate = date_today;
        },
        week(){
            this.startDate = date_week;
            this.endDate = date_today;
        },
        half_month(){
            this.startDate = date_half_month;
            this.endDate = date_today;
        },
        current_month(){
            this.startDate = date_current_month;
            this.endDate = date_today;
        },
        before_1month(){
            this.startDate = date_before_1month_s;
            this.endDate = date_before_1month_e;
        },
        before_2month(){
            this.startDate = date_before_2month_s;
            this.endDate = date_before_2month_e;
        },
        before_3month(){
            this.startDate = date_before_3month_s;
            this.endDate = date_before_3month_s;
        },
        set_s_date(s_date){
            this.$emit("set_s_date",s_date);
        },
        set_e_date(e_date){
             this.$emit("set_e_date",e_date);
        },
        set_date_type(date_types){
            this.$emit("set_date_type",date_types);
        }
    }
}
</script>

<style>
.myo_date_input{
  border-radius: 8px !important;
  padding : 2px 8px 2px 8px;
  height: 35px;
}
</style>
