<template>
    <div>
        <div style="padding:7px;">
            <table class="nwrite" width="100%" cellspacing="0" cellpadding="0" style="margin-top:20px; margin-bottom:30px;">
                <colgroup>
                    <col width="100%">
                </colgroup>
                <tbody>
                <tr>
                    <td style="padding:2px 1px;">
                        <table width="100%" class="upchange_table" style="max-width:400px; margin:0 auto">
                            <tbody>
                            <tr>
                                <td>
                                    <table width="100%" style="border: 1px solid #ddd;">
                                        <tbody>
                                        <tr style="border-bottom:1px solid #ddd">
                                            <td class="gray01 banner02" style="text-align:center; word-break:break-word; border-right: 1px solid #ddd; font-size: 16px; padding: 10px;">
                                                <strong>{{ i18n.myoffice.memberUpChangePop.lineInfo }}</strong>
                                            </td>
                                            <td>
                                                <table style="width: 100%;">
                                                    <tbody>
                                                    <tr>
                                                        <td style="padding:5px">
                                                            <input id="InputId" name="InputId" type="text" v-model="InputIds" style="width:100%; height: 35px; font-size: 16px; padding: 0 5px; border: 1px solid #BBB; max-width: 320px;" maxlength="30">
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="gray01 banner02" style="text-align:center; word-break:break-word; border-right: 1px solid #ddd; font-size: 16px; padding: 10px;">
                                                <strong>{{ i18n.myoffice.memberUpChangePop.campus }}</strong>
                                            </td>
                                            <td style="padding:5px">
                                                <select id="selectRank" name="selectRank" class="select" style="width:100%; height: 35px; font-size: 16px; padding: 0 5px; border: 1px solid #BBB; max-width: 320px;">
                                                    <option value="">All</option>
                                                </select>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding-top: 20px; text-align:center">
                                    <button type="button" @click="searchrid" style="background-color:#000; color:white;border: 1px solid #aaa; padding: 8px; font-weight: 400; cursor: pointer; min-width: 150px; max-width: 250px;">{{ i18n.join.join.Search }}</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>




                    </td>
                </tr>
                </tbody>
            </table>

            <div style="text-align: center;color: red; margin: 8px; word-break: break-word">
                ↓ Please click the member name after checking the information below. <!-- 추후 번역 적용 필요 -->
            </div>

            <table style="width: 100%; max-width:800px; margin:0 auto;">
                <caption>검색 리스트 영역</caption>
                <colgroup>
                    <col width="100%">
                </colgroup>
                <tbody>
                <tr>
                    <td id="grid" align="center">
                        <table id="table01" class="nlist_tit" style="width:100%; border-collapse: collapse; ">
                            <caption>검색 리스트</caption>
                            <colgroup>
                                <col width="40%">
                                <col width="30%">
                                <col width="*">
                            </colgroup>
                            <thead>
                            <tr>
                                <td class="ltit" style="height:35px; word-break:break-word;">{{ i18n.myoffice.memberUpChange.member }}</td>
                                <td style="word-break:break-word;">{{ i18n.myoffice.memberUpChange.rank }}</td>
                                <td style="word-break:break-word;">{{ i18n.myoffice.memberUpChange.pid }}</td>
                            </tr>
                            </thead>
                            <tbody id="ridbody">
                            <tr class='riddata' v-if="List.length > 0" v-for="Value in List" v-on:click="setiddata(Value.userid,Value.username,Value.abposcnt)">
                                <td class="ltit" style="height: 50px;">{{ Value.username }}</td>
                                <td>{{ Value.rank_name }}</td>
                                <td>{{ Value.p_name }}</td>

                            </tr>
                            <tr v-else>
                                <td class="ltit" style="height:400px;" colspan="3">{{ i18n.myoffice.memberUpChangePop.notcie }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>

            <div style="height:20px;"></div>


        </div>

    </div>

</template>

<script>
export default {
    name: "MyMemberUpChangeSearch",
    data: () => ({
        i18n: window.i18n,
        List : [],
        InputIds : "",
    }),
    props : {
        rrId : String,
        InputId : String,
        searchType : String,
        schUserId : String,
    },
    created() {
        this.InputIds = this.InputId;
    },
    methods : {
        searchrid(){

            let exec_mode = "ridsearch";
            if(this.searchType == "1"){
                exec_mode = "ridsearch";
            }
            else if(this.searchType == "2"){
                exec_mode = "pidsearch";
            }
            else return;

            axios.post(window.Laravel.back_url+'/api/'+exec_mode,{
                inputId : this.InputIds, rrId : this.rrId, schUserId : this.schUserId
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {

                //console.log(response.data);
                if(response.data.chk == 0){
                    if(this.searchType == "1" && response.data.is_valid == 0 ){
                        alert(i18n.join.twalert.globalalert1);
                        return;
                    }else if(this.searchType == "2" && response.data.abposcnt == 0 ){
                        alert(i18n.myoffice.memberUpChangelegCheckeNotice.b1001);
                        return;
                    }else{
                        response.data.rstCodeLst.forEach((value, index) => {
                            var ppname = value.p_name.substring(0, 2) + "***";
                            var username = value.username.substring(0, 2) + "***";
                            var abposcnt = 0;
                            if(this.searchType == "2"){
                                abposcnt = value.abposcnt;
                            }

                            this.List.push({
                                userid: value.userid,
                                username: value.username,
                                rank_name: value.rank_name,
                                p_name: ppname,
                                abposcnt : abposcnt

                            });
                        });
                    }
                }
            })
        },
        setiddata(userid,username,abposcnt){
            this.$emit("setiddata",userid,username,abposcnt);
        }
    }
}
</script>

<style>
thead td { text-align:center; }
.ltit { text-align:center; }
caption {position:absolute; visibility:hidden; width:0; height:0; overflow:hidden; font-size:0;}
#table01 td{ border:1px solid #aaa; }
#table01  tbody tr:hover {
    background-color: #f5f5f5;
}
.modal-card{max-width:60% !important; min-height:700px !important;}
@media all and (max-width:1023px){
    .modal-card{max-width:90% !important;}
}
</style>

