<template>
  <section class="relative block w-full">
    <div class="main_visual relative w-full">
      <carousel v-if="banners.length !== 0" :settings=" settings " ref="myCarousel">
        <slide v-for=" (item) in banners " :key=" item.seq_si ">
          <div class="carousel__item">
              <a :href=" item.link_url ">
              <img :src="item.href" v-bind:alt=" item.title ">
              </a>
          </div>
        </slide >
        <template #addons>
          <Pagination />
          <Navigation />
        </template>
      </carousel>
  </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import VueSimpleAlert from "vue3-simple-alert";

/* javascript numberFormat library */
let numeral = require("numeral");

export default{
  name: "VisualBanners",
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },setup() {
        // const { isMobile } = useMobileDetection();   // 모바일 여부 체크
        // return { isMobile };
    },
    data: () => ({
        // carousel settings
        settings: {
            wrapAround: true,
            snapAlign: 'end',
            autoplay: '5000',
            mouseDrag: false,
            touchDrag: false,
        },
        seen: false,
        banners: [],
    }),
    created() {
        /* S : 2023년 7일 1일 이전 가입자 한정으로 회원정보 수정 여부 체크(사전가입자 회원정보수정 요청 목적) */
        // if(window.Laravel.modinfo_check) {
        //   VueSimpleAlert.alert(i18n.myoffice.modinfoMsg,"","",{allowOutsideClick : false}).then((result) => {
        //     if(result){
        //       location.replace("/MyPersonInfo");
        //     }
        //   });
        // }
        /* E : 2023년 7일 1일 이전 가입자 한정으로 회원정보 수정 여부 체크 */
        // 배너
        axios.post(window.Laravel.back_url+'/api/banner', {
            bz_cd: window.Laravel.bz_cd,
            limit: 8
        }).then(response => {
            // this.banners = response.data;
            response.data.forEach((value, index) => {
                this.banners.push({ seq_si:value.seq_si, link_url: value.linkurl, href: 'http://cdn.gcoop.me/shop' + value.path_name + value.file_name, current: false, title: value.title });
            });
        });
    }
}
$(window).scroll(function(e){
  if($(".main_visual").length == 1){
    if($(document).scrollTop() > 820){
      $(".quick-menu").addClass("fix");
      $(".quick-menu").removeAttr("style");
    }else{
      $(".quick-menu").removeClass("fix");
      $(".quick-menu").css("top","820px");
    }
  }else if($(".main_visual").length == 1){
    if($(document).scrollTop() > 641){
      $(".quick-menu").addClass("fix");
      $(".quick-menu").removeAttr("style");
    }else{
      $(".quick-menu").removeClass("fix");
      $(".quick-menu").css("top","641px");
    }
  }else if($(".main_visual").length == 0){
    if($(document).scrollTop() > 155){
      $(".quick-menu").addClass("fix");
      $(".quick-menu").removeAttr("style");
    }else{
      $(".quick-menu").removeClass("fix");
      $(".quick-menu").css("top","155px");
    }
  }
});

</script>
<style>
.quick-menu{position:absolute;z-index:10;right:20px;top:820px;}
.main_visual .carousel__prev,
.main_visual .carousel__next {
  box-sizing: content-box;
  border: 2px solid white;
  opacity: 0.6;
}
.main_visual .carousel__prev{ margin-left:20px;}
.main_visual .carousel__next {margin-right:20px;}

.swal2-content { padding: 30px 20px 0 20px !important; word-break: break-word; font-weight: 600; color: #545454; font-size: 17px; }
</style>

