<template>
  <article class="myo_search_area">
    <form name='form_search' id="form_search" v-on:submit.prevent="submitForm" style="clear:both;">
      <section class="myo_condition_box">
        <section class="flex_box_quad">
          <memberForm :selectid="selectid"></memberForm>
        </section>
      </section>
      <div class="myo_condition_search2">
        <button type="reset" onclick="location.replace('/underMemberInfo')" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
        <button type="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
      </div>
    </form>
  </article>

  <section class="accordion_notice_box">
    <div class="accordion_notice_item">
      <a class="accordion_notice_tit" href="#n">
        <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
        {{ i18n.myoffice.myoSearchForm.notice }}
        <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>
      </a>
      <div class="accordion_notice_cont">
        <div class="myo_search_notice">
          <p>Informasi yang ditampilkan akan tertunda hingga satu jam dari data standar penutupan.</p>
        </div>
      </div>
    </div>
  </section>


  <h2 class="myo_table_tit">{{ i18n.myoffice.dashboard.memberinformation }}</h2>
  <ul id="myo_form">
    <li class="item table_form">
      <span class="item_title info_title">{{ i18n.myoffice.memberName }}</span>
      <span class="item_content info_content">{{ mem_dash["USERID"].d_code }} ({{ mem_dash["USERID"].d_val }})</span>
    </li>
    <li class="item table_form">
      <span class="item_title">{{ i18n.myoffice.RegistrationDate }}</span>
      <span class="item_content">{{ mem_dash["REG_DATE"].d_code }}</span>
    </li>
    <li class="item table_form">
      <span class="item_title">{{ i18n.myoffice.Rank }}</span>
      <span class="item_content" style='position: relative;'>{{ mem_dash["RANK_NEW"].d_val }}</span>
    </li>
    <li class="item item table_form">
      <span class="item_title title_sponsor">{{ i18n.myoffice.EnrollmentSponsor }}</span>
      <span class="item_content info_content">{{ mem.r_id }} ({{ mem.r_name }})</span>
    </li>
    <li class="item item table_form">
      <span class="item_title">{{ i18n.myoffice.PlacementSponsor }}</span>
      <span class="item_content info_content">{{ mem.p_id }} ({{ mem.p_name }})</span>
    </li>
  </ul>

  <h2 class="myo_table_tit">{{ i18n.myoffice.dashboard.personinformation }}</h2>
  <ul id="myo_form">
    <li class="item item table_form">
      <span class="item_title">{{ i18n.myoffice.handphone }}</span>
      <span class="item_content">{{ mem.mobile }}</span>
    </li>
    <li class="item item table_form">
      <span class="item_title">{{ i18n.myoffice.email }}</span>
      <span class="item_content">{{ mem.e_mail }}</span>
    </li>
    <li class="item item table_form">
      <span class="item_title">{{ i18n.myoffice.Address }}</span>
      <span class="item_content">{{ mem.addr1 }}</span>
    </li>

  </ul>

  <h2 class="myo_table_tit">{{ i18n.myoffice.dashboard.businessinformation }}</h2>
  <ul id="myo_form">
    <li class="item table_form">
      <span class="item_title">{{ i18n.myoffice.dashboard.Local }} {{ i18n.myoffice.dashboard.Maintenance }}</span>
      <span class="item_content">{{ mem_dash["UJI_LOCAL"].d_code == "Y" ? i18n.myoffice.active  : i18n.myoffice.inactive }}</span>
    </li>
    <li class="item table_form">
      <span class="item_title info_title">{{ i18n.myoffice.dashboard.Global }} {{ i18n.myoffice.dashboard.Maintenance }}</span>
      <span class="item_content info_content">{{ mem_dash["UJI_GLOBAL"].d_code == "Y"  ? i18n.myoffice.active : i18n.myoffice.inactive }}</span>
    </li>
    <li class="item table_form">
      <span class="item_title" v-html="i18n.myoffice.dashboard.MaintenanceQualificationEndingDate"></span>
      <span class="item_content">{{ mem_dash["UJI_LOCAL_EDATE"].d_val && mem_dash["UJI_LOCAL_EDATE"].d_val != "0000" ? mem_dash["UJI_LOCAL_EDATE"].d_val : "" }}</span>
    </li>
    <li class="item table_form">
      <span class="item_title" v-html="i18n.myoffice.dashboard.MaintenanceQualificationEndingDateGlobal"></span>
      <span class="item_content" style='position: relative;'>{{ mem_dash["UJI_GLOBAL_EDATE"].d_val && mem_dash["UJI_GLOBAL_EDATE"].d_val != "0000" ? mem_dash["UJI_GLOBAL_EDATE"].d_val : "" }}</span>
    </li>
    <li class="item item table_form">
      <span class="item_title title_sponsor">{{ i18n.gp.us.AutoshipStatus }}</span>
      <span class="item_content info_content">{{ mem_dash["AS_ADMIT_DAY_KIND"].d_code  == "Y"  ? i18n.myoffice.Join  : i18n.myoffice.GpUnJoin }}</span>
    </li>
    <li class="item item table_form">
      <span class="item_title">{{ i18n.gp.us.AutoshipNextPayment }}</span>
      <span class="item_content info_content">{{ mem_dash["AS_ADMIT_DAY"].d_val && mem_dash["AS_ADMIT_DAY"].d_val != "0000" ? mem_dash["AS_ADMIT_DAY"].d_val : "" }}</span>
    </li>
    <li class="item item table_form">
      <span class="item_title">{{ i18n.myoffice.bottommemberCount }}(A)</span>
      <span class="item_content info_content">{{ mem_dash["A_CNT"].d_val  }}</span>
    </li>
    <li class="item item table_form">
      <span class="item_title">{{ i18n.myoffice.bottommemberCount }}(B)</span>
      <span class="item_content info_content">{{ mem_dash["B_CNT"].d_val }}</span>
    </li>
    <li class="item table_form">
      <span class="item_title">{{ i18n.myoffice.dashboard.CombinedBVoftheLast4Weeks }}(A)</span>
      <span class="item_content">{{ mem_dash["A_PV_RANK"].d_val }}</span>
    </li>
    <li class="item table_form">
      <span class="item_title">{{ i18n.myoffice.dashboard.CombinedBVoftheLast4Weeks }}(B)</span>
      <span class="item_content">{{ mem_dash["B_PV_RANK"].d_val }}</span>
    </li>
    <li class="item table_form">
      <span class="item_title info_title">{{ i18n.myoffice.dashboard.PersonalWeeklyPerformance }}</span>
      <span class="item_content info_content">{{ mem_dash["ORD_BV_WEEK"].d_val }}</span>
    </li>
    <li class="item table_form">
      <span class="item_title">{{ i18n.myoffice.dashboard.DSCWeekCombinedBVTotal2 }}</span>
      <span class="item_content">{{ mem_dash["MY_SOBI"].d_val }}</span>
    </li>
    <li class="item table_form">
      <span class="item_title">{{ i18n.myoffice.MyFavorites.noPerformanceDate }}</span>
      <span class="item_content" style='position: relative;'>{{ mem_dash["ORD_LAST_CNT"].d_val == "-1" ? "" : mem_dash["ORD_LAST_CNT"].d_val }}</span>
    </li>
    <li class="item item table_form">
      <span class="item_title title_sponsor">{{ i18n.myoffice.dashboard.MostRecentPurchaseDate }}</span>
      <span class="item_content info_content">{{ mem_dash["ORD_MAX_DATE"].d_val != "-" && mem_dash["ORD_MAX_DATE"].d_val != '000000' ? mem_dash["ORD_MAX_DATE"].d_val : ""  }}</span>
    </li>
    <li class="item item table_form">
      <span class="item_title">{{ i18n.myoffice.dashboard.goUrl }}</span>
      <span class="item_content info_content">
          <span v-show="mem_dash['USERID'].d_code"><button type="button" class="download_btn3"  v-on:click="copy_register_url(xid)">{{ i18n.myoffice.Copy }}</button></span>
        </span>
    </li>
  </ul>
</template>

<script>
  import memberForm from '../../searchForm/memberForm.vue'

  export default {
    name: "underMemberInfo",
    components : {
      memberForm,
    },
    data: () => ({
      i18n: window.i18n,
      List : [],
      total_count : 0,
      page : 1,
      loading : false,
      mem_dash : {"USERID":{d_val : "",d_col : ""},"REG_DATE":{d_val : "",d_col : ""},"RANK_NEW":{d_val : "",d_col : ""}
        ,"UJI_LOCAL":{d_val : "",d_col : ""},"UJI_GLOBAL":{d_val : "",d_col : ""},"UJI_LOCAL_EDATE":{d_val : "",d_col : ""},"UJI_GLOBAL_EDATE":{d_val : "",d_col : ""}
        ,"AS_ADMIT_DAY_KIND":{d_val : "",d_col : ""},"AS_ADMIT_DAY":{d_val : "",d_col : ""},"A_CNT":{d_val : "",d_col : ""},"B_CNT":{d_val : "",d_col : ""}
        ,"A_PV_RANK":{d_val : "",d_col : ""},"B_PV_RANK":{d_val : "",d_col : ""},"ORD_BV_WEEK":{d_val : "",d_col : ""},"MY_SOBI":{d_val : "",d_col : ""}
        ,"ORD_LAST_CNT":{d_val : "",d_col : ""},"ORD_MAX_DATE":{d_val : "",d_col : ""}},
      mem : { "addr1" : "","r_id" : "","r_name" : "","p_id" : "","p_name" : "","mobile" : "" ,"e_mail" : "" },
        xid : "",
    }),
    props : ['selectid','no'],
    mounted () {
      if(this.selectid != null) {
        search_id.value = this.no;
        this.submitForm();
      }
    },
    methods : {
      submitForm: function (event) {
        this.loading = true;
        axios.post(window.Laravel.back_url + '/api/underMemberinfo', {
          sid: search_id.value,
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
          this.mem_dash = response.data.mem_array;
          this.mem = response.data.mem;
          this.xid = response.data.xid;
        });

      },
      copy_register_url(xid){
        this.detailshow = false;
        var link = 'https://signup.gcoop.com/RegToken?xid='+encodeURIComponent(xid);

        var md = $.Zebra_Dialog('<div class="url_tit"><i class="fas fa-link"></i>&nbsp;url copy</div><textarea id="reg_url">'+link+'</textarea><div class="url_notice"><div class="url_tit"><i class="fas fa-lightbulb-exclamation" aria-hidden="true"></i>&nbsp;notice</div>If the URL is used to join as a member, the sponsor is automatically designated for the left line based on the major performance.</div>', {
          width: 1000,
          title:  i18n.myoffice.myofficeAlert.RegisterURLTit,
          type :  false,
          modal: true,
          overlay_close: false,
          buttons:
            [
              {caption: 'Copy', callback: function() {
                  $('#reg_url').select();
                  document.execCommand('copy');
                }}
            ]
        });
      },
    }
  }
</script>

<style scoped>

</style>
