<template>
    <loading :active="false"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>
    <div class="member-search">
      <dd class="accordion_cont w-full block lg:grid lg:grid-cols-4 lg:gap-2">
        <div  class="z-[12] lg:mb-0 mb-2">
          <vSelect :options="date_type_list" label="value" :reduce="value => value.title"  :clearable="false" @change="setSelect(this.s_year)" v-model="s_year"></vSelect>
        </div>
      </dd>
    </div>

    <div class="summary" style="clear: both;"><strong>Total</strong> <em>{{ total_count }}</em></div>
    <input type=hidden name=exec_mode value=''>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">

        <table id="member_list" class="relative overflow-x-auto shadow-md sm:rounded-lg" >
            <thead>
            <tr class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
              <th class="px-6 py-3 whitespace-nowrap">Commission Year</th>
              <th class="px-6 py-3 whitespace-nowrap">Team Bonus</th>
              <th class="px-6 py-3 whitespace-nowrap">Matching Bonus</th>
              <th class="px-6 py-3 whitespace-nowrap">Builder Bonus</th>
              <th class="px-6 py-3 whitespace-nowrap">Promotion Bonus</th>
              <th class="px-6 py-3 whitespace-nowrap">Academy Bonus</th>
              <th class="px-6 py-3 whitespace-nowrap">Reduction/Others</th>

              <th class="px-6 py-3 whitespace-nowrap">Total Amount (RM)</th>
              <th class="px-6 py-3 whitespace-nowrap">Download</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(Value,index) in cp58 ">
              <td class="px-6 py-3 w-3 whitespace-nowrap">{{Value.years}}</td>
              <td class="px-6 py-3 w-3 whitespace-nowrap">{{Value.team_bonus}}</td>
              <td class="px-6 py-3 w-3 whitespace-nowrap">{{Value.matching_bonus}}</td>
              <td class="px-6 py-3 w-3 whitespace-nowrap">{{Value.builder_bonus}}</td>
              <td class="px-6 py-3 w-3 whitespace-nowrap">{{Value.promotion_bonus}}</td>
              <td class="px-6 py-3 w-3 whitespace-nowrap">{{Value.academy_bonus}}</td>
              <td class="px-6 py-3 w-3 whitespace-nowrap">{{Value.reduction_other}}</td>
              <td class="px-6 py-3 w-3 whitespace-nowrap">{{Value.actual_amount}}</td>
              <td class="px-6 py-3 w-3 whitespace-nowrap"><button v-on:click="popup_cp58(Value.years);" class="p-2 m-0 text-sm text-white bg-gray-500 rounded">View CP58</button></td>
            </tr>
            </tbody>
        </table>
    </div>
    <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
        <v-pagination
            v-model="page"
            :pages=total_page
            :range-size="1"
            active-color="#DCEDFF">
        </v-pagination>
    </div>
</template>

<script>

import dateForm from '../../searchForm/dateForm.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";
import vSelect from "vue-select";

export default {
    name: "Mycp58",
    components: {
        dateForm,
        VPagination,
        Loading,
      vSelect
    },setup() {
        //
    },
    data: () => ({
        i18n: window.i18n,
        title: [],
        products: [],
        page : 1,
        total_page : 1,
        total_count : 0,
        s_year: "",

        checkCondition : false,
        name_list : [],
        List : [],
        loading : false,
        list_msg : "Click 'Search' to view the data",
        cnt_top_chk : 0,
        cp58: "",
        date_type_list : [{title : "", value : i18n.myoffice.Select}],
    }),
    beforeCreate() {


    },

    created() {
        // 배너
        this.loading = true;
        this.setCpList();
    },
    methods:{
        setCpList(){
          this.date_type_list =[{title : "", value : i18n.myoffice.Select}];//선택 옵션값 초기화
          axios.post(window.Laravel.back_url + '/api/getCommissionYearly', {year : this.s_year,}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.cp58 = response.data;
            this.total_count = response.data.length;
            for(let i = 0; i<response.data.length;i++){
              this.date_type_list.push({title:response.data[i].years,value:response.data[i].years});
            }
          });
        },

        setSelect(year){
           //년도 변경시 자동 출력
            this.setCpList();
        },
      popup_cp58(year) {
        window.open(window.Laravel.back_url+"/api/getCp58?userid="+window.Laravel.user+"&year="+year);
      }

    },
    watch : {
      s_year(){
        console.log(this.s_year);
      }
    }
}
</script>

<style scoped>

</style>
