<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>
  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
        <MypageTop :laravel="laravel"></MypageTop>
        <div class="w-full lg:flex lg:justify-start">
          <div class="hidden lg:block xl:block">
            <SideMenu></SideMenu>
          </div>
          <div class="w-full">
            <div class="max-w-8xl mx-auto sm:pl-6 lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-2">
                <div class="flex justify-start item-start space-y-2 flex-col">
                  <div class="flex justify-between">
                    <h1 class="text-lg dark:text-white lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800 mb-2">{{ i18n.menu.MyInQuriy}}</h1>
                  </div>
                </div>
              </div>
              <MyInQuriyView :mid_mypage="mid_mypage" :no="this.no"></MyInQuriyView>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {TruckIcon} from '@heroicons/vue/outline';
import MypageTop from '../MypageTop.vue';
import SideMenu from '../SideMenu.vue';
import VPagination from "@hennge/vue3-pagination";
import moment from 'moment';
import MyInQuriyView from '../../myoffice/Board/MyInQuriyView.vue';

export default {
  name: "mypage_inquiry_view",
  components : {
    VueSimpleAlert,
    TruckIcon,
    MypageTop,
    SideMenu,
    VPagination,
    moment,
    MyInQuriyView
  },
  props: ['no'],
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    limit: 4,
    products: [],
    orderList : [],
    no_search_messsage: '',
    isLoggedIn: false,
    VPagination: [1],
    mid_mypage: window.location.pathname,
  }),
  created() {
  },
  mounted() {
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
    }
  },
  methods:{

  }
}
</script>
