<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <div id="my_page" >
        <div class="member-search">
            <article class="myo_search_area">
                <form name='form_search'  v-on:submit.prevent="submitForm"  style="clear:both;" method="post">

                    <div style="position: relative">
                        <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date" @set_date_type="set_date_type" :date_type="date_type"  :is_view = "true" :title_hide = "false" :is_regtitle="false"></dateForm>
                    </div>
                    <section class="flex_box_quad">
                        <memberForm  :selectid="selectid"></memberForm>
                        <divisionForm></divisionForm>
                    </section>

                    <div class="myo_condition_search">
                        <button type="button" onclick="location.replace('/MyMemberRank')" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                        <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
                    </div>
                </form>
            </article>

            <section class="accordion_notice_box">
                <div class="accordion_notice_item">
                    <a class="accordion_notice_tit" href="#n">
                        <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                        {{ i18n.myoffice.myoSearchForm.notice }}
                        <div class="bmenu x7">
                            <span class="btop">
                                <i class="fas fa-chevron-up" aria-hidden="true"></i>
                            </span>
                        </div>
                    </a>
                    <div class="accordion_notice_cont">
                        <div class="myo_search_notice">
                            <p>{{ i18n.myoffice.MemberRank.Notice2 }}</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>

            <div id="rank_page">
                <table id="rank_list" class="list_members relative overflow-x-auto shadow-md sm:rounded-lg" cellspacing="0" width="100%">
                    <thead>
                    <tr class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
                        <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.grade }}</th>
                        <th class="px-6 py-3 whitespace-nowrap" v-show="reg_type == 'P'">{{ i18n.myoffice.leftline }}</th>
                        <th class="px-6 py-3 whitespace-nowrap" v-show="reg_type == 'P'">{{ i18n.myoffice.rightline }}</th>
                        <th class="px-6 py-3 whitespace-nowrap" v-show="reg_type != 'P'">{{ i18n.myoffice.bottomline }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="text-sm bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-for="(Value,index) in list_a">
                        <td class="w-1 px-2 py-2 text-center">{{ Value.rankname }}</td>
                        <td class="w-0.5 px-3 py-2 whitespace-nowrap text-center" :class="{'sel_rank' : Value.rankcd != 'TOT'}" :id="Value.rankcd+'1'" v-show="reg_type == 'P'" v-on:click="searchList(Value.rankcd , Value.stduser ,1)">{{ Value.rankmemcnt }}</td>
                        <td class="w-0.5 px-3 py-2 whitespace-nowrap text-center" :class="{'sel_rank' : Value.rankcd != 'TOT'}"  :id="Value.rankcd+'2'" v-show="reg_type == 'P'" v-on:click="searchList(Value.rankcd , Value.stduser2 ,2)">{{ Value.rankmemcnt2 }}</td>
                        <td class="w-0.5 px-3 py-2 whitespace-nowrap text-center" :class="{'sel_rank' : Value.rankcd != 'TOT'}"  :id="Value.rankcd+'3'" v-show="reg_type != 'P'" v-on:click="searchList(Value.rankcd , Value.stduser ,3)">{{ Value.rankmemcnt }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-6 py-3 whitespace-nowrap"></td>
                        <td class="px-6 py-3 whitespace-nowrap" v-show="reg_type == 'P'" ></td>
                        <td class="px-6 py-3 whitespace-nowrap" v-show="reg_type == 'P'" ></td>
                        <td class="px-6 py-3 whitespace-nowrap" v-show="reg_type != 'P'" ></td>
                    </tr>
                    </tfoot>
                </table>
            </div>

            <div id="list_page" class="relative overflow-x-auto sm:rounded-lg">
                <div class="summary position_relate_box">
                    <strong>Total</strong> <em>{{ total_count }}</em>
                    <abbr title="{{ i18n.myoffice.myofficeAlert.CanTablescroll2 }}" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
                </div><!-- Total -->

                <table id="member_list" class="relative overflow-x-auto shadow-md sm:rounded-lg w-full" >
                    <thead>
                        <tr class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
                            <th class="px-6 py-3 whitespace-nowrap">NO</th>
                            <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.MemberRank.Favorite }}</th><!-- 즐겨찾기 -->
                            <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.MemberId }}</th>
                            <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.memberName }}</th>
                            <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Campus }}</th>
                            <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Rank }}</th>
                            <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.UpdateDate }}</th>
                            <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.PlacementSponsor }}</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-for="( Value,index ) in list"  v-show="this.loading==false">
                            <td class="px-6 py-3 whitespace-nowrap">{{ total_count - ((page-1)*10 + index) }}</td>
                            <td class="px-6 py-3 whitespace-nowrap">
                                <span class='m_favorites' title='Member Favorites' v-show="Value.fav_cnt != 0" v-on:click="favorChk(index,Value.fav_cnt,Value.userid)" ><i class="fas fa-star fav_on" aria-hidden="true"></i></span>
                                <span class='m_favorites' title='Member Favorites' v-show="Value.fav_cnt == 0" v-on:click="favorChk(index,Value.fav_cnt,Value.userid)"><i class="far fa-star fav_off" aria-hidden="true"></i></span>
                            </td>
                            <td class="px-6 py-3 whitespace-nowrap user_menu" :data-enc_id="Value.enc_id" :data-userid="Value.userid">{{ Value.userid }} <i class="fal fa-info-circle"></i></td>
                            <td class="px-6 py-3 whitespace-nowrap" >{{ Value.username }} </td>
                            <td class="px-6 py-3 whitespace-nowrap" >{{ Value.cnt_name }} </td>
                            <td class="px-6 py-3 whitespace-nowrap" >{{ Value.rank_name }} </td>
                            <td class="px-6 py-3 whitespace-nowrap" >{{ Value.rank_date }} </td>
                            <td class="px-6 py-3 whitespace-nowrap user_menu" :data-enc_id="Value.enc_pid"  :data-userid="Value.p_id">{{ Value.p_names }} <i class="fal fa-info-circle"></i></td>
                        </tr>
                        <tr class="h-2" v-if="total_count == 0">
                            <td class="text-left md:text-center pl-5 py-5" colspan="13">No Data</td>
                        </tr>

                    </tbody>
                </table>
                <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
                    <v-pagination
                        v-model="page"
                        :pages=total_page
                        :range-size="1"
                        active-color="#DCEDFF">
                    </v-pagination>
                </div>
            </div>
        </div>
</template>

<script>
import selectDateForm from '../../searchForm/selectDateForm.vue'
import dateForm from '../../searchForm/dateForm.vue'
import memberForm from '../../searchForm/memberForm.vue'
import divisionForm from '../../searchForm/divisionForm.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from "vue3-loading-overlay";
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyMemberRank",
    components : {
        dateForm,
        divisionForm,
        memberForm,
        VPagination,
        selectDateForm,
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        nodes : [],
        legMember : [],
        search_member_div : false,
        loading : false,
        search_tag : "",
        node_id : "",
        chart : "",
        s_date : "",
        e_date : "",
        reg_type : "P",
        list_a : Array,
        list_a_cnt : 0,
        list_b : Array,
        list_b_cnt : 0,
        list : Array,
        total_count : 0,
        page : 1,
        date_type : "register",
        rank : "",
    }),
    props:['search_id','sid_name','selectid'],
    watch : {
        page: function(){
            this.searchList(this.rank ,this.stduser,0);
        }
    },
    mounted : function (){
       this.submitForm();
    },
    methods : {
        set_s_date(s_date){
            this.s_date = s_date;
        },
        set_e_date(e_date){
            this.e_date = e_date;
        },
        set_date_type(date_type){
            this.date_type = date_type;
        },
        submitForm : function (event){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/Rank',{
                s_date : this.s_date,
                e_date : this.e_date,
                sid : search_id.value,
                date_type : this.date_type,
                page : this.page,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {s
                this.list_a = response.data.list_a;
                this.list_a_cnt = response.data.list_a.length;
                this.list_b = response.data.list_b;
                this.list_b_cnt = response.data.list_b.length;
                this.loading = false;
                if(this.list_a_cnt == 0 && this.list_b_cnt == 0){
                    VueSimpleAlert.alert("There are no search results","","",{});
                    this.sid_name = "";
                }
            });

        },
        searchList(rank,stduser,index){
            if(index != 0){
              this.page = 1;
            }
            this.rank = rank;
            this.stduser = stduser;

            if(rank == "TOT"){
                return;
            }
            $(".whitespace-nowrap").removeClass("bg-orange-400");
            $(".whitespace-nowrap").removeClass("text-white");

            $("#"+rank+index).addClass("bg-orange-400");
            $("#"+rank+index).addClass("text-white");

            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/RankList',{
                s_date : this.s_date,
                e_date : this.e_date,
                sid : search_id.value,
                uid : stduser,
                date_type : this.date_type,
                rank : rank,
                page : this.page,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.list = response.data.list.data;
                this.total_count = response.data.list.total;
                this.total_page = parseInt(this.total_count/10)+parseInt(this.total_count%10 > 0 ? 1 : 0);
                this.loading = false;
                if(this.total_count == 0){
                    VueSimpleAlert.alert("There are no search results","","",{});
                    this.sid_name = "";
                }
            });

        },
        favorChk(index,fav_cnt,userid){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/setMemberFavorites',{
                fid : userid,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data.ret == 1){
                    if(fav_cnt == 0){
                        this.list[index].fav_cnt = 1;
                    }
                    if(fav_cnt == 1){
                        this.list[index].fav_cnt = 0;
                    }

                }
                this.loading = false;
                VueSimpleAlert.alert(response.data.msg,"","",{allowOutsideClick : false});

            });
        },

    }
}
</script>

<style >
.summary{margin-bottom:5px;}
.p_alert{color:white; background-color:#272c33; font-size:15px; padding:8px; margin-bottom:5px;    padding: 12px;
  background: #fbfbff;
  -webkit-box-shadow: 0px 1px 2px 2px #e8e9ed;
  box-shadow: 0px 1px 2px 2px #e8e9ed;
  border-radius: 8px;
  color:#283056;
  font-size: 13px;
  padding-right: 17px;
}
.p_alert:before {
  content: "※";
  margin-right: 2px;
  color: #e91e63;
  font-size: 15px;
  margin-left: 0;
}
.fal.fa-search {font-size: 16px; padding:3px; border:1px solid #dadada;}

.alert_div{font-size:15px; background-color: #272c33; padding: 8px; margin-bottom: 5px; color:#fff;}
.alert_div_mobile{
    display:none;}

@media screen and (max-width:699px){
    .alert_div{display:none;}
    .alert_div_mobile{display:block; font-size:13px; margin-bottom:5px; background-color: #272c33; padding: 8px; margin-bottom: 5px; color:#fff;}
}
.delete_member {color:red}
#reg_url { width: 100%; border: none; height: 30px;}
#my_page:after { content: ""; display: block; clear: both;}
#rank_page { width: 280px; float:left; }
#list_page { width:calc(100% - 290px); float:right;}
.sel_rank { cursor: pointer;}
.sel_rank.on { background: yellow;}
@media all and (max-width:700px){
    #rank_page { width: 100%; float:none; padding-top: 0px;}
    #list_page { width: 100%; float:none; margin-top: 30px;}
}
</style>
