<template>
    <div class="main-notice">
        <a v-bind:href="notice_no">
            <span>NOTICE</span>
            {{ board_title }}
        </a>
    </div>

</template>

<script>
export default {
    name: "NoticeTitle",
    data: () => ({
        title : "",
        notice_no : "",
        board_title : ""
    }),
    beforeCreate() {
        axios.post(window.Laravel.back_url+'/api/noticeTopTitle',{
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            //console.log(response.data.no);
            this.notice_no = response.data.url;
            this.board_title = response.data.title;
        });
    }
}
</script>

<style scoped>

</style>
