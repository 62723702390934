<template>
    <loading :active="false"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <div class="member-search">
        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <section class="myo_condition_box relative z-30">
                <dl class="myo_search_list">
                    <dt>{{ i18n.myoffice.Commission.DateofBalance }}</dt> <!-- 마감일자 -->
                    <dd class="flex_box z-30">
                            <!-- 마감일자 -->
                        <vSelect :options="years" label="value" :reduce="value => value.value"  :clearable="false" v-model="s_year"  ></vSelect>
                        <vSelect :options="months" label="value" :reduce="value => value.title" :clearable="false" v-model="s_month"  ></vSelect>
                        <vSelect :options="days" label="value" :clearable="false" v-model="s_day"  ></vSelect>
                    </dd>
                </dl>
            </section><!-- // myo_condition_box -->

            <div class="myo_condition_search2">
                <button type="reset" class="reset_btn" onclick="location.href='/MyBenefitDetailHistory';">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" class="search_btn" v-on:click="submit">{{ i18n.myoffice.Search }}</button>
            </div>
        </form>
    </div>
    <div class="summary position_relate_box">
        <strong>Total</strong> <em>{{ total_count }} </em>
        <abbr title="{{ i18n.myoffice.myofficeAlert.CanTablescroll2 }}" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
    </div><!-- 수당내역조회는 마감일 기준입니다. -->

    <div id="week" style="margin-top:30px;" >
        <b>
        </b>
    </div>
    <h2 style="margin: 0 0 10px 0; position: relative;">
        <em style="float:left;" class="dash_color">{{ i18n.myoffice.dashboard.DashBoardTitle }}</em>
        <!-- (단위 : BV) --><!-- 수당상세내역은 마감일 기준입니다. -->
        <span style='font-size: 14px;'>{{ i18n.myoffice.BVforPoint }}</span>
    </h2>
    <div class="position_relate_box">
        <span class="table_notice_left">{{ i18n.myoffice.myofficeAlert.bonusListViewAlert3 }}</span>
        <abbr title="{{ i18n.myoffice.myofficeAlert.CanTablescroll2')" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
    </div>
    <div id="close_info1" style="overflow-x: scroll">
        <div id="close_info">
            <table border="0" class="dash_table" summary="마감실적" width="100%" style="overflow-x: scroll; word-break: break-word;">
                <colgroup>
                    <col width="*" />
                    <col width="11%" />
                    <col width="22%" />
                    <col width="11%" />
                    <col width="22%" />
                </colgroup>
                <thead>
                <tr>
                    <th><span>{{ i18n.myoffice.dashboard.SalesInformation }}</span></th><!-- 매출정보 -->
                    <th colspan="2"><span>{{ i18n.myoffice.dashboard.Line }} A</span></th>
                    <th colspan="2"><span>{{ i18n.myoffice.dashboard.Line }} B</span></th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <th rowspan="4">{{ i18n.myoffice.dashboard.NewlyOccurring }}</th>
                    <th style="border-left:1px solid #e9e9e9;">{{ i18n.myoffice.dashboard.Local }}</th>
                    <td class="right">{{ dashData.apvl }}</td>
                    <th>{{ i18n.myoffice.dashboard.Local }}</th>
                    <td class="right">{{ dashData.bpvl }}</td>
                </tr>
                <tr>
                    <th style="border-left:1px solid #e9e9e9;">{{ i18n.myoffice.dashboard.Global }}</th>
                    <td class="right">{{dashData.apvg}}</td>
                    <th>{{ i18n.myoffice.dashboard.Global }}</th>
                    <td class="right">{{dashData.bpvg}}</td>
                </tr>
                <tr>
                    <th style="border-left:1px solid #e9e9e9;">{{ i18n.myoffice.total }}</th>
                    <td class="right">{{dashData.apv}}</td>
                    <th>{{ i18n.myoffice.total }}</th>
                    <td class="right">{{dashData.bpv}}</td>
                </tr>
                <tr>
                    <th style="border-left:1px solid #e9e9e9;">{{ i18n.myoffice.jsALert.Consumer }}
                        <Popper hover arrow>
                            <a href="#tooltip-01" class="tooltip"  ><i class="fal fa-question-circle" aria-hidden="true"></i></a>
                            <template #content>
                                <div>
                                    {{ i18n.myoffice.dashboard.DashBoardTooltip3 }}
                                </div>
                            </template>
                        </Popper>
                    </th>
                    <td class="right">{{ dashData.a_sobi }}</td>
                    <th>{{ i18n.myoffice.jsALert.Consumer }}
                        <Popper hover arrow>
                            <a href="#tooltip-01" class="tooltip"  ><i class="fal fa-question-circle" aria-hidden="true"></i></a>
                            <template #content>
                                <div>
                                    {{ i18n.myoffice.dashboard.DashBoardTooltip3 }}
                                </div>
                            </template>
                        </Popper>
                    </th>
                    <td class="right">{{ dashData.b_sobi }}</td>
                </tr>

                <tr>
                    <th>{{ i18n.myoffice.dashboard.CurrentMonths }}
                        <Popper hover arrow>
                            <a href="#tooltip-01" class="tooltip"  ><i class="fal fa-question-circle" aria-hidden="true"></i></a>
                            <template #content>
                                <div>
                                    {{ i18n.myoffice.dashboard.DashBoardTooltip1 }}
                                </div>
                            </template>
                        </Popper>
                    </th>
                    <td colspan="2" class="right">{{dashData.apvbe}}</td>
                    <td colspan="2" class="right">{{dashData.bpvbe}}</td>
                </tr>
                <tr>
                    <th>{{ i18n.myoffice.dashboard.CombinedTotal }}</th><!-- Combined Total BV -->
                    <td colspan="2" class="right">{{dashData.apvcalc}}</td>
                    <td colspan="2" class="right">{{dashData.bpvcalc}}</td>
                </tr>
                <tr>
                    <th style="">{{ i18n.myoffice.dashboard.ThisWeekBonusMatchBV }}</th><!-- 이번 주 후원 수당 매칭 BV -->
                    <td colspan="4" class="right"><span id="aPvGroup"></span>{{ formatPrice(dashData.amt1_min_pv,1) }}</td>
                </tr>
                <tr>
                    <th style="">{{ i18n.myoffice.dashboard.NextWeekCurrentMonths }}</th><!-- 다음주 이월 실적 -->
                    <td colspan="2" class="right"><span id="txt01_week_new_next_bv_A"></span>{{ formatPrice(dashData.a_pre_week,1) }} </td>
                    <td colspan="2" class="right"><span id="txt01_week_new_next_bv_B"></span>{{ formatPrice(dashData.b_pre_week,1) }} </td>
                </tr>
                <tr>
                    <th>{{ i18n.myoffice.dashboard.CombinedBVoftheLast4Weeks }}</th>
                    <td colspan="2" class="right">{{dashData.apvrank}}</td>
                    <td colspan="2" class="right">{{dashData.bpvrank}}</td>
                </tr>
                <tr>
                    <th>{{ i18n.myoffice.dashboard.ResidualPromotionalBV }}</th>
                    <td colspan="2" class="right">{{dashData.apvup}}</td>
                    <td colspan="2" class="right">{{dashData.bpvup}}</td>
                </tr>
<!--                <tr>-->
<!--                    <th style="font-size: 13px;">Promotion Bonus Qualifications&lt;!&ndash; Residual Promotional BV &ndash;&gt;</th>-->
<!--                    <td colspan="4" class="right">Must be a Star</td>-->
<!--                </tr>-->
                </tbody>
            </table>
        </div>
    </div>
    <div id="tooltip-01" class="tooltip-content">
        <span>{{ i18n.myoffice.dashboard.DashBoardTooltip1 }}</span>
        <span class="tooltip-close"><i class="fas fa-times" aria-hidden="true"></i></span>
    </div>
    <div id="tooltip-02" class="tooltip-content">
        <span>{{ i18n.myoffice.dashboard.DashBoardTooltip3 }}</span>
        <span class="tooltip-close"><i class="fas fa-times" aria-hidden="true"></i></span>
    </div>
    <div>
        <div style="height:30px;"></div>
    </div>

    <div id="search_result" class="list-table-wrap">
        <div class="list-table-wrap">
            <table class="list-table">
                <thead>
                <tr>
                    <th>{{ i18n.myoffice.Commission.DateofBalance }}</th>
                    <th>{{ i18n.myoffice.Commission.Position }}</th>
                    <th class="px-6 py-3 whitespace-nowrap" v-for="Value in name_list">{{ Value.pay_name }}</th>
                    <th>{{ i18n.myoffice.Commission.TotalAmount }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(Value,index) in List" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.paydate }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.ranknewname }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt1) }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt2) }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt4) }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt5) }}</th>
                    <th class="px-6 py-3 whitespace-nowrap" v-show="cnt_top_chk != 0">{{ formatPrice(Value.amt6) }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt7) }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.totalamt) }}</th>
                </tr>
                <tr v-for="(Value,index) in name_date" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th class="px-6 py-3 whitespace-nowrap" colspan="2">{{ i18n.myoffice.Commission.Paymentday }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.amt1 }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.amt2 }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.amt4 }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.amt5 }}</th>
                    <th class="px-6 py-3 whitespace-nowrap" v-show="cnt_top_chk != 0">{{ Value.amt6 }}</th>
                    <th class="px-6 py-3 whitespace-nowrap">{{ Value.amt7 }}</th>
                    <th class="px-6 py-3 whitespace-nowrap"></th>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-if="list1_total_count">
        <h2 style="margin: 0 0 10px 0; position: relative;">
            <em style="float:left;" class="dash_color">{{ name_list1.pay_name  }}</em>
            <span style='font-size: 14px;'>(Unit : {{ i18n.myoffice.Commission.OrderBV }}))</span>
        </h2>
        <div class="benefit_notice_detail"><span>{{ i18n.myoffice.Commission.USDmultiply1000 }}</span></div><!-- 발생금액은 USD기준이므로 발생금액에서 *1,000원을 하시면 됩니다. -->
        <div class="list-table-wrap">
            <table id="orders-list-{{ name_list1.name_cd }}" class=" list-table"  cellspacing="0" width="100%">
                <thead>
                <tr class="tr-margin">
                    <th>No</th>
                    <th>{{ i18n.myoffice.MemberId }}</th>
                    <th>{{ i18n.myoffice.Commission.Name }}</th>
                    <th>{{ name_list1.detail_kind=='P' ? i18n.myoffice.Commission.Position : i18n.myoffice.Commission.OrderNumber }}</th><!-- 직급 -->
                    <th>{{ name_list1.detail_kind=='P' ? i18n.myoffice.Campus : i18n.myoffice.Commission.OrderClassification }}</th><!-- 캠퍼스 -->
                    <th>{{ name_list1.detail_kind=='P' ? i18n.myoffice.Commission.Bonusfinance : (name_list1.name_cd == '3' ? i18n.myoffice.Commission.OrderSV : i18n.myoffice.Commission.OrderBV) }}</th><!-- 수당재원 -->
                    <th>{{ i18n.myoffice.Commission.PaymentRate }}</th>
                    <th>{{ i18n.myoffice.Commission.Step }}</th>
                    <th>{{ i18n.myoffice.Commission.GeneratedAmount }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="Value in list1">
                    <td class="ltit text-center">{{ Value.rn }}</td>
                    <td class="ltit text-center">{{ Value.userid}}</td>
                    <td class="ltit text-center">{{ Value.username }}</td>
                    <td class="ltit text-center">{{ Value.ord_no }}</td>
                    <td class="ltit text-center">{{ Value.ord_kinds }}</td>
                    <td class="ltit text-right">{{ Value.ord_pv }}</td>
                    <td class="ltit text-center">{{ Value.tg_rate }}</td>
                    <td class="ltit text-center">{{ Value.tg_lv }}</td>
                    <td class="ltit text-right">{{ Value.tg_amt }}</td>
                </tr>
                </tbody>
            </table>

            <v-pagination
                v-model="list1_page"
                :pages=list1_total_page
                :range-size="1"
                active-color="#DCEDFF">
            </v-pagination>
        </div>
    </div>

    <div v-if="list2_total_count">
        <h2 style="margin: 0 0 10px 0; position: relative;">
            <em style="float:left;" class="dash_color">{{ name_list2.pay_name  }}</em>
            <span style='font-size: 14px;'>(Unit : {{ i18n.myoffice.Commission.OrderBV }}))</span>
        </h2>
        <div class="benefit_notice_detail"><span>{{ i18n.myoffice.Commission.USDmultiply1000 }}</span></div><!-- 발생금액은 USD기준이므로 발생금액에서 *1,000원을 하시면 됩니다. -->
        <div class="list-table-wrap">
            <table id="orders-list-{{ name_list2.name_cd }}" class=" list-table"  cellspacing="0" width="100%">
                <thead>
                <tr class="tr-margin">
                    <th>No</th>
                    <th>{{ i18n.myoffice.MemberId }}</th>
                    <th>{{ i18n.myoffice.Commission.Name }}</th>
                    <th>{{ name_list2.detail_kind=='P' ? i18n.myoffice.Commission.Position : i18n.myoffice.Commission.OrderNumber }}</th><!-- 직급 -->
                    <th>{{ name_list2.detail_kind=='P' ? i18n.myoffice.Campus : i18n.myoffice.Commission.OrderClassification }}</th><!-- 캠퍼스 -->
                    <th>{{ name_list2.detail_kind=='P' ? i18n.myoffice.Commission.Bonusfinance : (name_list2.name_cd == '3' ? i18n.myoffice.Commission.OrderSV : i18n.myoffice.Commission.OrderBV) }}</th><!-- 수당재원 -->
                    <th>{{ i18n.myoffice.Commission.PaymentRate }}</th>
                    <th>{{ i18n.myoffice.Commission.Step }}</th>
                    <th>{{ i18n.myoffice.Commission.GeneratedAmount }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="Value in list2">
                    <td class="ltit text-center">{{ Value.rn }}</td>
                    <td class="ltit text-center">{{ Value.userid}}</td>
                    <td class="ltit text-center">{{ Value.username }}</td>
                    <td class="ltit text-center">{{ Value.ord_no }}</td>
                    <td class="ltit text-center">{{ Value.ord_kinds }}</td>
                    <td class="ltit text-right">{{ Value.ord_pv }}</td>
                    <td class="ltit text-center">{{ Value.tg_rate }}</td>
                    <td class="ltit text-center">{{ Value.tg_lv }}</td>
                    <td class="ltit text-right">{{ Value.tg_amt }}</td>
                </tr>
                </tbody>
            </table>

            <v-pagination
                v-model="list1_page"
                :pages=list1_total_page
                :range-size="1"
                active-color="#DCEDFF">
            </v-pagination>
        </div>
    </div>
    <div v-if="list3_total_count">
        <h2 style="margin: 0 0 10px 0; position: relative;">
            <em style="float:left;" class="dash_color">{{ name_list3.pay_name  }}</em>
            <span style='font-size: 14px;'>(Unit : {{ i18n.myoffice.Commission.OrderBV }}))</span>
        </h2>
        <div class="benefit_n otice_detail"><span>{{ i18n.myoffice.Commission.USDmultiply1000 }}</span></div><!-- 발생금액은 USD기준이므로 발생금액에서 *1,000원을 하시면 됩니다. -->
        <div class="list-table-wrap">
            <table id="orders-list-{{ name_list3.name_cd }}" class=" list-table"  cellspacing="0" width="100%">
                <thead>
                <tr class="tr-margin">
                    <th>No</th>
                    <th>{{ i18n.myoffice.MemberId }}</th>
                    <th>{{ i18n.myoffice.Commission.Name }}</th>
                    <th>{{ name_list3.detail_kind=='P' ? i18n.myoffice.Commission.Position : i18n.myoffice.Commission.OrderNumber }}</th><!-- 직급 -->
                    <th>{{ name_list3.detail_kind=='P' ? i18n.myoffice.Campus : i18n.myoffice.Commission.OrderClassification }}</th><!-- 캠퍼스 -->
                    <th>{{ name_list3.detail_kind=='P' ? i18n.myoffice.Commission.Bonusfinance : (name_list3.name_cd == '3' ? i18n.myoffice.Commission.OrderSV : i18n.myoffice.Commission.OrderBV) }}</th><!-- 수당재원 -->
                    <th>{{ i18n.myoffice.Commission.PaymentRate }}</th>
                    <th>{{ i18n.myoffice.Commission.Step }}</th>
                    <th>{{ i18n.myoffice.Commission.GeneratedAmount }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="Value in list3">
                    <td class="ltit text-center">{{ Value.rn }}</td>
                    <td class="ltit text-center">{{ Value.userid}}</td>
                    <td class="ltit text-center">{{ Value.username }}</td>
                    <td class="ltit text-center">{{ Value.ord_no }}</td>
                    <td class="ltit text-center">{{ Value.ord_kinds }}</td>
                    <td class="ltit text-right">{{ Value.ord_pv }}</td>
                    <td class="ltit text-center">{{ Value.tg_rate }}</td>
                    <td class="ltit text-center">{{ Value.tg_lv }}</td>
                    <td class="ltit text-right">{{ Value.tg_amt }}</td>
                </tr>
                </tbody>
            </table>

            <v-pagination
                v-model="list3_page"
                :pages=list3_total_page
                :range-size="1"
                active-color="#DCEDFF">
            </v-pagination>
        </div>
    </div>
    <div v-if="list4_total_count">
        <h2 style="margin: 0 0 10px 0; position: relative;">
            <em style="float:left;" class="dash_color">{{ name_list4.pay_name  }}</em>
            <span style='font-size: 14px;'>(Unit : {{ i18n.myoffice.Commission.OrderBV }}))</span>
        </h2>
        <div class="benefit_notice_detail"><span>{{ i18n.myoffice.Commission.USDmultiply1000 }}</span></div><!-- 발생금액은 USD기준이므로 발생금액에서 *1,000원을 하시면 됩니다. -->
        <div class="list-table-wrap">
            <table id="orders-list-{{ name_list4.name_cd }}" class=" list-table"  cellspacing="0" width="100%">
                <thead>
                <tr class="tr-margin">
                    <th>No</th>
                    <th>{{ i18n.myoffice.MemberId }}</th>
                    <th>{{ i18n.myoffice.Commission.Name }}</th>
                    <th>{{ name_list4.detail_kind=='P' ? i18n.myoffice.Commission.Position : i18n.myoffice.Commission.OrderNumber }}</th><!-- 직급 -->
                    <th>{{ name_list4.detail_kind=='P' ? i18n.myoffice.Campus : i18n.myoffice.Commission.OrderClassification }}</th><!-- 캠퍼스 -->
                    <th>{{ name_list4.detail_kind=='P' ? i18n.myoffice.Commission.Bonusfinance : (name_list4.name_cd == '3' ? i18n.myoffice.Commission.OrderSV : i18n.myoffice.Commission.OrderBV) }}</th><!-- 수당재원 -->
                    <th>{{ i18n.myoffice.Commission.PaymentRate }}</th>
                    <th>{{ i18n.myoffice.Commission.Step }}</th>
                    <th>{{ i18n.myoffice.Commission.GeneratedAmount }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="Value in list4">
                    <td class="ltit text-center">{{ Value.rn }}</td>
                    <td class="ltit text-center">{{ Value.userid}}</td>
                    <td class="ltit text-center">{{ Value.username }}</td>
                    <td class="ltit text-center">{{ Value.ord_no }}</td>
                    <td class="ltit text-center">{{ Value.ord_kinds }}</td>
                    <td class="ltit text-right">{{ Value.ord_pv }}</td>
                    <td class="ltit text-center">{{ Value.tg_rate }}</td>
                    <td class="ltit text-center">{{ Value.tg_lv }}</td>
                    <td class="ltit text-right">{{ Value.tg_amt }}</td>
                </tr>
                </tbody>
            </table>

            <v-pagination
                v-model="list4_page"
                :pages=list4_total_page
                :range-size="1"
                active-color="#DCEDFF">
            </v-pagination>
        </div>
    </div>
    <div v-if="list5_total_count">
        <h2 style="margin: 0 0 10px 0; position: relative;">
            <em style="float:left;" class="dash_color">{{ name_list5.pay_name  }}</em>
            <span style='font-size: 14px;'>(Unit : {{ i18n.myoffice.Commission.OrderBV }}))</span>
        </h2>
        <div class="benefit_notice_detail"><span>{{ i18n.myoffice.Commission.USDmultiply1000 }}</span></div><!-- 발생금액은 USD기준이므로 발생금액에서 *1,000원을 하시면 됩니다. -->
        <div class="list-table-wrap">
            <table id="orders-list-{{ name_list5.name_cd }}" class=" list-table"  cellspacing="0" width="100%">
                <thead>
                <tr class="tr-margin">
                    <th>No</th>
                    <th>{{ i18n.myoffice.MemberId }}</th>
                    <th>{{ i18n.myoffice.Commission.Name }}</th>
                    <th>{{ name_list5.detail_kind=='P' ? i18n.myoffice.Commission.Position : i18n.myoffice.Commission.OrderNumber }}</th><!-- 직급 -->
                    <th>{{ name_list5.detail_kind=='P' ? i18n.myoffice.Campus : i18n.myoffice.Commission.OrderClassification }}</th><!-- 캠퍼스 -->
                    <th>{{ name_list5.detail_kind=='P' ? i18n.myoffice.Commission.Bonusfinance : (name_list5.name_cd == '3' ? i18n.myoffice.Commission.OrderSV : i18n.myoffice.Commission.OrderBV) }}</th><!-- 수당재원 -->
                    <th>{{ i18n.myoffice.Commission.PaymentRate }}</th>
                    <th>{{ i18n.myoffice.Commission.Step }}</th>
                    <th>{{ i18n.myoffice.Commission.GeneratedAmount }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="Value in list5">
                    <td class="ltit text-center">{{ Value.rn }}</td>
                    <td class="ltit text-center">{{ Value.userid}}</td>
                    <td class="ltit text-center">{{ Value.username }}</td>
                    <td class="ltit text-center">{{ Value.ord_no }}</td>
                    <td class="ltit text-center">{{ Value.ord_kinds }}</td>
                    <td class="ltit text-right">{{ Value.ord_pv }}</td>
                    <td class="ltit text-center">{{ Value.tg_rate }}</td>
                    <td class="ltit text-center">{{ Value.tg_lv }}</td>
                    <td class="ltit text-right">{{ Value.tg_amt }}</td>
                </tr>
                </tbody>
            </table>

            <v-pagination
                v-model="list5_page"
                :pages=list5_total_page
                :range-size="1"
                active-color="#DCEDFF">
            </v-pagination>
        </div>
    </div>
</template>
<script>
import vSelect from "vue-select";
import Popper from "vue3-popper";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
    name: "MyBenefitDetailHistory",
    components : {
        vSelect,
        Popper,
        VPagination,
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        title: [],
        products: [],
        page : 1,
        total_page : 1,
        total_count : 1,
        checkCondition : false,
        name_list : [],
        name_date : [],

        List : [],
        s_year : "",
        s_month : "",
        s_day : "",
        dashData : "",
        months : [{title : "01", value : i18n.myoffice.jan},
            {title : "02", value : i18n.myoffice.feb},
            {title : "03", value : i18n.myoffice.mar},
            {title : "04", value : i18n.myoffice.apr},
            {title : "05", value : i18n.myoffice.may},
            {title : "06", value : i18n.myoffice.jun},
            {title : "07", value : i18n.myoffice.jul},
            {title : "08", value : i18n.myoffice.aug},
            {title : "09", value : i18n.myoffice.sep},
            {title : "10", value : i18n.myoffice.oct},
            {title : "11", value : i18n.myoffice.nov},
            {title : "12", value : i18n.myoffice.dec}],
        years : [],
        days : [],
        list1 : [],
        list2 : [],
        list3 : [],
        list4 : [],
        list5 : [],
        name_list1 : [],
        name_list2 : [],
        name_list3 : [],
        name_list4 : [],
        name_list5 : [],
        list1_total_count: 0,
        list1_page : 1,
        list1_total_page : 1,
        list2_total_count: 0,
        list2_page : 1,
        list2_total_page : 1,
        list3_total_count: 0,
        list3_page : 1,
        list3_total_page : 1,
        list4_total_count: 0,
        list4_page : 1,
        list4_total_page : 1,
        list5_total_count: 0,
        list5_page : 1,
        list5_total_page : 1,
        loading : true,
        cnt_top_chk : 0,

    }),
    watch : {
        s_year : function(){
            this.get_days();
        },
        s_month : function(){
            this.get_days();
        }
    },
    created : function (){
        var year = "2015";
        for(year ; year <= new Date().getFullYear() ; year++){
            this.years.push(
                {value: year}
            );
        }
        this.submit();

    },
    methods : {
        get_days(){
            axios.post(window.Laravel.back_url+'/api/getWeekDay',{
                month : this.s_year+""+this.s_month
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response =>{
                this.s_day = response.data.data[0].value;
                this.days = response.data.data;
            });
        },
        get_list(tit, tit_num, page){
            axios.post(window.Laravel.back_url+'/api/getTotalAllowanceOrBunusList',{
                s_year : this.s_year, s_month : this.s_month,s_day : this.s_day.code,tit: tit, number: tit_num, page : page,
                page_size : 20,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response =>{
                if(tit == "list1"){
                    this.list1 = response.data.list.data;
                    this.name_list1 = response.data.name_list;
                    this.list1_total_count = response.data.list_total;
                    this.list1_total_page = parseInt(this.list1_total_count/20)+parseInt(this.list1_total_count%20 > 0 ? 1 : 0);
                }
                if(tit == "list2"){
                    this.list2 = response.data.list.data;
                    this.name_list2 = response.data.name_list;
                    this.list2_total_count = response.data.list_total;
                    this.list2_total_page = parseInt(this.list2_total_count/20)+parseInt(this.list2_total_count%20 > 0 ? 1 : 0);
                }
                if(tit == "list3"){
                    console.log(response.data.list);
                    this.list3 = response.data.list.data;
                    this.name_list3 = response.data.name_list;
                    this.list3_total_count = response.data.list_total;
                    this.list3_total_page = parseInt(this.list3_total_count/20)+parseInt(this.list3_total_count%20 > 0 ? 1 : 0);
                }
                if(tit == "list4"){
                    this.list4 = response.data.list.data;
                    this.name_list4 = response.data.name_list;
                    this.list4_total_count = response.data.list_total;
                    this.list4_total_page = parseInt(this.list4_total_count/20)+parseInt(this.list4_total_count%20 > 0 ? 1 : 0);
                }
                if(tit == "list5"){
                    this.list5 = response.data.list.data;
                    this.name_list5 = response.data.name_list;
                    this.list5_total_count = response.data.list_total;
                    this.list5_total_page = parseInt(this.list5_total_count/20)+parseInt(this.list5_total_count%20 > 0 ? 1 : 0);
                }
            });
        },
        tooltip(){
            var $this = this;
            var $href = $this.$attrs.href, $top = $this.$attrs.top + 22, $left = $this.$attrs.left ;

            var config = {
                tooltipstyle : {
                    position : 'absolute',
                    top : $top,
                    left : $left,
                    zIndex: 50
                }};

            $($href).css(config.tooltipstyle);
            (this.$type == 'mouseenter') ? $($href).show() : $($href).hide();
            return false;

        },
        formatPrice(value,digit) {
            // 코드 개선 add by hskim :: 2023-07-21 오후 2:46
            value = isNaN(value) ? 0 : value;
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        submit(){
            axios.post(window.Laravel.back_url+'/api/getTotalAllowanceOrBunus',{
                s_year : this.s_year, s_month : this.s_month, s_day : this.s_day.code
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.name_list = response.data.name_list;
                this.List = response.data.name_data;
                this.name_date = response.data.name_date;
                this.s_year = response.data.s_year;
                this.s_month = response.data.s_month;
                this.s_day = response.data.s_day;
                this.dashData = response.data.dashData;
                this.cnt_top_chk = response.data.cnt_top_chk;
                this.get_list("list1",1,1);
                this.get_list("list2",2,1);
                //this.get_list("list3",3,1);
                this.get_list("list4",4,1);
                this.get_list("list5",5,1);

            });

        }
    }

}
</script>

<style scoped>
:deep(.popper) {
    max-width: 600px;
    word-break: break-all;
    white-space: pre-line;

}
</style>

<style >
.v-select{ min-width: 100px; z-index:0 }
/*배솧비 툴팁*/
.tooltip{display: inline-block;}
.tooltip i{color:#0abab5; font-weight:400; vertical-align: baseline}
.tooltip-content{ display: none; text-align: left; font-size: 12px; background-color: #fff; padding:10px; border-radius:10px; font-size:1em; box-shadow:5px 5px 5px #888; background:#0abab5; color:#fff;}
/* 툴팁 삭제버튼 추가 */
.tooltip-content:after{display:inline-block; content:' '; width:20px;}
.tooltip-close{position:absolute; top:7px; right:12px;}
:root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 32px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}


</style>
