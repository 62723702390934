<template>
    <div id="wrap">
        <input type="hidden" id="a_userid">
        <input type="hidden" id="b_userid">
        <div id="content_myoffice" class="clr">
            <div id="content_dashboard">
                <div id="week" style="margin-top:30px;" >
                    <span v-on:click="dashBoardPage(uid, week_cnt2 , 'prv',userid)" style="cursor:pointer;">◀ </span>
                    <b>
                        {{ week_info_title }}
                    </b>
                    <span v-on:click="dashBoardPage(uid, week_cnt2 , 'next',userid)" style="cursor:pointer;"> ▶</span>
                </div>
                <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
                  <table id="order_list" class="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
                    <thead>
                    <tr class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
                      <th class="px-6 py-3 whitespace-nowrap">No</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Country}}</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.MemberId}}</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.memberName}}</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Rank}}</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Left}}</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Right}}</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Commission.paymentAmount }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(Value,index) in infoList" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap text-center">{{ index + 1 }}</td>
                      <th class="px-6 py-3 whitespace-nowrap">{{ Value.bz_cd }}</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ Value.userid }}</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ Value.username }}</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ Value.rank_name }}</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.a_pv,2) }}</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.b_pv,2) }}</th>
                      <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt7,2) }}</th>
                    </tr>
                    <tr class="h-2" v-if="total_count == 0">
                      <td class="text-left md:text-center pl-5 py-5" colspan="13">{{ this.list_msg }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
    </div><!-- //wrap -->
</template>

<script>
import vSelect from "vue-select";
import VueSimpleAlert from "vue3-simple-alert";
import 'floating-vue/dist/style.css';
import { Tooltip } from "floating-vue";

export default {

    name: "MyofficeDashBoard",
    components: {
        vSelect,
        VTooltip : Tooltip
    },
    data: () => ({
        i18n: window.i18n,
        txt01_userid : "",
        txt01_username : "",
        txt01_grade : "",
        txt01_acquire_rank : "",
        txt01_uji_yn : "",
        txt01_uji_yn2 : "",
        txt01_end_of_uji : "",
        txtUjiEbuy : "",
        txt01_end_of_Guji : "",
        txtUjiGEbuy : "",
        txt01_autoship_status : "",
        txt01_autoship_admitsday : "",

        txt01_week_new_bv_A_C : "",
        txt01_week_new_bv_B_C : "",
        txt01_week_new_bv_A_CT : "",
        txt01_week_new_bv_B_CT : "",
        txt01_week_new_bv_A_L : "",
        txt01_week_new_bv_B_L : "",
        txt01_week_new_bv_A_G : "",
        txt01_week_new_bv_B_G : "",
        txt01_week_new_bv_A : "",
        txt01_week_new_bv_B : "",

        txt01_foward_month_A : "",
        txt01_foward_month_B : "",
        txt01_total_PV_A : "",
        txt01_total_PV_B : "",
        txt01_latest4week_A : "",
        txt01_latest4week_B : "",
        txt01_rest_rank_bv_A : "",
        txt01_rest_rank_bv_B : "",
        rankMaxName : "",
        pvAcc : "",
        lastOrdDate : "",
        lastOrdPv : "",
        lastRIdOrdDate : "",
        txt01_thisweek_PV : "",
        lastRIdOrdPv : "",
        ordLastSobi : "",
        alinename : "",
        blinename : "",
        atotalsumPv : "",
        alocalsumPv : "",
        aglobalsumPv : "",
        btotalsumPv : "",
        bglobalsumPv : "",
        blocalsumPv : "",
        txt01_gfesta_consumer_A : "",
        txt01_gfesta_consumer_B : "",
        recent4week : "",
        a_userid : "",
        b_userid : "",
        week4Data : [],
        tag2 : "",
        week_info_title : "",
        uid : "",
        week_cnt2: "",
        maxWeekCnt : "",
        maxWeekFirst : 0,
        favoriteList : [],
        userid : login_userid,
        Autoship : true,
        m4_flag : "",

    }),
    beforeCreate() {
        axios.post(window.Laravel.back_url+'/api/getMemberFavor',{
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.getFabourRiteData(response.data.list.data);
        });
    },
    created : function (){
        if(this.selectid == "fail"){
            VueSimpleAlert.alert("잘못된 접근 입니다.","","",{allowOutsideClick : false}).then((result) => {
                if(result){
                    location.replace("/MyofficeMain");
                }
            });
        }
        this.dashBoardPage('','','');
    },
    props : {
        selectid : "",
        no : ""
    },
    watch: {
        userid : function (val){
            this.dashBoardPage('','','');
        },
        selectid : function (val){
            console.log("test");
            this.dashBoardPage('','','');
        }
    },
    methods: {
        getFabourRiteData($list) {
            this.favoriteList.push({
                userid: "",
                c_id: "",
                c_name: "",
                label: "me",
            });

            $list.forEach((value, index) => {
                this.favoriteList.push({
                    userid: value.userid,
                    c_id: value.c_id,
                    c_name: value.c_name,
                    label: value.c_name+"("+value.c_id+")",
                });
            });

        },
        setTitleWeek($list) {
            //data.title = $list.week_info.title.replace("주차",i18n.myoffice.dashboard.Week);
            this.week_info_title = $list.week_info.title.replace("주차",i18n.myoffice.dashboard.Week);
            if(this.maxWeekFirst == "0"){
                this.maxWeekFirst = 1;
                this. maxWeekCnt = $list.week_info.week_cnt2;
            }
            this.week_cnt2 = $list.week_info.week_cnt2;

            //this.uid = $list.week_info.uid;
        },
        formatPrice(value,digit) {
          let val = (value/1).toFixed(digit).replace('.', '.')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        dashBoardPage: function(uid,weekCnt,prv){
            if(prv == "next"){
                if(this.maxWeekCnt > this.week_cnt2){
                    weekCnt++;
                }
            }else if(prv == "prv"){
                weekCnt--;
            }
            axios.post(window.Laravel.back_url+'/api/MyRankPromotion', {
                uid : uid, weekCnt : weekCnt, selectId : this.selectid,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                console.log(response.data);
                this.total_count = response.data.total_count;
                this.infoList = response.data.info_list;
                this.setTitleWeek(response.data);
            });
        }
    }
}
</script>
<style >
.v-popper--theme-tooltip {
   max-width: 400px !important;
}
.vs--single .vs__selected{
  line-height: 1.5;
}
</style>
