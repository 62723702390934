<template>
  <div class="bg-[#c832c8cc] px-4 py-6 " v-show="false"> <!-- promotion -->
    <h4 class="xl:text-3xl lg:text-2xl text-xl font-semibold text-white flex flex-row justify-between items-center">
      7 Core Telomere Sponsoring Program 2024/7
      <button class="" :class="{ togg1: toggleinfo }" @click='toggleinfo = !toggleinfo'>
        <span class="arrow-btn block px-2"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white w-5 h-5" viewBox="0 0 448 512"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/></svg></span>
      </button>
    </h4>
    <div class="xl:mt-6 lg:mt-6 mt-8" v-show="toggleinfo">
      <!-- S : 50BV -->
      <div class="w-full px-1 py-2 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:justify-evenly lg:justify-evenly">
        <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:hidden lg:hidden block">Achievement</p> <!--달성 인원-->
        <div class="flex flex-row xl:gap-2 lg:gap-2 gap-1 xl:w-auto lg:w-auto w-full justify-around">
          <div class="flex gap-1 items-center"><span class="xl:inline-block lg:inline-block text-[#f5d7e4] text-lg text-center">50BV</span></div>
          <div class="flex gap-1 items-center">
            <span class="text-white xl:inline-block lg:inline-block hidden">Left Achievement</span>
            <span class="text-white xl:hidden lg:hidden inline-block ">Left</span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo50.leftpostCnt" readonly />
          </div>
          <span class="flex items-center text-white">&nbsp;&#47;&nbsp;</span>
          <div class="flex gap-1 items-center">
            <span class="text-white xl:inline-block lg:inline-block hidden">Right Achievement</span>
            <span class="text-white xl:hidden lg:hidden inline-block ">Right</span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo50.rightPostCnt" readonly />
          </div>
        </div>
        <div class="p-1 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:w-auto lg:w-auto w-full">
          <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:mt-0 lg:mt- mt-4 xl:text-left lg:text-left text-center">Promotion Achievement Current Status</p> <!-- 프로모션 달성 여부 -->
          <!-- 달성될 경우 달성 노출 - 달성 건수 노출 , 미달성일 경우 미달성 노출 -->
          <div v-if="promo50.achievementYn == 'Y'" class="bg-slate-50 text-blue-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full" ><span>{{ promo50.totalAchievement }} </span>&nbsp;Achieved</div>
          <div v-else class="bg-slate-50 text-red-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full">Not Achieved</div>
        </div>
      </div>
      <!-- E : 50BV -->
      <!-- S : 500BV -->
      <div class="w-full px-1 py-2 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:justify-evenly lg:justify-evenly">
        <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:hidden lg:hidden block">Achievement</p> <!--달성 인원-->
        <div class="flex flex-row xl:gap-2 lg:gap-2 gap-1 xl:w-auto lg:w-auto w-full justify-around">
          <div class="flex gap-1 items-center"><span class="xl:inline-block lg:inline-block text-[#f5d7e4] text-lg text-center">500BV</span></div>
          <div class="flex gap-1 items-center">
            <span class="text-white xl:inline-block lg:inline-block hidden">Left Achievement</span>
            <span class="text-white xl:hidden lg:hidden inline-block ">Left</span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo500.leftpostCnt" readonly />
          </div>
          <span class="flex items-center text-white">&nbsp;&#47;&nbsp;</span>
          <div class="flex gap-1 items-center">
            <span class="text-white xl:inline-block lg:inline-block hidden">Right Achievement</span>
            <span class="text-white xl:hidden lg:hidden inline-block ">Right</span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="promo500.rightPostCnt" readonly />
          </div>
        </div>
        <div class="p-1 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:w-auto lg:w-auto w-full">
          <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:mt-0 lg:mt- mt-4 xl:text-left lg:text-left text-center">Promotion Achievement Current Status</p> <!-- 프로모션 달성 여부 -->
          <!-- 달성될 경우 달성 노출 - 달성 건수 노출 , 미달성일 경우 미달성 노출 -->
          <div v-if="promo500.achievementYn == 'Y'" class="bg-slate-50 text-blue-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full" ><span>{{ promo500.totalAchievement }} </span>&nbsp;Achieved</div>
          <div v-else class="bg-slate-50 text-red-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full">Not Achieved</div>
        </div>
      </div>
      <!-- E : 500BV -->
      <div class="w-full text-gray-50 text-sm border-t border-white border-dashed pt-4">
        The promotion achievement status is updated on a deadline basis.<br>
        If you return the product yourself within the promotion period, you will not be eligible for the promotion.<br>
        If even one return process occurs before the achievement announcement, promotion achievement will be invalidated.
        <!--      프로모션 달성 현황은 마감 시간 기준으로 갱신됩니다.<br>
                  프로모션 기간 내 본인 반품 시 대상에서 제외됩니다.<br>
                  프로모션 마감 후 달성자 발표 전까지 1건이라도 반품이
                  확인 된 경우 프로모션 달성은 무효가 되며 여행 달성자에서
                  제외 됩니다.-->
      </div>
    </div>
  </div>
  <!-- S : INITIATOR -->
  <div class="bg-[#00b0f0] px-4 py-6 " >
    <h4 class="xl:text-3xl lg:text-2xl text-xl font-semibold text-white flex flex-row justify-between items-center">
      INITIATOR Program 2024/9
      <button class="" :class="{ togg1: toggleinfo2 }" @click='toggleinfo2 = !toggleinfo2'>
        <span class="arrow-btn block px-2"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white w-5 h-5" viewBox="0 0 448 512"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/></svg></span>
      </button>
    </h4>
    <div class="xl:mt-6 lg:mt-6 mt-8" v-show="toggleinfo2">
      <!-- S : 50BV -->
      <div class="w-full px-1 py-2 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:justify-evenly lg:justify-evenly">
        <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:hidden lg:hidden block">Achievement</p> <!--달성 인원-->
        <div class="flex flex-row xl:gap-2 lg:gap-2 gap-1 xl:w-auto lg:w-auto w-full justify-around">
          <div class="flex gap-1 items-center"><span class="xl:inline-block lg:inline-block text-[#f5d7e4] text-lg text-center">Direct Enrollment Members</span></div>
          <div class="flex gap-1 items-center">
            <span class="text-white xl:inline-block lg:inline-block hidden">Achievement</span>
            <span class="text-white xl:hidden lg:hidden inline-block "></span><input type="text" class="text-right xl:w-24 lg:w-24 w-10 p-1" v-model="initiator.cnt" readonly />
          </div>
        </div>
        <div class="p-1 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:w-auto lg:w-auto w-full">
          <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:mt-0 lg:mt- mt-4 xl:text-left lg:text-left text-center">Promotion Achievement Current Status</p> <!-- 프로모션 달성 여부 -->
          <!-- 달성될 경우 달성 노출 - 달성 건수 노출 , 미달성일 경우 미달성 노출 -->
          <div v-if="initiator.prom_yn == 'Y'" class="bg-slate-50 text-blue-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full" ><span> </span>&nbsp;Achieved</div>
          <div v-else class="bg-slate-50 text-red-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full">Not Achieved</div>
        </div>
      </div>
      <!-- E : 50BV -->
      <div class="w-full text-gray-50 text-sm border-t border-white border-dashed pt-4">
        The promotion achievement status is updated on a deadline basis.<br>
        If you return the product yourself within the promotion period, you will not be eligible for the promotion.<br>
        If even one return process occurs before the achievement announcement, promotion achievement will be invalidated.
        <!--      프로모션 달성 현황은 마감 시간 기준으로 갱신됩니다.<br>
                  프로모션 기간 내 본인 반품 시 대상에서 제외됩니다.<br>
                  프로모션 마감 후 달성자 발표 전까지 1건이라도 반품이
                  확인 된 경우 프로모션 달성은 무효가 되며 여행 달성자에서
                  제외 됩니다.-->
      </div>
    </div>
  </div>
  <!-- E : INITIATOR -->
  <!-- S : INDONESIA TRIP -->
  <div class="bg-[#edab39] px-4 py-6 " >
    <h4 class="xl:text-3xl lg:text-2xl text-xl font-semibold text-white flex flex-row justify-between items-center">
      INDONESIA TRIP PROMOTION 2024
      <button class="" :class="{ togg1: toggleinfo3 }" @click='toggleinfo3 = !toggleinfo3'>
        <span class="arrow-btn block px-2"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white w-5 h-5" viewBox="0 0 448 512"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/></svg></span>
      </button>
    </h4>
    <div class="xl:mt-6 lg:mt-6 mt-8" v-show="toggleinfo3">
      <!-- S : 50BV -->
      <div class="w-full px-1 py-2 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:justify-evenly lg:justify-evenly">
        <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:hidden lg:hidden block">Achievement</p> <!--달성 인원-->
        <div class="flex flex-row xl:gap-2 lg:gap-2 gap-1 xl:w-auto lg:w-auto w-full justify-around">
          <div class="flex gap-1 items-center"><span class="xl:inline-block lg:inline-block text-[#f5d7e4] text-lg text-center">Performance BV</span></div>
          <div class="flex gap-1 items-center">
            <span class="text-white xl:inline-block lg:inline-block hidden">Achievement</span>
            <span class="text-white xl:hidden lg:hidden inline-block "></span><input type="text" class="text-right xl:w-24 lg:w-24 w-20 p-1" v-model="domestic_trip.sumBv" readonly />
          </div>
        </div>
        <div class="p-1 flex xl:flex-row lg:flex-row flex-col gap-3 items-center xl:w-auto lg:w-auto w-full">
          <p class="text-white font-semibold xl:text-xl lg:text-xl text-lg xl:mb-0 lg:mb-0 mb-2 xl:mt-0 lg:mt- mt-4 xl:text-left lg:text-left text-center">Promotion Achievement Current Status</p> <!-- 프로모션 달성 여부 -->
          <!-- 달성될 경우 달성 노출 - 달성 건수 노출 , 미달성일 경우 미달성 노출 -->
          <div v-if="domestic_trip.achieveYn == 'Y'" class="bg-slate-50 text-blue-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full" ><span> {{ domestic_trip.achieveCnt }} </span>&nbsp;Achieved</div>
          <div v-else class="bg-slate-50 text-red-500 font-bold p-3 text-center xl:inline-block lg:inline-block block text-lg xl:w-auto lg:w-auto w-full">Not Achieved</div>
        </div>
      </div>
      <!-- E : 50BV -->
      <div class="w-full text-gray-50 text-sm border-t border-white border-dashed pt-4">
        The promotion achievement status is updated on a deadline basis.<br>
        If you return the product yourself within the promotion period, you will not be eligible for the promotion.<br>
        If even one return process occurs before the achievement announcement, promotion achievement will be invalidated.
      </div>
    </div>
  </div>
  <!-- E : INDONESIA TRIP -->
    <section>
        <div class="l">
            <div class="t">
                <span> {{ userid }}&nbsp;  Welcome </span><!-- 님 환영합니다. -->
                <div class="mb-box">
                    <div class="mb-img"><img src="/images/rank_pin/rank_45.jpg" alt=""></div>
                    <div class="mb-name">
                        <span class="na">{{ username }} {{ bb_username}}</span>
                        <span class="lv">{{ rank_name }}</span>
                        <span class="lv">{{ cnt_name }}</span>
                    </div>
                </div>
            </div>
            <div class="b">
                <ul class="mb-list1">
                    <li>
                        <span>{{ i18n.myoffice.memberName }}</span>
                        <span>{{ username }}</span>
                    </li>
                    <li>
                        <span>{{ i18n.myoffice.MemberId }}</span>
                        <span>{{ userid }}</span>
                    </li>
                    <li>
                        <span>{{ i18n.myoffice.email }}</span>
                        <span>{{ e_mail }}</span>
                    </li>
                    <li>
                        <span>{{ i18n.myoffice.handphone }}</span>
                        <span>{{ mobile }}</span>
                    </li>
                </ul>

                <ul class="mb-list2 title" v-show="myconfig.flag_profit == 'Y'">
                    <li style="width: 20%;">
                        <span>{{ i18n.myoffice.dashboard.Week }}</span>
                    </li>
                    <li>
                        <span class="more_wrap">Period&nbsp;&nbsp;<i class="fas fa-caret-square-down total_more" aria-hidden="true"></i></span><!--기간  -->
                    </li>
                    <li style="width: 22%;">
                        <span style="word-break: normal;">{{ i18n.myoffice.aLineTotal }}</span>
                    </li>
                    <li style="width: 22%;">
                        <span style="word-break: normal;">{{ i18n.myoffice.bLineTotal }}</span>
                    </li>
                </ul>
                <ul v-for="Value in week4Data" v-show="myconfig.flag_profit == 'Y'" class="mb-list2 week_list">
                    <li style="width: 20%;">
                        <span>{{ Value.pay_date}}</span>
                    </li>
                    <li>
                        <span style="word-break: normal;">{{ Value.remark}}</span>
                    </li>
                    <li style="width: 22%;">
                        <span>{{ Value.a_week_tot}}</span>
                    </li>
                    <li style="width: 22%;">
                        <span>{{ Value.b_week_tot}}</span>
                    </li>
                </ul>

                <ul class="mb-list2 week_list" v-show="myconfig.flag_profit == 'Y'">
                    <li style="width: 20%;">
                        <span>-</span>
                    </li>
                    <li>
                        <span> {{ i18n.myoffice.dashboard.LineTotal }}</span>
                    </li>
                    <li style="width: 22%;">
                        <span>{{ weekATotal }}</span>
                    </li>
                    <li style="width: 22%;">
                        <span>{{ weekBTotal }}</span>
                    </li>
                </ul>
            </div>
        </div>


        <div class="cont_table_box">
            <h3 class="openIt">&nbsp;<span class="test1"><i class="fas fa-chevron-down" aria-hidden="true"></i></span></h3>
            <section class="open_test">
                <div class="table_box1">
                    <dl class="pr1">
                        <dt v-html="i18n.myoffice.dashboard.MaintenanceQualificationEndingDate"></dt>
                        <dd id="txt01_end_of_uji"> {{ local_edate }}</dd>
                    </dl>
                    <dl class="pr1">
                        <dt>{{ i18n.myoffice.dashboard.PurchasePeriod }}</dt>
                        <dd id="txtUjiEbuy"> {{ uji_date }}</dd>
                    </dl>
                </div>

                <div class="table_box2">
                    <dl class="pr2">
                        <dt v-html="i18n.myoffice.dashboard.MaintenanceQualificationEndingDateGlobal"></dt><!-- 글로벌 유지 종료일 -->
                        <dd id="txt01_end_of_uji"> {{ global_edate }}</dd>
                    </dl>
                    <dl class="pr2">
                        <dt v-html="i18n.myoffice.dashboard.PurchasePeriodGlobal"></dt><!-- 글로벌 유지구매기간 -->
                        <dd id="txtUjiEbuy">{{ ujiG_date }}</dd>
                    </dl>
                </div>

                <!--div class="table_box3" v-show="Autoship">
                    <dl class="pr3">
                        <dt>Autoship Status</dt>
                        <dd>&nbsp;{{ autoship_admits_cnt }}</dd>
                    </dl>
                    <dl class="pr3">
                        <dt>Next Autoship Payment</dt>
                        <dd>&nbsp;{{ autoship_admits_day }}</dd>
                    </dl>
                </div-->

                <div class="table_box4">
                    <dl class="pr4">
                        <dt>{{ i18n.myoffice.dashboard.My_Favorites }}</dt><!-- 즐겨찾기 회원수 -->
                        <dd>&nbsp;{{ favorite_total_count }}&nbsp;<a href="https://vcshop.gcoop.com/MyMemberFavorites">People</a></dd>
                    </dl>
<!--                    <dl class="pr5 btn_view_pay">-->
<!--                        <dt>Bonus total( {{ pay_date }} ) &nbsp;&nbsp;-->
<!--                            <i class="fal fa-lightbulb view_pay_text" v-if="view_pay" v-on:click="view_pay = false" style="color: #333"  aria-hidden="true"></i>-->
<!--                            <i class="fal fa-lightbulb-on view_pay_text"  v-if="!view_pay" v-on:click="view_pay = true" style="color: #ff8466"  aria-hidden="true"></i>-->
<!--                        </dt>&lt;!&ndash; 마감) &ndash;&gt;-->
<!--                        <dd>&nbsp;<span class="view_pay" v-if="view_pay">S$ {{ total_amt }}&nbsp</span></dd>&lt;!&ndash; 원 &ndash;&gt;-->
<!--                    </dl>-->
                </div>
<!--                <div class="table_box5">-->
<!--                    <dl class="pr6">-->
<!--                        <dt>{{  i18n.shop.mypage.availableHeart }}</dt>-->
<!--                        <dd><a href="/heartManager"><span>{{ h_stamp }}</span>&nbsp;Hearts</a></dd>-->
<!--                    </dl>-->
<!--                </div>-->

            </section>
        </div>

        <div class="row-box1 quick_btn_box2">
            <div class="quick-mn  w-1/3">
                <a target="_blank" href="/">
                    <div class="q1234L q1"><i class="fal fa-shopping-bag fa-3x" aria-hidden="true"></i></div>
                    <div class="q1234R q1"><span>Shop</span></div>
                </a>
            </div>
            <div class="quick-mn  w-1/3">
                <a href="#" v-on:click="goAutoLogin('register','')">
                    <div class="q1234L q2"><i class="fal fa-sign-in fa-3x" aria-hidden="true"></i></div>
                    <div class="q1234R q2"><span>{{ i18n.myoffice.memberShortcuts }}</span></div>
                </a>
            </div>
            <div class="quick-mn w-1/3">
                <a href="/MyDashBoard">
                    <div class="q1234L q3"><i class="fal fa-user-chart fa-3x" aria-hidden="true"></i></div>
                    <div class="q1234R q3"><span>{{ i18n.myoffice.dashboardShortcuts }}</span></div>
                </a>
            </div>
            <!-- 레이아웃 조정을 위헤 미사용하는 동안은 주석처리 -->
            <!--<div class="quick-mn autoship_icon_box" v-show="Autoship">
                <a target="_blank" href="/autoshipSE2Cart">
                    <div class="q1234L q4"><img src="/myoffice/images/new_autoship_icon.png" alt="autoship_logo"></div>
                    <div class="q1234R q4"><span>Go to Autoship Page</span></div>
                </a>
            </div>-->
        </div>


<!--        <div class="fr-box chart_box">-->


<!--            <div class="l main_chart1" style="height:300px;padding:10px;"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>-->
<!--                <canvas id="myChart2" height="300" width="836" style="display: block; width: 836px; height: 300px;" class="chartjs-render-monitor"></canvas>-->
<!--                &lt;!&ndash; <div id="piechart" style="padding-top:10px;padding-left: 10px; width: 95%;  min-height: 450px;"></div> &ndash;&gt;-->
<!--            </div>-->

<!--            <div class="position_relate_box">-->
<!--                <span class="table_notice_left">Click on the graph to see the amount.</span>-->
<!--                <span class="table_notice_left">Commission Analysis is based on deposit dates.</span>-->
<!--            </div>-->
<!--            <div class="r main_chart2" style="height:300px;padding:10px;"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>-->
<!--                <canvas id="myChart1" height="300" style="display: block; width: 836px; height: 300px;" width="836" class="chartjs-render-monitor"></canvas>-->
<!--                &lt;!&ndash; <div id="chart_div2" style="padding-top:10px;padding-left: 10px; width: 95%;  min-height: 450px;"></div> &ndash;&gt;-->
<!--            </div>-->
<!--        </div>-->
        <!-- // 차트 -->

        <div class="fr-box1">

            <div class="l">
                <!-- 게시판 -->
                <div class="main-board">
                    <div class="tit" style="position:relative;">Board
                        <ul id="moreTab">
                            <li class="selectTitle" v-show="currentId == 1"><a href="/MyNotice"><i class="fas fa-plus" aria-hidden="true"></i> {{ i18n.myoffice.moreview }}</a></li><!-- 더보기 -->
                            <li class="selectTitle" v-show="currentId == 2"><a href="/MyOrderList"><i class="fas fa-plus" aria-hidden="true"></i> {{  i18n.myoffice.moreview}} </a></li><!-- 더보기 -->
                            <li class="selectTitle" v-show="currentId == 3"><a href="/MyInQuriy"><i class="fas fa-plus" aria-hidden="true"></i> {{  i18n.myoffice.moreview }}</a></li><!-- 더보기 -->
                            <li class="selectTitle" v-show="currentId == 4"><a href="/MyData"><i class="fas fa-plus" aria-hidden="true"></i> {{ i18n.myoffice.moreview }}</a></li><!-- 더보기 -->
                        </ul>
                    </div><!-- Board -->
                    <div class="inner px-2">
                        <div class="board-tab">
                            <ul id="titleTab">
                                <li v-for="(Value) in Tablist" :class="[{ 'on':currentId === Value.id}, 'tabtab']" v-on:click="tabChange(Value.id)"><span >{{ Value.label }}</span></li>
                            </ul>
                        </div>
                        <!-- 게시판 리스트 1 -->
                        <div class="board-list" id="board-list1" v-show="currentId == 1">
                            <ul v-for="Value in boardlist1">
                                <li>
                                    <span><a :href="Value.url" class="ellipsis">{{ Value.title }}</a></span>
                                    <span>{{ Value.date }}</span>
                                </li>
                            </ul>
                        </div>
                        <!-- // 게시판 리스트 1 -->

                        <!-- 게시판 리스트 2 -->
                        <div class="board-list" id="board-list2" style="display:none" v-show="currentId == 2">

                            <div id="search_result1">
                                <div class="list-table-wrap">
                                    <table class="list-table">
                                        <thead>
                                        <tr>
                                            <th>{{ i18n.myoffice.Order.ordno }}</th><!-- 주문번호 -->
                                            <th>{{ i18n.myoffice.Order.orderDate }}</th><!-- 주문일 -->
                                            <th>{{ i18n.myoffice.Order.Status }}</th><!-- 배송상태 -->
                                            <th>{{ i18n.myoffice.Order.amount }}</th><!-- 총금액 -->
                                            <th>BV</th>
                                            <th>{{ i18n.myoffice.Order.type }}</th><!-- 구매유형(전체) -->
                                            <th>{{ i18n.myoffice.Order.location }}</th><!-- 소속센터 -->
                                            <th>{{ i18n.myoffice.Order.membername }}</th><!-- 배송여부 -->
                                        </tr>
                                        </thead>
                                        <tbody  v-for="Value in boardlist2">
                                            <tr>
                                                <td align="center" style="cursor:pointer; color:blue; padding: 3px;"><a href="/MyOrderView/eyJpdiI6IlBWTW9QVjY0cEZYeS94RVk3NmVMOUE9PSIsInZhbHVlIjoiYXVEWjJKSXB5SXpKOXNuMUx0T1V0U3MzRUFBM2xucUdGY3FSRXFNVDR5RT0iLCJtYWMiOiIxMDdiNzU5ZmE0NTc2NzlkYmYwNTQyMTU4ZWRmMDM5NWUyNmI3Yzg1OTQwYWU2N2E4OWRlODdmNmVmNTNmMzg0In0=&amp;bz_cd=USA">{{ Value.ord_no }}</a></td>
                                                <td align="center">{{ Value.ord_date }}</td>
                                                <td align="center" style="padding: 3px;">{{ Value.ord_kind_name }}</td>
                                                <td align="right" style="padding: 3px;">S$ {{ Value.ord_amt }}</td>
                                                <td align="right" style="padding: 3px;">{{ Value.ord_pv }}</td>
                                                <td align="center" style="padding: 3px;">{{ Value.kind_name }}</td>
<!--                                                <td align="center" style="padding: 3px;">{{ Value.cnt_name }}</td>-->
                                                <td align="center" style="padding: 3px;"><a>{{ Value.deli_finish }}</a></td><!-- 배송검색 -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- // 게시판 리스트 2 -->
                        <!-- 게시판 리스트 3 -->
                        <div class="board-list" id="board-list3" style="display:none" v-show="currentId == 3">
                            <div id="search_result2">
                                <div class="list-table-wrap">
                                    <table class="list-table">
                                        <thead>
                                        <tr>
                                            <th data-priority="2">{{ i18n.inquriy.inquriy.status }}</th>
                                            <th data-priority="3">{{ i18n.inquriy.inquriy.category }}</th>
                                            <th data-priority="4">{{ i18n.inquriy.inquriy.title }}</th>
                                            <th data-priority="5">{{ i18n.inquriy.inquriy.questionDate }}</th>
                                            <th data-priority="6">{{ i18n.inquriy.inquriy.questioner }}</th>
                                            <th data-priority="7">{{ i18n.inquriy.inquriy.answerDate }}</th>
                                        </tr>
                                        </thead>
                                        <tbody  v-for="Value in boardlist3">
                                        <tr>
                                            <td align="center" style="cursor:pointer; color:blue; padding: 3px;">{{ Value.flag }}</td>
                                            <td align="center" style="cursor:pointer; color:blue; padding: 3px;">-</td>
                                            <td align="left" style="cursor:pointer; color:blue; padding: 3px;"><a :href="Value.url">{{ Value.title }} <em class="badge">{{ Value.reply_cnt }}</em></a></td>
                                            <td align="center" style="cursor:pointer; color:blue; padding: 3px;">{{ Value.reg_date }}</td>
                                            <td align="center" style="cursor:pointer; color:blue; padding: 3px;">{{ Value.reply_user_name }}</td>
                                            <td align="center" style="cursor:pointer; color:blue; padding: 3px;">{{ Value.reply_reg_date }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- // 게시판 리스트 3 -->
                        <!-- 게시판 리스트 3 -->
                        <div class="board-list" id="board-list4" style="display:none" v-show="currentId == 4">
                            <div class="list-table-wrap">
                                <table class="list-table">
                                    <thead>
                                    <tr>
                                        <th data-priority="2">{{ i18n.inquriy.inquriy.status }}</th>
                                        <th data-priority="4">{{ i18n.inquriy.inquriy.title }}</th>
                                        <th data-priority="6">{{ i18n.inquriy.inquriy.questionDate }}</th>
                                    </tr>
                                    </thead>
                                    <tbody  v-for="Value in boardlist4">
                                    <tr>
                                        <td align="center"><span v-html="Value.notice_yn"></span></td>
                                        <td style="overflow: hidden;"><a :href="Value.url" >{{ Value.title}} <span v-html="Value.attach_cnt"></span></a></td>
                                        <td align="center">{{ Value.date}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- // 게시판 리스트 3 -->
                    </div>
                </div>
                <!-- // 게시판 -->
            </div>
        </div>

    </section>
</template>


<script>

    import numeral from "numeral";
    import TabItem from './TabItem.vue'
    import VueSimpleAlert from "vue3-simple-alert";

    export default {
        name: "MyofficeMain",
        data: () => ({
            toggleinfo: true,
            toggleinfo2: true,
            toggleinfo3: false,
            title : "",
            notice_no : "",
            board_title : "",
            username : "",
            bb_username : "",
            rank_name : "",
            cnt_name : "",
            e_mail : "",
            mobile : "",
            week4Data : [],
            weekATotal : "",
            weekBTotal : "",
            local_edate : "",
            uji_date : "",
            global_edate : "",
            ujiG_date : "",
            autoship_admits_cnt : "",
            autoship_admits_day : "",
            favorite_total_count : "",
            pay_date : "",
            total_amt : "",
            boardlist1 : [],
            boardlist2 : [],
            boardlist3 : [],
            boardlist4 : [],
            Tablist: [
                { id: 1, label: i18n.menu.MyNotice, content: 'board-list1', href:'MyNotice' },
                { id: 2, label: i18n.menu.MyOrderList, content: 'board-list2', href:'MyOrderList' },
                { id: 3, label: i18n.menu.MyInQuriy, content: 'board-list3', href:'MyInQuriy' },
                { id: 4, label: i18n.menu.MyData, content: 'board-list4', href:'MyData' }
            ],
            currentId: 1,
            working_nation : working_nation,
            i18n: window.i18n,
            userid : window.Laravel.user,

            // 오토십 여부 노출 여부 add by hskim :: 2023-07-24 오전 9:21
            // Autoship : true,
            Autoship : window.isAutoship,

            view_pay : true,
            myconfig : "",
            h_stamp : 0,
            promo50       : [],
            promo500      : [],
            initiator     : [],
            domestic_trip : [],
        }),
        beforeCreate() {
            axios.post(window.Laravel.back_url+'/api/memberInfo',{
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.username = response.data.username;
                if(response.data.bb_name != "" && response.data.bb_name != null){
                    this.bb_username = " / "+response.data.bb_name;
                }
                this.rank_name = response.data.rank_name;
                this.cnt_name = response.data.cnt_name;
                this.e_mail = response.data.e_mail;
                this.mobile = response.data.mobile;

            });

            axios.post(window.Laravel.back_url+'/api/dashboard',{
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.title = response.data.B_BV.d_val;
                this.get4WeekData(response.data);
                this.local_edate = response.data.dash_date.local_edate;
                this.uji_date = response.data.dash_date.uji_date;
                this.global_edate = response.data.dash_date.global_edate;
                this.ujiG_date = response.data.dash_date.ujiG_date;
                this.pay_date = response.data.pay_calc.pay_date;
                this.total_amt = response.data.pay_calc.total_amt;
                this.h_stamp = response.data.h_stamp;
            });
            axios.post(window.Laravel.back_url+'/api/getMyofficeMainAutoShipStatus',{
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.autoship_admits_cnt = response.data.autoship_admits_cnt == 1 ? i18n.myoffice.asMstStatus.b : i18n.myoffice.asMstStatus.f ;
                this.autoship_admits_day = response.data.autoship_admits_day;

            });

            axios.post(window.Laravel.back_url+'/api/favoriteCount',{
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.favorite_total_count = response.data.length ;

            });
            axios.post(window.Laravel.back_url+'/api/chart1',{
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.drawChart(response.data);
            });
            axios.post(window.Laravel.back_url+'/api/chart2',{
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.drawChart2(response.data);
            });
            axios.post(window.Laravel.back_url+'/api/getMainBoard1',{
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.mainBoard1(response.data);
            });
            axios.post(window.Laravel.back_url+'/api/getMainBoard2',{
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.mainBoard2(response.data);
            });
            axios.post(window.Laravel.back_url+'/api/getMainBoard3',{
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.mainBoard3(response.data);
            });
            axios.post(window.Laravel.back_url+'/api/getMainBoard4',{
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.mainBoard4(response.data);
            });
            axios.post(window.Laravel.back_url + '/api/getMyConfig', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                this.myconfig = response.data;

            });

            axios.post(window.Laravel.back_url+'/api/promotion_index',{
              'promo_type' : ['initiator', 'domestic_trip']
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
              if (response.data.ret == '200') {
                this.initiator      = response.data.initiator;
                this.domestic_trip  = response.data.domestic_trip;
                // this.promo50     = response.data.seven_core.promotion50Pv; // seven core 50
                // this.promo500    = response.data.seven_core.promotion500Pv; // seven core 500
              }
            });
        },
        created() {
            /* S : 2023년 7일 1일 이전 가입자 한정으로 회원정보 수정 여부 체크(사전가입자 회원정보수정 요청 목적) */
            // if(window.Laravel.modinfo_check) {
            //   VueSimpleAlert.alert(i18n.myoffice.modinfoMsg,"","",{allowOutsideClick : false}).then((result) => {
            //     if(result){
            //       location.replace("/MyPersonInfo");
            //     }
            //   });
            // }
            /* E : 2023년 7일 1일 이전 가입자 한정으로 회원정보 수정 여부 체크 */
        },
        methods:{
            get4WeekData($list) {
                var i = 3 ;
                for( i ; i >= 0 ; i--){
                    if( i == 3){
                        var a_week_tot_data = $list.A_WEEK_TOT4.d_val;
                        var b_week_tot_data = $list.B_WEEK_TOT4.d_val;
                        var d_remark_data = $list.A_WEEK_TOT4.d_remark;
                    }
                    if( i == 2){
                        var a_week_tot_data = $list.A_WEEK_TOT3.d_val;
                        var b_week_tot_data = $list.B_WEEK_TOT3.d_val;
                        var d_remark_data = $list.A_WEEK_TOT3.d_remark;
                    }
                    if( i == 1){
                        var a_week_tot_data = $list.A_WEEK_TOT2.d_val;
                        var b_week_tot_data = $list.B_WEEK_TOT2.d_val;
                        var d_remark_data = $list.A_WEEK_TOT2.d_remark;
                    }
                    if( i == 0){
                        var a_week_tot_data = $list.A_WEEK_TOT1.d_val;
                        var b_week_tot_data = $list.B_WEEK_TOT1.d_val;
                        var d_remark_data = $list.A_WEEK_TOT1.d_remark;
                    }
                    this.week4Data.push({
                        pay_date : $list.PAY_DATE.d_code - i ,
                        remark : d_remark_data ,
                        a_week_tot : a_week_tot_data ,
                        b_week_tot : b_week_tot_data ,

                    });
                }

                this.weekATotal = $list.A_WEEK_TOT.d_val;
                this.weekBTotal = $list.B_WEEK_TOT.d_val;

            },
            drawChart($list) {
                var chartwidth = $('#piechart').width();
                if($list)
                {

                    var chartchl = $list.chartchl.split("|");
                    var ctx = document.getElementById('myChart2').getContext('2d');

                    var cartchd = $list.chartchd;
                    var txtArray = new Array();
                    cartchd = cartchd.split(",");
                    for(var i = 0 ; i < cartchd.length; i++){
                        var subArray = new Array(cartchd[i]);
                        txtArray.push(cartchd[i]);

                    }

                    var myChart = new Chart(ctx, {
                        type: 'doughnut',
                        data: {
                            labels: chartchl,
                            datasets: [{
                                label: i18n.myoffice.Commission.BonusRate+'(%)',  /* 수당비율 */
                                data: txtArray,
                                backgroundColor: [
                                    '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                                ],
                                borderColor: [
                                    '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                                ],
                                borderWidth: 1
                            }]
                        },

                        options: {
                            title: {
                                display: true,
                                text: ''
                            },
                            maintainAspectRatio: false,
                        }
                    });

                }
            },
            drawChart2($list) {
                var chartwidth = $('#piechart').width();
                if($list)
                {

                    var chartchl = new Array(i18n.myoffice.jan,i18n.myoffice.feb,i18n.myoffice.mar,i18n.myoffice.apr,i18n.myoffice.may,i18n.myoffice.jun,
                        i18n.myoffice.jul,i18n.myoffice.aug,i18n.myoffice.sep,i18n.myoffice.oct,i18n.myoffice.nov,i18n.myoffice.dec);

                    var ctx = document.getElementById('myChart1').getContext('2d');

                    var cartchd = $list.chartchd;
                    var txtArray = new Array();
                    cartchd = cartchd.split(",");
                    for(var i = 0 ; i < cartchd.length; i++){
                        var subArray = new Array(cartchd[i]);
                        txtArray.push(cartchd[i]);

                    }
                    var labels  = Array(i18n.myoffice.jan,i18n.myoffice.feb,i18n.myoffice.mar,i18n.myoffice.apr,i18n.myoffice.may,i18n.myoffice.jun,
                        i18n.myoffice.jul,i18n.myoffice.aug,i18n.myoffice.sep,i18n.myoffice.oct,i18n.myoffice.nov,i18n.myoffice.dec);

                    var myChart = new Chart(ctx, {
                        type: 'horizontalBar',
                        data: {
                            labels: Array(i18n.myoffice.jan,i18n.myoffice.feb,i18n.myoffice.mar,i18n.myoffice.apr,i18n.myoffice.may,i18n.myoffice.jun,
                                i18n.myoffice.jul,i18n.myoffice.aug,i18n.myoffice.sep,i18n.myoffice.oct,i18n.myoffice.nov,i18n.myoffice.dec),
                            datasets: [{
                                label: i18n.myoffice.Commission.BonusRate+'(%)',  /* 수당비율 */
                                data: txtArray,
                                backgroundColor: [
                                    '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                                ],
                                borderColor: [
                                    '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
                                ],
                                borderWidth: 1
                            }]
                        },
                        options: {
                            title: {
                                display: true,
                                text: i18n.myoffice.dashboard.yearmonthbonus /* 년 월별 수당금액 */
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        userCallback: function(value, index, values) {
                                            if(parseInt(value) >= 1000){
                                                value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                            }
                                            return value;
                                        }
                                    }
                                }]
                            },
                            tooltips: {
                                callbacks: {
                                    label: function(tooltipItem, data) {
                                        var value = $list.data[tooltipItem.index];
                                        if(parseInt(value) >= 1000){
                                            value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                        }
                                        return value;
                                    }
                                } // end callbacks:
                            },
                        },
                    });

                }
            },
            mainBoard1($list){
                $list.forEach((value, index) => {
                    numeral.defaultFormat('$0,0[.]00');
                    // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                    // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                    this.boardlist1.push({
                        no: value.no,
                        title: value.title,
                        date: value.reg_date,
                        url: "MyNotice/"+value.no,

                    });
                });
            },
            mainBoard2($list){
                $list.forEach((value, index) => {
                    numeral.defaultFormat('$0,0[.]00');
                    // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                    // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                    this.boardlist2.push({
                        ord_no: value.ord_no,
                        title: value.title,
                        date: value.reg_date,
                        ord_date : value.ord_date,
                        ord_amt : value.ord_amt,
                        ord_pv : value.ord_pv,
                        kind_name : value.kind_name,
                        cnt_name : value.cnt_name,
                        deli_finish : value.deli_finish,
                        ord_kind_name : value.ord_kind_name,
                        //url: value.i_list ? "",

                    });
                });
            },
            mainBoard3($list){
                $list.forEach((value, index) => {
                    numeral.defaultFormat('$0,0[.]00');
                    // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                    // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                    this.boardlist3.push({
                        flag: value.flag,
                        title: value.title,
                        reg_date: value.reg_date,
                        reply_cnt: value.reply_cnt,
                        reply_reg_date: value.reply.reg_date,
                        reply_user_name: value.reply.username,
                        url: "MyInQuiryView/"+value.no,

                    });
                });
            },
            mainBoard4($list){
                $list.forEach((value, index) => {
                    numeral.defaultFormat('$0,0[.]00');
                    // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                    // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                    this.boardlist4.push({
                        notice_yn : value.notice_yn == 'Y' ? "<span class='notice'>Notice</span>" : "",
                        attach_cnt : value.attach_cnt ? "<i class=\"fas fa-file-text-o attach\"></i>" : "",
                        no: value.no,
                        title: value.title,
                        date: value.reg_date,
                        url: "MyData/"+value.no,

                    });
                });
            },
            tabChange(id){
                this.Tablist.id;
                this.currentId = id;
            },
            goAutoLogin(mode, url) {
                window.open("https://signup.gcoop.com/AutoLogin?xid=" + window.Laravel.xid_info + "&token=" + window.Laravel.xid_info);

                // let config = {
                //     headers: {
                //         Authorization: 'Bearer ' + window.Laravel.access_token
                //     }
                // };
                //
                // axios.post(window.Laravel.back_url +'/api/goAutoLogin',{
                //     mode: mode,
                //     url: url,
                //     bz_cd: window.Laravel.bz_cd,
                // },config).then(response => {
                //     if(response.data.href_url != ""){
                //         window.open(response.data.href_url);
                //     }
                // }).catch(e => {
                // });
            },

        },
    }

</script>

<style scoped>

</style>
