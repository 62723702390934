<template>
  <dl class="myo_search_list">
    <dt>{{ i18n.myoffice.myoSearchForm.Line }}</dt>
    <dd>
      <select id="tree_node" v-bind="tree_node" class="select1 wide">
        <option value="BIN" >{{ i18n.myoffice.Supportline }}</option>
        <option value="UNI" >{{ i18n.myoffice.Recommendline }}</option>
      </select>
    </dd>
  </dl>

</template>

<script>
export default {
  name: "legForm",
  data: () => ({
        i18n: window.i18n,
        s_date : "",
        reg_type : "P",
  }),
    props : {
        tree_node : String
    }

}
</script>

<style scoped>

</style>
