<template>
    <!--  <section class="absolute w-full min-h-screen">-->
    <!--    <div :style="full_height" class="absolute w-full min-h-screen m-0 p-0 item-center bg-gray-500 opacity-50 z-30"></div>-->
    <!--    <div :style="item_top" class="absolute w-full h-full flex justify-center z-40">-->
    <!--      <div class="absolute lg:w-1/3 my-auto mx-auto px-2 text-start bg-white">-->
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
        <address_search @set_address="set_address" @close_address_search="close_address_search" />
    </modalView>

    <div class="w-full p-4 flex justify-between border-b">
        <h2 class="w-full text-2xl">Manage shipping addresses</h2>
        <p @click=" close_address_book " class="cursor-pointer p-2">X</p>
    </div>
    <div class="w-full p-2 mb-24 md:mb-4">
        <div class="w-full text-gray-300 py-2 border-b">Select or register the shipping address.</div>
        <ul class="flex py-2">
            <li class="flex-1 mr-2" @click="currentTab = 1">
                <a class="text-center block border rounded py-2 px-4"
                   :class="currentTab === 1 ? 'border-blue-500  bg-blue-500 hover:bg-blue-700 text-white' : 'border-white hover:border-gray-200 text-blue-500 hover:bg-gray-200'" href="#">Select / Manage
                    shipping address</a>
            </li>
            <li class="flex-1 mr-2" @click=" get_create_address_book ">
                <a class="text-center block border rounded py-2 px-4"
                   :class="currentTab === 2 ? 'border-blue-500  bg-blue-500 hover:bg-blue-700 text-white' : 'border-white hover:border-gray-200 text-blue-500 hover:bg-gray-200'"  href="#">Register shipping address</a>
            </li>
        </ul>

        <div v-for="(address) in address_list" :key="address.seq_oab" class="border" v-if="currentTab === 1">
            <div class="w-full p-4 flex justify-between border-b">
                <h4>{{ address.r_nick }}</h4>
                <p @click=" delete_item(address.seq_oab) " class="cursor-pointer p-2">X</p>
            </div>
            <div class="w-full p-4">
                <p v-show="address.default_yn == 'Y'" >Basic</p>
                <p>Address : {{ address.r_addr2 ? (address.r_addr2).replace(/\|\@\|/g, ", ") : ""  }}{{ address.r_addr2 ? ', ' : '' }}{{ address.r_addr1 ? (address.r_addr1).replace(/\|\@\|/g, ", ") : "" }}</p>
                <p>Name  : {{ address.r_name }}</p>
                <p>Mobile : {{ address.r_mobile }}</p>
                <div class="flex justify-center" v-show="this.mypage_yn != 'Y'">
                    <button @click=" get_info_address_book(address.seq_oab) " class="mx-1 py-2 px-4 bg-blue-500 rounded text-white">Select</button>
                    <button @click=" get_edit_address_book(address.seq_oab) " class="mx-1 py-2 px-4 bg-gray-500 rounded text-white">Edit</button>
                </div>

                <div class="flex justify-center" v-show="this.mypage_yn == 'Y'">
                    <button @click=" get_edit_address_book(address.seq_oab) " class="mx-1 py-2 px-4 bg-blue-500 rounded text-white">Edit</button>
                    <button @click=" delete_item(address.seq_oab) " class="mx-1 py-2 px-4 bg-gray-500 rounded text-white">Delete</button>
                </div>

            </div>
        </div>
        <div v-if="currentTab === 2">
            <form name="shipping_info">
                <input type="hidden" name="_token">
                <input type="hidden" name="client_ip">
                <div class="my-4 space-x-0 lg:flex lg:space-x-4">
                    <div class="w-full lg:w-1/2">
                        <label for="firstName" class="block mb-3 text-sm font-semibold text-gray-500">Reciever</label>
                        <input name="name" id="firstName" ref="d_name" type="text" placeholder="First Name" maxlength="45" v-model='this.shipping_info["d_name"]'
                               class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div class="w-full lg:w-1/2 mt-2 lg:mt-0 ">
                        <label for="mobile" class="block mb-3 text-sm font-semibold text-gray-500">Mobile</label>
                        <input name="mobile" id="mobile" ref="d_mobile" type="text" @keyup="regNumber('d_mobile')" placeholder="Mobile" v-model='this.shipping_info["d_mobile"]'
                               class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="12">
                    </div>
                </div>
                <div class="mt-4">
                    <input type="button" @click="search_address" value="search address" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">
                    <div class="text-red-500 font-semibold text-sm md:text-base pt-4">※ For DELIVERY to Academy kindly click "Delivery to GCOOPER Academy" button. For delivery to preferred address (House, Office & etc) kindly enter the address below.</div>
                </div>

                <div class="mt-4 space-x-0 lg:flex lg:space-x-4">
                    <div class="relative z-[14] w-full bg-white">
                        <label for="address" class="block mb-3 text-sm font-semibold text-gray-500">Address</label>
                        <input name="address" id="address" ref="d_address" type="text" placeholder="" v-model='this.shipping_info["d_address1_str"]' readonly @click="search_address"
                               class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 cursor-pointer">
                    </div>
                </div>
                <div class="mt-4 space-x-0 lg:flex lg:space-x-4">
                    <div class="relative z-[13] w-full bg-white">
                        <label for="address2" class="block mb-3 text-sm font-semibold text-gray-500">Address2</label>
                        <input name="address2" id="address2" ref="d_address2" type="text" placeholder="House Number / Name of building / Name of neighborhood" v-model='this.shipping_info["d_address2"]'
                               class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 cursor-pointer">
                    </div>
                </div>
                <div class="mt-4">
                    <input type="checkbox" name="default" id="default_chk" v-model="checked">
                    <label for="default_chk"> Set the selected address as the default shipping address</label>
                </div>
            </form>
            <div class="mt-4 space-x-0 flex justify-center lg:space-x-4">
                <button @click="currentTab = 1" class="mx-1 py-2 px-4 bg-gray-500 rounded text-white">Cancel</button>
                <button @click=' address_update(this.shipping_info["seq"]) ' class="mx-1 py-2 px-4 bg-blue-500 rounded text-white" v-if='this.shipping_info["seq"]'>Update</button>
                <button @click=" address_add " class="mx-1 py-2 px-4 bg-blue-500 rounded text-white" v-if='!this.shipping_info["seq"]'>Register</button>
            </div>
        </div>
    </div>
    <!--      </div>-->
    <!--    </div>-->
    <!--  </section>-->
</template>

<script>
import vSelect from "vue-select";
import alert from "vue3-simple-alert";
import modalView from "./modalView";

export default {
    name: "addressBook",
    components : {
        vSelect,
        alert,
        modalView,
    },
    /*props:{
      openlayer: Boolean
    },*/
    props:['mypage_yn'],
    data(){
        return {
            address_book: Boolean,
            currentTab: 1,
            csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
            address_list: [],
            state_list: [],
            city_list: [],
            street_list: [],
            post_list: [],
            shipping_info: [],
            height: 0,
            full_height: {},
            openlayer:Boolean,
            item_top: {},
            address_search_open:false,
        };
    },
    computed : {
        openlayer: function(){
            this.address_book = this.openlayer;
            //return this.set_open;
        }
    },
    created : function () {

        this.getAddressBook();
        let body = document.body,
            html = document.documentElement;

        this.height = Math.max( body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight );

    },
    mounted() {
        this.full_height = {'height':this.height};
        this.item_top = {'top':this.height - (this.height*0.75)};
        this.getPostState();
    },
    methods : {
        getAddressBook(){
            axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.address_list = response.data;
                })
        },
        regNumber(colum){
            var reg = /'/gi;
            var text = this.shipping_info[colum];
            text = text.replace(/[^0-9]/g,'');
            this.shipping_info[colum] = text;
        },
        get_info_address_book(id){
            this.$emit("get_info_address_book",id);
            this.$emit("close_address_book");
        },
        delete_item(seq){
            alert.confirm("Are you sure you want to delete this address?").then(() => {
                axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_delete',{
                    bz_cd: window.Laravel.bz_cd,
                    seq: seq,
                },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                    .then(response => {
                        this.address_list = response.data;
                    })
            })
        },
        // 우편번호  가져오기
        getPostState(){   // 주(State) 목록 가져오기
            axios.post(process.env.MIX_BACK_URL+'/api/getState',{

            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    // this.shipping_info["d_state"] = value;
                    this.state_list = response.data;
                })
                .catch(e => {
                });
        },
        get_create_address_book()
        {
            this.currentTab = 2;
            this.shipping_info["seq"]            = '';
            this.shipping_info["d_name"]         = '';
            this.shipping_info["d_name1"]        = '';
            this.shipping_info["d_name2"]        = '';
            this.shipping_info["d_state"]        = '';
            this.shipping_info["d_city"]         = '';
            this.shipping_info["d_street"]       = '';
            this.shipping_info["d_post"]         = '';
            this.shipping_info["d_address1"]     = '';
            this.shipping_info["d_address1_str"] = '';
            this.shipping_info["d_address2"]     = '';
            this.shipping_info["d_telephone"]    = '';
            this.shipping_info["d_mobile"]       = '';
            this.shipping_info["default"]        = '';
            this.shipping_info["r_county"]       = '';
        },
        get_edit_address_book(id)
        {
            axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_item',{
                seq:id
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.currentTab = 2;
                    this.shipping_info["seq"]             = response.data.seq_oab;
                    this.shipping_info["d_name"]          = response.data.r_name;
                    this.shipping_info["d_name1"]         = '';
                    this.shipping_info["d_name2"]         = '';
                    this.shipping_info["d_address1"]      = response.data.r_addr1;
                    this.shipping_info["d_address1_str"]  = response.data.r_addr1 ? (response.data.r_addr1).replace(/\|@\|/g, ", ") : '';
                    this.shipping_info["d_address2"]      = response.data.r_addr2 ? (response.data.r_addr2).replace(/\|@\|/g, " ") : "";
                    this.shipping_info["d_post"]          = response.data.r_post;
                    this.shipping_info["d_telephone"]     = response.data.r_tel;
                    this.shipping_info["d_mobile"]        = response.data.r_mobile;
                    this.shipping_info["r_county"]        = response.data.r_county;

                    this.checked = (response.data.default_yn === 'Y');
                })
        },
        // 주소록 수정하기
        address_update(id){
            if(!this.shipping_info["d_name"]){
                alert.alert('Please enter reciever name');
                return false;
            }
            // if(!this.shipping_info["d_name2"]){
            //   alert.alert('Please enter last name');
            //   return false;
            // }
            if(!this.shipping_info["d_mobile"]){
                alert.alert('Please enter mobile No.');
                return false;
            }
            if(!this.shipping_info["d_address1"]){
                alert.alert('Please select shipping Address information');
                return false;
            }

            let format = new FormData();
            format.append("_token"   , this.csrf);
            format.append("seq"      , id);
            format.append("bz_cd"    , window.Laravel.bz_cd);
            format.append("name"     , this.shipping_info.d_name);
            format.append("mobile"   , this.shipping_info.d_mobile);
            format.append("address1" , this.shipping_info.d_address1);
            format.append("address"  , this.shipping_info.d_address2);
            format.append("post"     , this.shipping_info.d_post);
            format.append("default"  , this.checked);
            format.append("client_ip", window.Laravel.clientIp);
            format.append("tariff"   , this.shipping_info.tariff);
            format.append("free_bv"  , this.shipping_info.free_bv);
            format.append("r_county" , this.shipping_info.r_county);

            axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_update', format,
                {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                    this.currentTab = 1;
                    this.address_list = response.data;
                    // if(response.data.result == 'Success'){
                    //   let form = document.shipping_info;
                    //   form.action = '/order';
                    //   form.order_seq.value = response.data.order_seq;
                    //   form.shipping_fee.value = this.shipping_selected;
                    //   form.tax_fee.value = this.tax_selected;
                    //   form.pay_total_amt.value = this.total_cost;
                    //   form.submit();
                    // }
                    // this.getCart(window.Laravel.bz_cd);
                });

            // this.$router.go（-1）;
        },
        // 주소록 추가하기
        address_add(){
            if(!this.shipping_info["d_name"]){
                alert.alert('Please enter reciever name');
                return false;
            }
            // if(!this.shipping_info["d_name2"]){
            //   alert.alert('Please enter last name');
            //   return false;
            // }
            if(!this.shipping_info["d_mobile"]){
                alert.alert('Please enter mobile No.');
                return false;
            }
            if(!this.shipping_info["d_address1"]){
                alert.alert('Please select shipping Address information');
                return false;
            }

            let format = new FormData();
            format.append("_token"   , this.csrf);
            format.append("bz_cd"    , window.Laravel.bz_cd);
            format.append("name"     , this.shipping_info.d_name);
            format.append("mobile"   , this.shipping_info.d_mobile);
            format.append("address1" , this.shipping_info.d_address1);
            format.append("address"  , this.shipping_info.d_address2);
            format.append("post"     , this.shipping_info.d_post);
            format.append("default"  , this.checked);
            format.append("client_ip", window.Laravel.clientIp);
            format.append("tariff"   , this.shipping_info.tariff);
            format.append("free_bv"  , this.shipping_info.free_bv);
            format.append("r_county" , this.shipping_info.d_city_code);
            axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_store', format,
                {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                    this.currentTab = 1;
                    this.address_list = response.data;
                    // if(response.data.result == 'Success'){
                    //   let form = document.shipping_info;
                    //   form.action = '/order';
                    //   form.order_seq.value = response.data.order_seq;
                    //   form.shipping_fee.value = this.shipping_selected;
                    //   form.tax_fee.value = this.tax_selected;
                    //   form.pay_total_amt.value = this.total_cost;
                    //   form.submit();
                    // }
                    // this.getCart(window.Laravel.bz_cd);
                });

            // this.$router.go（-1）;
        },
        close_address_book(){
            // this.openlayer = false;
            this.$emit("close_address_book");
        },
        search_address(){
            this.address_search_open = true;
        },
        set_address(value){
            this.address_search_open = false;
            this.shipping_info["d_address1"]     = [value.provinsi, value.kota_kabupaten, value.kecamatan_distrik].join('|@|');
            this.shipping_info["d_address1_str"] = [value.provinsi, value.kota_kabupaten, value.kecamatan_distrik].join(', ');
            this.shipping_info["d_post"]         = value.postcode;
            this.shipping_info["d_city_code"]    = value.city_code;
            this.shipping_info["tariff"]         = value.tariff;  // 배송료
            this.shipping_info["free_bv"]        = value.free_bv; // 무료배송 기준 (미달시 배송료 부과(tariff))

        },
        close_address_search(){
            this.address_search_open = false;
        }

    }
}
</script>

<style>

</style>
