<template>
    <div class="member-search">
        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <div class="myo_condition_box">
                <dl class="myo_search_list">
                    <dt>{{ i18n.myoffice.myoSearchForm.selectDate }}</dt>
                    <dd>
                        <span class="radiobox_icon">
                            <input type="radio" v-model="radioCondition" id="radioCondition" value="1" checked>
                            <label for="radioCondition"><i class="far fa-dot-circle"></i> Daily</label>
                        </span>
                        <span class="radiobox_icon">
                            <input type="radio" v-model="radioCondition" id="radioCondition2" value="2">
                            <label for="radioCondition2"><i class="far fa-dot-circle"></i> Weekly</label>
                        </span>
                        <span class="radiobox_icon">
                            <input type="radio" v-model="radioCondition" id="radioCondition3" value="3">
                            <label for="radioCondition3"><i class="far fa-dot-circle"></i> Monthly</label>
                        </span>
<!--                        <span class="radiobox_icon">-->
<!--                            <input type="radio" v-model="radioCondition" id="radioCondition4" value="4">-->
<!--                            <label for="radioCondition4"><i class="far fa-dot-circle"></i> Quarterly</label>-->
<!--                        </span>-->
<!--                        <span class="radiobox_icon">-->
<!--                            <input type="radio" v-model="radioCondition" id="radioCondition5" value="5">-->
<!--                            <label for="radioCondition5"><i class="far fa-dot-circle"></i> Semi-annual</label>-->
<!--                        </span>-->
<!--                        <span class="radiobox_icon">-->
<!--                            <input type="radio" v-model="radioCondition" id="radioCondition6" value="6">-->
<!--                            <label for="radioCondition6"><i class="far fa-dot-circle"></i> Annual</label>-->
<!--                        </span>-->
                    </dd>
                </dl>
                <div class="relative z-50">
                    <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date" :title_hide = "true" ></dateForm>
                </div>
            </div>
            <!-- 검색 버튼 박스 -->
            <div class="myo_condition_search2">
                <!-- 페이지 새로고침 -->
                <button type="button" class="page_reset_btn" onclick="location.reload();">{{ i18n.myoffice.Refresh }}</button>
                <!-- 전체내용 검색 -->
                <button type="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>
        </form>
    </div>

    <div class="list-table-wrap">
        <h2 class="myo_table_tit">Academy</h2>
        <ul id="myo_form">
            <li>
                <span class="item_title">Current number of members</span>
                <span class="item_content">{{ cnt_count }}</span>
            </li>
            <li>
                <span class="item_title">Name of Academy</span>
                <span class="item_content">{{ cnt_info.cnt_name }}</span>
            </li>
            <li>
                <span class="item_title">Address</span>
                <span class="item_content">{{ cnt_info.addr2 }} {{  cnt_info.addr1 }}</span>
            </li>
            <li>
                <span class="item_title">Opening date</span>
                <span class="item_content">{{ cnt_info.start_date }}</span>
            </li>
        </ul>
    </div>

    <div id="search_result" class="list-table-wrap mt-4">

        <div class="list-table-wrap">
            <table class="list-table">
                <thead>
                <tr>
                    <th>Type</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>The Number of new member</th>
                    <th>Total order</th>
                    <th>Total sales</th>
                    <th>Total BV</th>
                    <th>Exchange/Return BV</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="(Value,index) in academyList" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.kind }}</th>
                        <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.sdate }}</th>
                        <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.edate }}</th>
                        <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.new_cnt }}</th>
                        <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.ord_cnt }}</th>
                        <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.ord_amt }}</th>
                        <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.ord_bv_a }}</th>
                        <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.ord_bv_b }}</th>

                    </tr>
                    <tr class="h-2" v-if="total_count == 0">
                        <td class="text-left md:text-center pl-5 py-5" colspan="13">{{ this.list_msg }}</td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import dateForm from '../../searchForm/dateForm.vue'
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyAcademy",
    components : {
        dateForm,
    },
    data: () => ({
        i18n: window.i18n,
        academyList : "",
        total_count : 0,
        total_page : 0,
        cnt_info : "",
        cnt_count : "",
        radioCondition : "1",
        s_date : "",
        e_date : "",
        list_msg : "",
    }),
    beforeCreate() {

        axios.post(window.Laravel.back_url + '/api/getAcademyMemInfo',{},{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.cnt_info = response.data.cnt_info;
            this.cnt_count = response.data.cnt_count;

        });
    },
    methods : {
        submitForm : function (event){
            event.preventDefault();
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getAcademy',{
                s_date : this.s_date,
                e_date : this.e_date,
                radioCondition : this.radioCondition,
                cnt_cd : this.cnt_info.cnt_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.academyList = response.data.academyList.data;
                this.total_count = response.data.total_count;

                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
            }).catch(error => console.error(error));

        },
        set_s_date(s_date){
            this.s_date = s_date;
        },
        set_e_date(e_date){
            this.e_date = e_date;
        },

    }
}
</script>

<style scoped>

</style>