<template>
    <loading :active="false"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <div id="divPrint">
        <div class="position_relate_box">
            <abbr title="{{ i18n.myoffice.myofficeAlert.CanTablescroll2 }}" rel="tooltip" class="position_ques_icon">{{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
        </div>
        <div id="search_result"  class="list-table-wrap">
            <table class="list-table">
                <thead>
                <tr>
                    <th class="ltit" style="height:35px;">No</th>
                    <th>{{ i18n.myoffice.date }}</th>
                    <th>{{ i18n.myoffice.MemberId }}</th>
                    <th>{{ i18n.myoffice.memberName }}</th>
                    <th>{{ i18n.myoffice.Order.amount }}</th>
                    <th>{{ i18n.myoffice.remark }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(Value,index) in List">
                    <td class="text-center">{{ Value.rn }}</td>
                    <td class="text-center">{{ Value.pay_date }}</td>
                    <td class="text-center">{{ Value.userid }}</td>
                    <td class="text-center">{{ Value.username }}</td>
                    <td class="text-right">{{ Value.amt }}</td>
                    <td class="text-center">{{ Value.remark }}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div><!-- divPrint -->

    <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
        <v-pagination
            v-model="page"
            :pages=total_page
            :range-size="1"
            active-color="#DCEDFF">
        </v-pagination>
    </div>


</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
    name: "MyBenefitAllowanceReturn",
    components : {
        VPagination,
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        List : [],
        total_count : 0,
        page : 1,
        loading : false,
    }),
    beforeCreate() {
        this.loading = true;
        axios.post(window.Laravel.back_url+'/api/MyBenefitAllowanceReturn',{
            page : this.page
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.List = response.data.list.data;
            this.total_count = response.data.total_count;
            this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
            this.loading = false;
        });

    }
}
</script>

<style scoped>

</style>
