<template>
  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
        <MypageTop :laravel="laravel"></MypageTop>
        <div class="w-full pt-4 lg:flex lg:justify-start">
          <div class="hidden lg:block xl:block">
            <SideMenu></SideMenu>
          </div>
          <!-- 마이페이지 본문 -->
          <div class="w-full">
            <div class="max-w-8xl mx-auto sm:py-6 sm:pl-6 lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start space-y-2 flex-col">
                  <div class="flex justify-between">
                    <h1 class="text-lg dark:text-white lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800 mb-2">View Order/Delivery</h1>
                    <!--                    <span class="text-sm leading-4 mt-4">전체보기
                                            <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-4 w-4 mt-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                              <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                            </svg>
                                        </span>-->
                  </div>
                  <div class="w-full border-y-2 border-black divide-y bg-white lg:p-2">
                    <div class="py-2">

                      <div class="flex justify-between text-lg lg:mb-4 xl:mb-4">
                        <h2 class="font-semibold">
                          {{ i18n.myoffice.Order.orderInformation }}&nbsp;
                          <button class="px-4 py-1 text-sm text-white bg-black rounded hover:bg-slate-500" v-if="row.ord_kind != 0 && row.kind_cd != 7 && row.rcpt_yn == 'Y' && isOwnDelivery"  v-on:click="popup_invoice(row.ord_no);">{{ i18n.myoffice.Order.purchaseform }}</button>
                        </h2>
                        <div class="flex justify-end cursor-pointer"  @click="showHideContents($event, 1)">
                          <MinusSmIcon class="h-6 w-6 minus-contents-body1" aria-hidden="true" />
                          <PlusSmIcon class="h-6 w-6 plus-contents-body1 contents-active-none" aria-hidden="true" />
                        </div>
                      </div>

                      <div class="contents-body1 contents-active mt-2 py-2 border-t border-dashed border-slate-300">
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base  font-semibold mb-1">{{ i18n.myoffice.Order.ordno}}</div>
                          <div class="w-4/6 text-sm lg:text-base xl:text-base">{{ row.ord_no }}</div>
                        </div>
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Order.orderDate}}</div>
                          <div class="w-4/6 text-sm lg:text-base xl:text-base">{{ row.ord_date2 }}</div>
                        </div>
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Order.type }}</div>
                          <div class="w-4/6 text-sm lg:text-base xl:text-base">{{ row.kind_cd == "1" ? "Normal" : "Auto Order" }}</div>
                        </div>
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Order.ordKind}}</div>
                          <div class="w-4/6 text-sm lg:text-base xl:text-base">{{ row.ord_kind == "1" ? "Order" : (row.ord_kind == "0" ? "Cancel" : "Exchange/Return") }}</div>
                        </div>
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-full lg:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Order.invoicenumber }}</div>
                          <div class="w-full lg:w-5/6" v-for="( value,index ) in deli_mst"  v-if="row.kind_Cd !=7 && isOwnDelivery" >
                            <span class="block lg:inline-block">{{ value.deli_no }}</span>
                            {{ value.i_list[0] ? (i18n.myoffice.Order.shippingnumber + ' : ') : '' }}
                            <span v-for="( ivalue,iindex ) in value.i_list" v-if="value.i_list[0]">
                                <span class="text-blue-400">{{ ivalue }}</span>&nbsp;
                                <button class="px-4 py-1 text-sm text-white bg-black rounded mt-2 lg:mt-0 hover:bg-slate-500" v-on:click='viewTraking(ivalue)'>{{ i18n.myoffice.Order.traking }}</button>
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="py-2">
                      <div class="flex justify-between text-lg lg:mb-4 xl:mb-4">
                        <h2 class="font-semibold">{{ i18n.myoffice.Order.PaymentDetails }}</h2>
                        <div class="flex justify-end cursor-pointer" @click="showHideContents($event, 2)">
                          <MinusSmIcon class="h-6 w-6 minus-contents-body2" aria-hidden="true" />
                          <PlusSmIcon class="h-6 w-6 plus-contents-body2 contents-active-none" aria-hidden="true" />
                        </div>
                      </div>
                      <div v-for="(value,index) in rcpt_list"  class="contents-body2 contents-active mt-2 py-2 border-t border-dashed border-slate-300">
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Order.PaymentType }}</div>
                          <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ value.kinds }} : Rp {{ formatPrice(value.amt,2) }}</div>
                        </div>
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1 break-words">{{ i18n.myoffice.Order.Paymentdate }}</div>
                          <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ value.reg_date }}</div>
                        </div>
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Order.depositcheck }}</div>
                          <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base" :class="row.ord_kind != '1' ? 'text-red-600' : 'text-blue-600'">
                            {{ row.rcpt_yn=='Y' ? (row.ord_kind == "0" ?  i18n.myoffice.CancleComplete : i18n.myoffice.paymentComplete ) : i18n.myoffice.Order.undeposit }}
                            <button v-if="row.rcpt_yn !== 'Y' && row.stripe_qr_link" v-on:click="goLink(row.stripe_qr_link)" class="go_list_btn">QR Link</button>
                          </div>
                        </div>
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.refund.shippingfee }}</div>
                          <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ row.bz_cd == "MYS" ? "RM" : "Rp" }} {{ this.shipping_fee }}</div>
                        </div>

                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Commission.TotalAmount }}</div>
                          <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ row.bz_cd == "MYS" ? "RM" : "Rp" }} {{ formatPrice(row.ord_amt,2) }}</div>
                        </div>
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Commission.paymentAmount }}</div>
                          <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ row.bz_cd == "MYS" ? "RM" : "Rp" }} {{ formatPrice(value.amt,2) }}</div>
                        </div>
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">BV</div>
                          <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ formatPrice(row.ord_pv,1) }} BV</div>
                        </div>
                      </div>
                    </div>

                    <div class="py-2">
                      <div class="flex justify-between text-lg lg:mb-4 xl:mb-4">
                        <h2 class="font-semibold">{{ i18n.myoffice.Order.shippinginformation }}</h2>
                        <div class="flex justify-end cursor-pointer" @click="showHideContents($event, 3)">
                          <MinusSmIcon class="h-6 w-6 minus-contents-body3" aria-hidden="true" />
                          <PlusSmIcon class="h-6 w-6 plus-contents-body3 contents-active-none" aria-hidden="true" />
                        </div>
                      </div>
                      <div  v-if="row.kind_Cd !=7 && isOwnDelivery" class="contents-body3 contents-active mt-2 py-2 border-t border-dashed border-slate-300">
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Order.Recipient }}</div>
                          <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ ord_deli.r_name }}</div>
                        </div>
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Address }}</div>
                          <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ ord_deli.r_addr2 }} {{ ord_deli.r_addr1 }}</div>
                        </div>
                        <div class="w-full flex justify-start lg:space-x-4 lg:py-2 lg:items-center mb-2">
                          <div class="w-2/6 lg:w-1/6 xl:w-1/6 text-sm lg:text-base xl:text-base font-semibold mb-1">{{ i18n.myoffice.Order.Contact }}</div>
                          <div class="w-4/6 lg:w-5/6 xl:w-5/6 text-sm lg:text-base xl:text-base">{{ ord_deli.r_mobile }}</div>
                        </div>
                      </div>
                    </div>

                    <div class="py-2">
                      <div class="flex justify-between">
                        <h2 class="font-semibold text-lg">Product Information</h2>
                        <div class="flex justify-end cursor-pointer" @click="showHideContents($event, 4)">
                          <MinusSmIcon class="h-6 w-6  minus-contents-body4" aria-hidden="true" />
                          <PlusSmIcon class="h-6 w-6 plus-contents-body4 contents-active-none" aria-hidden="true"/>
                        </div>
                      </div>

                      <div class="border-t-2 border-gray-500 divide-y contents-body4 contents-active mt-2">
                        <div class="py-2 hidden lg:flex lg:justify-between lg:items-center">
                          <div class="w-1/3 text-center">&nbsp;</div>
                          <div class="w-1/2 text-center">{{ i18n.shop.shopping.Pdt }}</div>
                          <div class="w-1/3 text-center">{{ i18n.shop.shopping.productcomposition }}</div>
                          <div class="w-20 text-center">{{ i18n.myoffice.Order.qty }}</div>
                          <div class="w-44 text-center">{{ i18n.myoffice.Order.amount }}/BV</div>
                        </div>
                        <div>
                          <div class="py-2 grid grid-cols-3 lg:flex lg:justify-between lg:items-center border-b border-dotted last:border-0" v-for="(value, index) in pdt_list">
                            <div class="relative col-span-1 row-span-4 w-full lg:w-1/3 py-1 px-2">
                              <img class="w-full" v-bind:src="value.file_url"  alt=""/> <!--hidden md:block-->
                              <!-- <img class="w-full md:hidden" v-bind:src="'value.file_url'" alt="" />-->
                            </div>
                            <div class="w-full lg:w-1/2 col-span-2 text-center lg:flex lg:justify-start lg:items-center">
                              <div class="w-full lg:flex lg:flex-col lg:justify-start lg:space-y-8">
                                <h3 class="dark:text-white lg:text-lg font-semibold leading-6 text-gray-800 text-left lg:text-center mb-1 lg:mb-0">{{ value.pdt_name }}</h3>
                              </div>
                            </div>
                            <div class="w-full lg:w-1/3 col-span-2 text-center lg:items-center pdt_bom">
                              <div class="w-full text-left block lg:hidden font-semibold text-sm lg:text-md">{{ i18n.shop.shopping.productcomposition }}</div>
                              <div class="text-left lg:text-center text-sm lg:text-md" v-for="(value_bom, index_bom) in pdt_list[index].bom_list">{{ value_bom.pdt_name }}</div>
                            </div>
                            <div class="w-full lg:w-20 col-span-2 text-center lg:items-center mb-1 lg:mb-0">
                              <div class="w-full text-left block lg:hidden font-semibold text-sm lg:text-md">{{ i18n.myoffice.Order.qty }}&nbsp;&nbsp;{{ value.qty }}</div>
                              <div class="w-full text-center text-lg lg:text-md hidden lg:block">{{ value.qty }}</div>
                            </div>
                            <div class="w-full lg:w-44 col-span-2 text-left lg:text-center lg:items-center">
                              <p class="text-sm lg:text-lg font-semibold">{{ row.bz_cd == "MYS" ? "RM" : "Rp" }} {{ formatPrice(value.amt,2) }}</p>
                              <p class="text-cyan-400 text-sm lg:text-lg font-semibold" v-show="value.pdt_cd !='000240'">{{ formatPrice(value.pv,1) }} BV</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--                    <div class="flex justify-end space-x-4"><button class="my-2 py-1 px-2 leading-6 text-sm text-white bg-gray-900 rounded">제품 구매계약서</button> <button class="my-2 py-1 px-2 leading-6 text-sm text-white bg-gray-900 rounded">청약철회 신청서</button></div>-->
                  <a href="/Mypage/Order"><div class="flex"><button class="my-2 py-2 px-4 leading-6 text-white bg-gray-900 w-full xl:w-1/5 lg:1/5 rounded">My Order List</button></div></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {MinusSmIcon, PlusSmIcon} from '@heroicons/vue/outline';
import MypageTop from '../MypageTop.vue';
import SideMenu from '../SideMenu.vue';
import moment from 'moment';

export default {
  name: "mypage_order_view",
  components : {
    VueSimpleAlert,
    MinusSmIcon,
    PlusSmIcon,
    MypageTop,
    SideMenu,
    moment
  },
  props: ['order_no'],
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    limit: 4,
    products: [],
    no_search_messsage: '',
    isLoggedIn: false,
    row : '',
    max_qty : 99,
    VPagination: [1],
    shipping_fee : 0,
  }),
  created() {
    this.submitForm();
  },
  mounted() {
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
    }
  },
  methods:{
    submitForm: function (event) {
      this.loading = true;
      axios.post(window.Laravel.back_url+'/api/getMyOrderView', {
        ord_no: this.order_no
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        this.loading       = false;
        this.row           = response.data.row;
        this.row.ord_date2 = moment(this.row.ord_date2).format('DD-MM-YYYY');
        this.isOwnDelivery = response.data.isOwnDelivery;
        this.pdt_list      = response.data.pdt_list;
        this.ord_deli      = response.data.ord_deli;
        this.rcpt_list     = response.data.rcpt_list;
        this.deli_mst      = response.data.deli_mst;
        this.shipping_fee  = response.data.ord_deli.deli_amt;
      });

    },
    /*getProductList($list) {
      if($list.length < 1){
        this.no_search_messsage = "There are no results";
      }
      $list.forEach((value, index) => {
        numeral.defaultFormat('$0,0[.]00');
        // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        this.products.push({
          id: value.seq_pd,
          name: value.pdt_name,
          href: '/product/item/' + value.seq_pd,
          imageSrc: 'https://cdn.gcoop.me/public' + value.images[1]?.path + '/' + value.images[1]?.filename,
          imageAlt: value.cate_name + '>' + value.pdt_name,
          // price: numeral(value.prices.amt).format('$0,0.00'),
          price: 'RM ' + numeral(value.prices.amt).format('0,0.00'),
          bv: numeral(value.prices.pv).format('0,0.0') + ' BV',
          qty : 1,
          status : value.status,
          badge : value.badge != "" ? "https://cdn.gcoop.me/"+value.badges.image_url : "",
        });
      });
    },*/
    list_url(link){
      location.href="/product/list/"+link;
    },
    goLink(link) {
      window.open(link);
    },
    decrement(product){
      product.qty = this.formatPrice(product.qty,0);

      if(product.qty > 1) {
        product.qty--;
      }
    },
    increment(product){
      product.qty = this.formatPrice(product.qty,0);
      if(product.qty < this.max_qty) {
        product.qty++;
      }
    },
    changeQty(product){
      product.qty = this.formatPrice(product.qty,0);
      if(product.qty < 1) {
        this.count = 1;
      }
      if(product.qty > this.max_qty) {
        product.qty = this.max_qty;
      }
    },
    formatPrice(value,digit) {
      // 코드 개선 add by hskim :: 2023-07-21 오후 2:46
      value = isNaN(value) ? 0 : value;
      let val = (value/1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    viewTraking(invoice_no){
      //this.deli_no = deli_no;
      //this.showModal = true;
      window.open("https://esvr5.gdexpress.com/WebsiteEtracking/Home/Etracking?id=GDEX&input="+invoice_no);
    },
    popup_invoice(ord_no) {
      window.open(window.Laravel.back_url+"/api/getInvoice?ordno="+ord_no+"&bzCd=id");
    },

    showHideContents: function(e, i) {
      if($(".contents-body"+i).hasClass("contents-active")){
        $(".contents-body"+i).removeClass('contents-active');
        $(".contents-body"+i).addClass('contents-active-none');
        $(".plus-contents-body"+i).removeClass('contents-active-none');
        $(".minus-contents-body"+i).addClass('contents-active-none');
      }else{
        $(".contents-body"+i).removeClass('contents-active-none');
        $(".contents-body"+i).addClass('contents-active');
        $(".minus-contents-body"+i).removeClass('contents-active-none');
        $(".plus-contents-body"+i).addClass('contents-active-none');
      }
    }

  }
}
</script>

<style scoped>
.contents-active{
  display:block;
}

.contents-active-none{
  display:none;
}
</style>
