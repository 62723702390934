<template>
    <dl class="myo_search_list">
        <dt>{{ i18n.myoffice.Order.SearchMulti }}</dt>
        <dd>
            <vSelect :options="userkind_type_cds" :reduce="value => value.id" v-bind="userkind_type"   ></vSelect>
        </dd>
    </dl>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "orderMeMultiCode",
    components : {
        vSelect
    },
    data: () => ({
        i18n: window.i18n,
        userkind_type_cds : [ { "id": '', "label": i18n.myoffice.SearchAll }, { "id": 'M', "label": i18n.myoffice.Order.SearchMember }, { "id": 'C', "label": i18n.myoffice.Order.SearchMulti } ]
    }),
    props : {
        userkind_type : ""
    },
    watch : {
        userkind_type(userkind_type){
            this.set_userkind_type(userkind_type);
        }
    },
    methods : {
        set_userkind_type(userkind_type){
            this.$emit("set_userkind_type",userkind_type);
        }
    }
}
</script>

<style scoped>

</style>
