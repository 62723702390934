<template>
    <dl class="myo_search_list">
        <dt>{{ i18n.myoffice.Rank }}</dt>
        <dd>
            <vSelect multiple :options="rank" v-model="rank_cds" @option:selected="setSelected" @option:selecting="selecting" @option:deselected="setDeselected" :closeOnSelect="false"  ></vSelect>
        </dd>
        <span class="mini_notice2">{{ i18n.myoffice.myoSearchForm.rankNotice }}</span>
    </dl>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "rankForm",
    components : {
        vSelect
    },
    data: () => ({
        i18n: window.i18n,
        rank: [{id : "" , label : "Select All"}],
        rank_cd : "",
        rank_cds : [{id : "" , label : "Select All"}],
        last_select_rank_cd  : "",
    }),
    beforeCreate() {
        axios.post(window.Laravel.back_url+'/api/getRankData',{
        }).then(response => {
            response.data.forEach((value, index) => {

                this.rank.push({

                    id: value.rank_cd,
                    label: value.rank_name,

                });

            });
        });

    },
    methods : {
        setSelected(value){
            var chk = 1;
            var sub_cds = this.rank_cds;
            sub_cds.forEach((value, index) => {
                if( value.id == "0" || value.id != ""){
                    chk = 0;
                }
            });
            if(this.last_select_rank_cd == "-1"){
                this.rank_cds =  [{id : "" , label : "Select All"}];
            }else{
                this.rank_cds = [];
                sub_cds.forEach((value, index) => {
                    //console.log("push:" + value.id);
                   if(value.id == "0" || value.id != ""){
                        this.rank_cds.push(value);
                    }
                });
            }
            this.$emit("setSelected",this.rank_cds);
        },
        setDeselected(value){

            if(this.rank_cds.length == 0){
                this.rank_cds =  [{id : "" , label : "Select All"}];
            }

            this.$emit("setSelected",this.rank_cds);
        },
        selecting(value){
            //console.log(value.id);
            if(value.id == "" && value.id != "0"){
                this.last_select_rank_cd = "-1";
            }else{
                this.last_select_rank_cd = "1";
            }
        }
    }
}
</script>
<style scoped>
>>> {
    --vs-dropdown-option-padding: 10px;

}
</style>
