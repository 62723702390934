<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
      <address_search @set_address="set_address" @close_address_search="close_address_search" />
    </modalView>

    <div id="mem_info" class="myo-modi-mem">

        <section class="accordion_notice_box">
            <div class="accordion_notice_item">
                <a class="accordion_notice_tit" href="#n">
                    <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                    {{ i18n.myoffice.myoSearchForm.notice }}
                    <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>
                </a>
                <div class="accordion_notice_cont">
                    <div class="myo_search_notice">
                        <p><span class="signiture remark1">&#42;</span>&nbsp;<span>{{ i18n.myoffice.myofficeAlert.academy_change_notice1 }}</span></p>
                        <p><span class="signiture remark1">&#42;</span>&nbsp;<span>{{ i18n.myoffice.myofficeAlert.academy_change_notice2 }}</span></p>
                        <p><span class="signiture remark1">&#42;</span>&nbsp;<span>{{ i18n.myoffice.myofficeAlert.academy_change_notice3 }}</span></p>
                    </div>
                </div>
            </div>
        </section>

        <form name="uni_form" method="post" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
            <input type=hidden name=exec_mode value='modify'>

            <!--아카데미 변경-->
            <h2 class="myo_table_tit">{{ i18n.myoffice.Change}} {{ i18n.myoffice.dashboard.Academy}}</h2> <!--회원정보 -->
            <ul id="myo_form" class="mgb_30">
                <li class="item table_form top-li">
                    <span class="item_title">GCOOPER {{ i18n.myoffice.dashboard.Academy }}</span>
                    <span class="item_content">
                            <span class="item_content m_contents z-30 relative">
                                <vSelect :options="campus_list" label="cnt_name" :reduce="value => value.cnt_cd" v-model="campus" :clearable="false"  :disabled="campus == '0000'"  ></vSelect>
                            </span>
                    </span>
                </li><!-- 회원명-->

            </ul>
            <!-- 회원정보 끝 -->

            <!-- 비즈니스 정보 시작 -->
            <ul id="myo_form">
              <li class="item table_form">
                <span class="item_title">Owner {{ i18n.myoffice.dashboard.Academy}}</span>
                <span class="item_content">{{ campusInfo.cnt_top_id }}</span>
              </li>
                <li class="item table_form top-li">
                    <span class="item_title">{{ i18n.myoffice.createDate}}</span>
                    <span class="item_content">{{ campusInfo.start_date2 }}</span>
                </li>
                <li class="item table_form">
                  <span class="item_title">{{ i18n.myoffice.Address }}</span>
                  <span class="item_content">{{ campusInfo.address }}</span>
                </li>

            </ul>

            <div class="myo_condition_search2">
                <button type="button" onclick="location.replace('/MyPersonInfo');"  class="reset_btn">{{ i18n.myoffice.Goback }}</button>
                <button type="submit" class="search_btn">{{ i18n.myoffice.save }}</button>
            </div>
        </form>
    </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueSimpleAlert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { createPopper } from '@popperjs/core'
import alert from "vue3-simple-alert";
import modalView from "../../modalView";

export default {
    name: "MyPersonInfoModifyCenter",
    components : {
        vSelect,
        VueSimpleAlert,
        modalView,
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        mem: "",
        myconfig : "",
        state : "",
        tel : "",
        mobile : "",
        addr1_str : "",
        addr1 : "",
        addr2 : "",
        Street : "",
        post : "",
        city : "",
        selectState : "",
        routingNo : "",
        selectCity : "",
        loading : false,
        fullPage : true,
        state_list : "",
        city_list : "",
        street_list : "",
        address_search_open:false,
        isMatchStateCity:false,
        campus_list : "",
        campus : "",
        campusInfo : [],
    }),
    beforeCreate() {
      axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        this.mem         = response.data;
        this.addr1       = response.data.address;
        this.addr2       = response.data.addr2;
        this.addr1_str   = response.data.addr1;
        this.post        = response.data.post;
        this.campus      = response.data.cnt_cd;

        this.mobile      = response.data.mobile;
        this.tel         = response.data.tel != "null" ? response.data.tel : '';
        this.e_mail      = response.data.e_mail;

        this.depositor   = response.data.depositor;
        this.account     = response.data.account != "null" ? response.data.account : "";
        this.bank        = response.data.bank_cd;
        this.campus_list = response.data.campus_list;
      });

    },
    created() {

    },

    watch : {
      campus : function (){
        // this.city = this.selectCity;
        this.getCampusInfo(this.campus);
      }
    },
    methods : {
      getCampusInfo : function(){
        axios.post(window.Laravel.back_url+'/api/getAcademyInfo', {
          cnt_cd : this.campus,
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
          this.campusInfo = response.data;
        });
      },
      submitForm: function (event) {
        let formData = new FormData();


        formData.append('cnt_cd', this.campus);


        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization : 'Bearer ' + window.Laravel.access_token
          }
        }

        this.loading = true;
        axios.post(window.Laravel.back_url+'/api/memberCenterModify',
          formData
          , config).then(response => {
          if(response.data.result == 1){
            VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
              if(result){
                location.replace("/MyPersonInfo");
              }
            });
          }else if(response.data.result == -1){
            VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {

            });
          }

          if(response.data.result == "0"){
            VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
              if(result){
              }
            });
          }

          this.loading = false;
        });
      },


    },

}
</script>

<style >
.v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
    border-radius: 4px 4px 0 0;
    border-top-style: solid;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
