<template>
  <section class="container mx-auto py-12">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-8 px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8">
        <div class="w-full">
          <div class="flex justify-start item-start space-y-2 flex-col">
            <h1 class="text-2xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800  mb-2 lg:mb-6">Payment Successful</h1>
            <div v-if="isResponse">
              <p v-if="!isOnlyPickup" class="text-base dark:text-gray-300 font-medium leading-6 text-gray-600">Thanks for making a purchase you can check our order summary from below</p>
              <p v-else class="text-base dark:text-gray-300 font-medium leading-6 text-gray-600">

                {{ i18n.shop.pickup.pickupPaymentComplete1 }}<br/>
                {{ i18n.shop.pickup.pickupPaymentComplete2 }}<br/>
                {{ i18n.shop.pickup.pickupPaymentComplete3 }}<br/><br/>
                {{ i18n.shop.pickup.pickupPaymentComplete4 }}<br/>
                {{ i18n.shop.pickup.pickupPaymentComplete5 }}<br/>
                {{ i18n.shop.pickup.pickupPaymentComplete6 }}<br/>

              </p>
            </div>
          </div>
          <div class="w-full flex justify-start my-6 py-2 bg-gray-100">
            <h2 class="md:text-xl dark:text-white lg:text-2xl w-80 font-semibold leading-7 lg:leading-9 text-gray-800">OrderNo. {{ order_list.ord_no }}</h2>
            <button v-show="visible_invoice" v-on:click="popup_invoice(order_list.ord_no);" class="px-2 m-0 text-sm text-white bg-gray-500 rounded">View Invoice</button>
          </div>
            <div v-show="this.order_list != '' && visible_invoice == false" class="flex justify-start item-start space-y-2 flex-col">
                <p class="text-base dark:text-gray-300 font-medium leading-6 text-gray-600">
                    The payment has not been completed. Please complete the payment through the QR code.  <a class="text-red-500" target="_blank" :href="qr_link">[QR Link]</a><br>
                    (QR is valid for 1 hour. Please complete the payment within an hour.)
                </p>
            </div>

            <div class="w-full px-4 lg:flex lg:justify-between">
            <div class="flex justify-start items-start flex-col space-y-4 mt-4 lg:mt-8">
              <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">Payment Information</p>
              <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">{{ order_pay_list.pg_id }}<br>{{ order_pay_list.approval_no }}</p>
            </div>
            <div class="flex justify-start items-start flex-col space-y-4 mt-4 lg:mt-8">
              <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">Delivery Information</p>
              <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">
                {{ order_info_list.d_name }}<br>{{ order_info_list.d_mobile }}<br>
                {{ order_info_list.d_addr2 }} {{ order_info_list.d_addr1 }}</p>
            </div>
            <div class="flex justify-start items-start flex-col space-y-4 mt-4 lg:mt-8" v-show="false">
              <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">Billing Information</p>
              <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">
                {{ order_info_list.o_name }}<br>{{ order_info_list.o_mobile }}<br>
                {{ order_info_list.o_addr2 }} {{ order_info_list.o_addr1 }}</p>
            </div>
            <div class="flex justify-start items-start flex-col space-y-4 mt-4 lg:mt-8">
              <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">Contact Help</p>
              <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">Info.id@gcoop.com</p>
            </div>
          </div>

          <div class="w-full lg:flex lg:justify-start">
            <div class="flex flex-col justify-start items-start w-full lg:w-2/3 space-y-4 md:space-y-6 xl:space-y-8 px-2">
              <div class="flex flex-col justify-start items-start dark:bg-gray-800 bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full mt-8">
                <p class="text-lg md:text-xl dark:text-white font-semibold leading-6 xl:leading-5 text-gray-800 mb-6">Customer’s Cart</p>
                <div v-for="(product, i) in order_product_list" :key="product.seq_pd" class="flex w-full border-b border-gray-200 mb-2">
                  <div class="relative w-1/2 py-2">
                    <img class="w-full hidden md:block" v-bind:src="'http://cdn.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename "  alt="{{ product.product.pdt_name }}" />
                    <img class="w-full md:hidden" v-bind:src="'http://cdn.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename " alt="{{ product.product.pdt_name }}" />
<!--                    <div class="sold-out-box2" v-if="product.product.status == 2">-->
<!--                      <img src="/images/badge/us/pc_temp_sold_out.png" alt="sold out" />-->
<!--                    </div>-->
<!--                    <div class="sold-out-box2" v-if="product.product.status == 3">-->
<!--                      <img src="/images/badge/us/pc_sold_out.png" alt="sold out" />-->
<!--                    </div>-->
<!--                    <div class="coming_box" v-if="product.product.status == 4">-->
<!--                      <img src="/images/badge/us/pc_coming_soon.png" alt="sold out" />-->
<!--                    </div>-->
<!--                    <div class="badge_box" v-if="product.badges.image_link != ''">-->
<!--                      <img :src="product.badges.image_link" alt="sold out" />-->
<!--                    </div>-->

                  </div>
                  <div class="flex-col flex justify-between items-start w-full pt-2 pb-8 space-y-4 md:space-y-0 pl-4">
                    <div class="w-full flex flex-col justify-start items-start space-y-8">
                      <h3 class="dark:text-white xl:text-xl font-semibold leading-6 text-gray-800">{{ product.product.pdt_name }}</h3>
                    </div>
                    <div class="text-red-500 text-xs md:text-lg" v-if="product.product.deli_date2" v-html="product.product.deli_date2"></div>
                    <div class=" w-full">
                      <p class="text-sm dark:text-white xl:text-lg">Rp{{ formatPrice(product.amt,2) }} / <span class="text-red-500">{{ formatPrice(product.pv,1) }} BV</span></p>
                      <p class="text-sm dark:text-white xl:text-lg text-gray-800">Qty : {{ product.qty }}</p>
                      <p class="text-sm dark:text-white xl:text-lg font-semibold text-gray-800">Rp{{ formatPrice(product.amt * product.qty,2) }} / <span class="text-red-500">{{ formatPrice(product.pv * product.qty,1) }} BV</span></p>
                    </div>
                  </div>
                </div>

<!--                <div v-for="(product, i) in order_product_list" :key="product.seq_pd" class="mt-4 md:mt-6 flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full">-->
<!--                  <div class="relative w-1/2 py-2">-->
<!--                    <img class="w-full hidden md:block" v-bind:src="'http://cdn.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename "  alt="dress" />-->
<!--                    <img class="w-full md:hidden" v-bind:src="'http://cdn.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename " alt="dress" />-->
<!--                  </div>-->
<!--                  <div class="flex-col flex justify-between items-start w-full pt-2 pb-8 space-y-4 md:space-y-0 pl-4">-->
<!--                    <div class="w-full flex flex-col justify-start items-start space-y-8">-->
<!--                      <h3 class="text-xl dark:text-white xl:text-2xl font-semibold leading-6 text-gray-800">{{ product.product.pdt_name }}</h3>-->
<!--                    </div>-->
<!--                    <div class=" w-full">-->
<!--                      <p class="text-base dark:text-white xl:text-lg">RM{{ formatPrice(product.amt,2) }} <span class="text-red-300">{{ formatPrice(product.pv,1) }} BV</span></p>-->
<!--                      <p class="text-base dark:text-white xl:text-lg text-gray-800">Qty : {{ product.qty }}</p>-->
<!--                      <p class="text-base dark:text-white xl:text-lg font-semibold text-gray-800">RM{{ formatPrice(product.amt * product.qty,2) }} <span class="text-red-300">{{ formatPrice(product.pv * product.qty,1) }} BV</span></p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
            <div class="w-full lg:w-1/3 lg:mt-8 px-2">
              <div class="flex justify-center flex-col md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                <div class="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 dark:bg-gray-800 space-y-6">
                  <h3 class="text-xl dark:text-white font-semibold leading-5 text-gray-800">Payment</h3>
                  <div class="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                    <div class="flex justify-between w-full">
                      <p class="text-base dark:text-white leading-4 text-gray-800">Subtotal</p>
                      <p class="text-base dark:text-gray-300 leading-4 text-gray-600">Rp{{ formatPrice(sum_item.subTotal,2) }} <p class="text-red-300 text-right mt-2">{{ formatPrice(sum_item.bv,1) }} BV</p></p>
                    </div>
                      <div v-show="false" class="flex justify-between items-center w-full">
                          <p class="text-base dark:text-white leading-4 text-gray-800">Tax</p>
                          <p class="text-base dark:text-gray-300 leading-4 text-gray-600">Rp{{ formatPrice(sum_item.vat,2) }}</p>
                      </div>

                      <div class="flex justify-between items-center w-full">
                      <p class="text-base dark:text-white leading-4 text-gray-800">Shipping</p>
                      <p class="text-base dark:text-gray-300 leading-4 text-gray-600">Rp{{ formatPrice(shipping_selected,2) }}</p>
                    </div>
                      <div v-show="heart_pay.pay_amount != 0" class="flex justify-between items-center w-full">
                          <p class="text-base dark:text-white leading-4 text-gray-800">Heart</p>
                          <p class="text-base dark:text-gray-300 leading-4 text-gray-600">Rp{{ formatPrice(heart_pay.pay_amount,2) }}</p>
                      </div>

                  </div>
                  <div class="flex justify-between items-center w-full">
                    <p class="text-base dark:text-white font-semibold leading-4 text-gray-800">Total</p>
                    <p class="text-base dark:text-gray-300 font-semibold leading-4 text-gray-600">Rp{{  formatPrice(total_cost,2) }} <p class="text-red-300 text-right mt-2">{{ formatPrice(sum_item.bv,1) }} BV</p></p>
                  </div>
                </div>
              </div>

              <div class="w-full">
                <!--              <h3 class="text-xl dark:text-white font-semibold leading-5 text-gray-800">Customer</h3>-->
                <!--              <div class="flex flex-col md:flex-row justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0">-->
                <!--                <div class="flex flex-col justify-start items-start flex-shrink-0">-->
                <!--                  <div class="flex justify-center w-full md:justify-start items-center space-x-4 py-8 border-b border-gray-200">-->
                <!--                    <img src="https://i.ibb.co/5TSg7f6/Rectangle-18.png" alt="avatar" />-->
                <!--                    <div class="flex justify-start items-start flex-col space-y-2">-->
                <!--                      <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">David Kent</p>-->
                <!--                      <p class="text-sm dark:text-gray-300 leading-5 text-gray-600">10 Previous Orders</p>-->
                <!--                    </div>-->
                <!--                  </div>-->

                <!--                  <div class="flex justify-center text-gray-800 dark:text-white md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">-->
                <!--                    <img class="dark:hidden" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/order-summary-3-svg1.svg" alt="email">-->
                <!--                    <img class="hidden dark:block" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/order-summary-3-svg1dark.svg" alt="email">-->
                <!--                    <p class="cursor-pointer text-sm leading-5 ">david89@gmail.com</p>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </div>-->

                <div class="flex justify-start w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                  <div class="flex flex-col justify-center px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 dark:bg-gray-800 space-y-6">
                    <h3 class="text-xl dark:text-white font-semibold leading-5 text-gray-800">Shipping</h3>
                    <div class="flex justify-between items-start w-full">
                      <div class="flex justify-center items-center space-x-4">
                        <div class="w-8 h-8">
                          <img class="w-full h-full" alt="logo" src="https://cdn.gcoop.me/shop/storage/images/my/logo_gdex_m.png" />
                        </div>
                        <div class="flex flex-col justify-start items-center">
                          <p class="text-lg leading-6 dark:text-white font-semibold text-gray-800">GDEX Malaysia</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Pickup from "../../assets/module/pickup";

export default {
  name: "OrderSummary",
  props: ['order'],
  data: () => ({
    i18n: window.i18n,
    title: [],
    order_list: [],
    order_info_list: [],
    order_pay_list: [],
    order_product_list: [],
    sum_item: [],
    // fee_shipping: [],
    shipping_selected: 0,
    isOnlyPickup : false,
    isResponse : false,
    heart_pay: [],
      pay_use : 0,
      visible_invoice : false,
      qr_link : "",

      // fee_tax: [],
    // tax_selected: 0,
    // total_cost: 0,
    // csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
    //orderer: orderer
  }),
  created() {
    this.sum_item.price = 0;
    this.sum_item.vat = 0;
    this.sum_item.amt = 0;
    this.sum_item.bv = 0;
    this.shipping_selected = 0;
    this.heart_pay.pay_amount = 0;
    this.getOrder(window.Laravel.bz_cd);
  },
  methods: {
    getOrder(bz_cd) {
      axios.post(process.env.MIX_BACK_URL + '/api/getOrder', {
        order: this.order,
        bz_cd: bz_cd,
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
        .then(response => {

            this.order_list = response.data;
            this.order_info_list= response.data.info;
            this.order_info_list.d_addr2 = this.order_info_list.d_addr2.replaceAll("|@|", ", ");
            this.order_info_list.d_addr1 = this.order_info_list.d_addr1.replaceAll("|@|", ", ");
            this.order_info_list.o_addr1 = this.order_info_list.o_addr1.replaceAll("|@|", ", ");
            response.data.pay.forEach((value, index) => {
                if(value.kind_cd == '3'){
                    this.order_pay_list= value;
                }
                if(value.kind_cd == '9'){
                    this.heart_pay = value;
                }
            });


          this.isOnlyPickup = Pickup.isOnlyPickup(this.order_list.product);
          this.isResponse = true



            if(parseFloat(response.data.pay[0].pay_amount) == 0){
                this.order_product_list = response.data.product;
                this.sum_item.price     = this.order_list.ord_price;
                this.sum_item.vat       = this.order_list.ord_vat;
                this.sum_item.subTotal  = parseFloat(this.sum_item.price) + parseFloat(this.order_list.ord_vat);
                this.sum_item.amt       = 0;
                this.sum_item.bv        = this.order_list.ord_pv;
                this.shipping_selected  = this.order_list.ord_deli_amt;
                this.visible_invoice = false;
                this.qr_link = response.data.pay[0].stripe_qr_link;

            }else{
                this.order_product_list = response.data.product;
                this.sum_item.price     = this.order_list.ord_price;
                this.sum_item.vat       = this.order_list.ord_vat;
                this.sum_item.subTotal  = parseFloat(this.sum_item.price) + parseFloat(this.order_list.ord_vat);
                this.sum_item.amt       = this.order_list.ord_amt;
                this.sum_item.bv        = this.order_list.ord_pv;
                this.shipping_selected  = this.order_list.ord_deli_amt;
                this.visible_invoice = true;

            }

            this.totalCost();
            // this.totalCost(this.order_product_list);  //  결제상품 총액 / 배송비 포함 실결제액
            // this.title = response.data[0].cate_name;
            console.log(this.sum_item);
            console.log(parseFloat(response.data.pay[0].pay_amount));

        })
        .catch(e => {
          // window.history.go(-1);
        });
    },
    // 합산 금액 합산
    totalCost(){  // 토탈 금액 합산 기능
      this.total_cost = this.sum_item.amt;
      // if(this.shipping_selected) this.total_cost += parseFloat(this.shipping_selected);
      // if(this.tax_selected) this.total_cost += parseFloat(this.tax_selected);
    },
    popup_invoice(ord_no) {
      window.open(window.Laravel.back_url+"/api/getInvoice?ordno="+ord_no+"&bzCd=id");
    },
    // 숫자 포멧
    formatPrice(value,digit) {
      // 코드 개선 add by hskim :: 2023-07-21 오후 2:46
      value = isNaN(value) ? 0 : value;
      let val = (value/1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  }
}
</script>

<style>

</style>
