<template>
    <loading :active="false"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <section class="accordion_notice_box">
        <div class="accordion_notice_item">
            <a class="accordion_notice_tit" href="#n">
                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                {{ i18n.myoffice.myoSearchForm.notice}}
                <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>
            </a>
            <div class="accordion_notice_cont">
                <div class="myo_search_notice">
                    <p>{{ i18n.myoffice.Commission.txt3}}</p>
                </div>
            </div>
        </div>
    </section>

    <div id="cs_page">
        <div class="ctn-box">
            <div class="board-inner">
                <div class="cont_wrap">
                    <div class="osBeneCal_box">
                        <div class='calendar_info' style="overflow-x: auto;">




                            <table class="cal_title">
                                <colgroup>
                                    <col width="200px"/>
                                    <col width="*"/>
                                    <col width="200px"/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <td style="width:200px;"></td>
                                    <td align="center" style="padding:20px;  width: 100%;">
                                        <table cellspacing="0" cellpadding="0" border="0">
                                            <colgroup>
                                                <col width="50"/>
                                                <col width="80"/>
                                                <col width="50"/>
                                            </colgroup>
                                            <tbody>
                                            <tr>
                                                <td class="gray01" align="center" colspan="3" style="font-size: 30px;">
                                                    <strong id="cal_curYear" class="gray01">{{ year }}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td id="btncal_prev" align="center">
                                                    <a v-on:click="prevMonth"><div style="width:50px; height:69px;"><i class="fas fa-angle-left"></i></div></a>
                                                </td>
                                                <td id="cal_curMonth" class="oran02" align="center" style="font-size: 20px;">{{ Month }}</td>
                                                <td id="nextMonth" align="center">
                                                    <a v-on:click="nextMonth"><div style="width:50px; height:69px;"><i class="fas fa-angle-right"></i></div></a>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td style="width:200px;">
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <table class="cal_month table01" cellspacing="0" cellpadding="0" style="min-width: 450px;">
                                <colgroup>
                                    <col width="15%"/>
                                    <col width="14%"/>
                                    <col width="14%"/>
                                    <col width="14%"/>
                                    <col width="14%"/>
                                    <col width="14%"/>
                                    <col width="*"/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <td class="day">{{ i18n.myoffice.sun2 }}</td>
                                    <td class="day">{{ i18n.myoffice.mon2 }}</td>
                                    <td class="day">{{ i18n.myoffice.tue2 }}</td>
                                    <td class="day">{{ i18n.myoffice.wed2 }}</td>
                                    <td class="day">{{ i18n.myoffice.thu2 }}</td>
                                    <td class="day">{{ i18n.myoffice.fri2 }}</td>
                                    <td class="day">{{ i18n.myoffice.sat2 }}</td>
                                </tr>
                                </thead>
                                <tbody>
                                <!-- 총 주차를 반복합니다. -->
                                <tr v-for="(row, index) in day_array">
                                    <!-- 1일부터 7일 (한 주) -->
                                    <td class="week" v-for="(row2, index2) in row">
                                        <!-- 시작 요일부터 마지막 날짜까지만 날짜를 보여주도록 -->
                                        <!-- 현재 날짜를 보여주고 1씩 더해줌 -->
                                        <div v-if="row2"  class="day">{{ row2 }}</div >
                                        <div v-if="data[row2]" style="width:98%; text-align:right;">{{ formatPrice(data[row2].totalamt,2) }}({{ i18n.myoffice.dashboard.total }})</div>
                                        <div v-if="data[row2]" style="width:98%; text-align:right;">{{ formatPrice(data[row2].taxamt,2) }}({{ i18n.myoffice.Commission.deduction }})</div>
                                        <div v-if="data[row2]" style="width:98%; text-align:right;">{{ formatPrice(data[row2].trueamt,2) }}({{ i18n.myoffice.Commission.payment }})</div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div><!-- cont_wrap -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
    name: "MyBenefitCalendar",
    components : {
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        total_week : 30,
        days : 1,
        start_week : 0,
        total_day : 0,
        data : [],
        day_array : [],
        now : "",
        Month : "",
        year : "",
        loading : true,

    }),
    created : function () {
        this.Month = dayjs().format("MM");
        this.year = dayjs().format("YYYY");

        var now = this.year+this.Month;
        this.now = now;
        var date = dayjs(now).format("YYYYMM01");
        this.start_week = dayjs(date).format("d");
        this.total_day = dayjs(now).daysInMonth();
        this.total_week = Math.ceil((parseInt(this.total_day) + parseInt(this.start_week)) / 7);

        this.createCalender();
        this.submitForm();
    },methods : {
        createCalender(){
            this.day_array = [];
            this.days = 1;
            for(var day = 1; day <= this.total_week ; day++){
                let calendarRow = [];
                for(var week = 0 ; week < 7 ; week++  ){
                    if((this.days > 1 || week >= this.start_week) && (this.total_day >= this.days)){
                        var date2 = this.days < 10 ? "0"+this.days : this.days;
                        calendarRow.push(date2) ;
                        this.days++;
                    }else{
                        calendarRow.push("") ;
                    }
                }
                this.day_array.push(calendarRow) ;

            }

        },
        submitForm : function (){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/MyBenefitCalendar',{
                Ymonth : this.now,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.data = response.data;
                this.loading = false;
            });

        },
        prevMonth(){
            this.Month = dayjs(this.now).subtract(1, 'month').format("MM");
            this.year = dayjs(this.now).subtract(1, 'month').format("YYYY");
            var now = this.year+this.Month;
            this.now = now;
            var date = dayjs(now).format("YYYYMM01");
            this.start_week = dayjs(date).format("d");
            this.total_day = dayjs(now).daysInMonth();
            this.total_week = Math.ceil((parseInt(this.total_day) + parseInt(this.start_week)) / 7);
            this.data = [];
            this.createCalender();
            this.submitForm();

        },
        nextMonth(){
            this.Month = dayjs(this.now).add(1, 'month').format("MM");
            this.year = dayjs(this.now).add(1, 'month').format("YYYY");
            var now = this.year+this.Month;
            this.now = now;
            var date = dayjs(now).format("YYYYMM01");
            this.start_week = dayjs(date).format("d");
            this.total_day = dayjs(now).daysInMonth();
            this.total_week = Math.ceil((parseInt(this.total_day) + parseInt(this.start_week)) / 7);
            this.data = [];
            this.createCalender();
            this.submitForm();

        },
        formatPrice(value,digit) {
            // 코드 개선 add by hskim :: 2023-07-21 오후 2:46
            value = isNaN(value) ? 0 : value;
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        setDate(){

        }
    }
}
</script>

<style>
.cal_title, .cal_month { width: 100%;}
.table01 thead .day { text-align: center; background: #f8f8f8; padding: 10px 0; width: 14.2%}
.table01 thead td+td { border-left: 1px solid #e0e0e0; }
.table01 thead tr td:first-child.day {color:#f96c56;}
.table01 thead tr td:last-child.day {color:#3bb8ae;}

.table01 tbody .week {height:100px; vertical-align:top; border-top:1px solid #d8d8d8;}
.table01 tbody .week+.week { border-left:1px solid #d8d8d8;}
.table01 tbody .day { width:calc(100% - 8px); padding:2px 4px; text-align:right; border-bottom: 1px dotted #c0c0c0;}
.table01 tbody .day2 { font-weight:normal; color:#3bb8ae;}
.table01 tbody tr td:first-child .day {color:#f96c56;}
.table01 tbody tr td:last-child .day {color:#3bb8ae;}
.table01 tbody .memo { width:calc(100% - 4px); padding:7px 2px; color:#353535; text-align:left;}

.tableInfo, .table01
.calendar_info {width:100%; max-width:1000px; margin: 20px auto;}
.osBeneCal_box { min-height: 500px;}

</style>
