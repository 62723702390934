<template>

    <table id="listTable" class="display nowrap border-collapse	border border-slate-400" cellspacing="0" width="100%">
        <thead>
        <tr>
            <th class="border border-slate-300">Release number</th>
            <th class="border border-slate-300">InvoiceNo</th>
            <th class="border border-slate-300">date</th>
            <th class="border border-slate-300">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row) in list">
            <td class="border border-slate-300" align="center">{{ row.orderNo }}</td>
            <td class="border border-slate-300" align="center">{{ row.consignmentNumber }}</td>
            <td class="border border-slate-300" align="center">{{ row.statusChangeDate }}</td>
            <td class="border border-slate-300" align="center">{{ row.status }}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "invoice",
    props : ['deli_no'],
    data: () => ({
        list : ""
    }),
    created : function (){
        axios.post(window.Laravel.back_url+'/api/getMyInvoiceTraking',{
            "deli_no" : this.deli_no
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.list = response.data[0];
        });
    },

}
</script>

<style scoped>

</style>