<template>
    <div id="admin_page">
        <div id="div_modify" style="display:block">
            <div class="tit">
                {{ row.title }}
                <span class="view-date"> {{ row.reg_date }}</span>
            </div>

            <!-- 상세 내용 -->
            <div class="board-view-txt">
                <div class="item item_img">
                    <span class="item_content">
                        <div class="view_contents" v-if="img_yn == 'T'" v-html="content_img_top"></div>
                        <div class="view_contents" v-html="content"></div>
                        <div class="view_contents" v-if="img_yn == 'B'" v-html="content_img_bottom"></div>
                    </span>
                </div>
                <div v-show="attach" class="view-add-file">
                    <h4>{{ i18n.inquriy.inquriy.attachment }}</h4>
                    <ul v-html="attach"></ul>
                </div>
            </div>
        </div>

    </div>

    <div class="myo_condition_search2">
        <button type="submit" @click=" listUrl " class="go_list_btn">{{ i18n.inquriy.inquriy.list }}</button>
    </div>
</template>

<script>
export default {
    name: "BoardView",
    props: ['no','list','mid_mypage'],
    data: () => ({
        i18n: window.i18n,
        content : "",
        content_img_top : "",
        content_img_bottom : "",
        img_yn : "",
        content_img : "",
        row : "",
        mid : window.location.pathname,
        attach : "",
    }),
    created : function (){
        //console.log(this.list);
        axios.post(window.Laravel.back_url+'/api/boardView',{
            no : this.no,
            mid : this.mid_mypage ? this.mid_mypage : this.mid,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.row = response.data.row;
            this.content = response.data.row.content;
            this.a_list = response.data.a_list;
            this.attachFileArr = response.data.attachFileArr;
            this.loading = false;

            this.img_yn = response.data.row.img_yn;
            this.content_img = response.data.content_img;
            this.attach = response.data.attach;
        });

    },
    methods : {
       listUrl: function(event) {location.href = "/"+this.list;}
    }

}
</script>

<style >
.qna_span{ display: none; }
.qna_img{ display: block; }
.view_contents img { min-width: 320px; }

@media all and (max-width: 480px){

    .qna_span{ display: inline-block; }
    .qna_img{ display: none; }
    ul.history_table{ width: 100% !important; }


    div.list_tools_div ul{ width: 100%; }
    div.list_tools_div ul li{ padding: 5px 8px; text-align: left; }
    div.list_tools_div ul li:nth-child(3){ clear: both; }
    div.list_tools_div ul li:nth-child(1), div.list_tools_div ul li:nth-child(3){ width: 125px; }
    div.list_tools_div ul li:last-child{ margin-top: 15px; }

    #content_page{ padding: 5px; box-sizing: border-box; }
    div.list_tools_div{ width: 100%; }
    table.dataTable>tbody>tr.child ul.dtr-details{ width: 100%; }
    #info_edit .item_title{ float: none !important; width: 100% !important; margin: 4px 0; }
    #info_edit .item_content{ float: none !important; width: 100% !important; margin: 8px 0; }
    .out_frame{ overflow: scroll; }
    .out_frame iframe{ width: 600px !important; height: 280px !important; }
    .view_contents{ box-sizing: border-box; padding: 5px !important;  }
    .out_frame { border-bottom:32px solid #b7b7b7;}
    .scroll_ntc{ display: block; color: red; }

    .btn{ margin: 0; }

    #info_edit .item_title:before{ content: '▼ ' !important; color: #000; }
    #cate_notice{ padding-bottom: 10px; }

    #info_edit .item:last-child .item_title{  width: 100px !important;}
    #info_edit .item:last-child .item_content .view_contents{ overflow: auto;}
    .view_title { overflow: auto;}

    .history-list-wrap .history_table{ width: calc(100% - 100px); }
    .history_table li{ padding: 5px !important;; }
}

@media all and (max-width: 320px){
    #content_page{ padding: 5px; box-sizing: border-box; }
    div.list_tools_div{ width: 100%; }

}

</style>
